import styled from "styled-components";

interface TagsContainerProps {
    centered?: Boolean;
  }

export const Container = styled.section`
    
    .headerTabela{
    
        padding: 36px 64px 0px 64px;

        .nomePagina{
            font-size: 2rem;
        }
    }

    main{
        padding: 24px 64px;
        margin: 0 auto;

        .container{
            padding: 42px 54px;
            background-color: var(--white);
            border-radius: 30px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            .nomeSessao{
                font-size: 0.875rem;
                margin-bottom: 10px;
            }           
        }

        .selectContainer{
            display: flex;
            justify-content: space-between;
            align-items: center;

            .selectContainerButtons{
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            @media only screen and (max-width: 900px) {
            flex-direction: column;
            }

        }

        @media only screen and (max-width: 900px) {
            padding: 0;
            
            .container{
                padding: 20px;
                margin-top: 36px;

                .nomeSessao{
                    margin-bottom: 8px;
                }
            }
        }

    }

    .loading{
        position: relative;
        width: 150px;
        height: 150px;
        animation: rotate 2s linear infinite;

        @keyframes rotate {
                0%{
                    transform: rotate(0deg);
                }
                100%{
                    transform: rotate(360deg);
                } 
            }
        circle{
            width: 100%;
            height: 100%;
            fill: none;
            stroke-width: 10;
            stroke: var(--roxo-700);
            stroke-linecap: round;
            transform: translate(5px ,5px);
            stroke-dasharray: 440;
            stroke-dashoffset: 440;
            animation: animate 4s linear infinite;

            @keyframes animate {
                0%,100%{
                    stroke-dashoffset: 440;
                }
                50%{
                    stroke-dashoffset: 0;
                }
                50.1%{
                    stroke-dashoffset: 880;
                }
            }
        }
    }

`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 22px;

    .inputsContainer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 13px;
    }

    .inputWrapper{
        display: flex;
        flex-direction: column;
        .inputLabel{
            font-size: 0.875rem;
            color: var(--input-label);
            text-transform: uppercase;
            margin-left: 13px;
            margin-bottom: 12px;
        }
        .inputLabelError{           
            font-size: 1rem;
            color: var(--laranja-600);
            margin-left: 13px;
        }
        .inputStyle{
            border: none;
            font-weight: bold;
            height: 49px;
            border-radius: 40px;
            background-color: var(--background-dashboard);
            
        }        
        .chooseContainer{
            min-height: 49px;
            padding-left: 20px;
            padding-top: 10px;
            border: none;
            border-radius: 40px;
            background-color: var(--background-dashboard);
            display: flex;
            align-items: center;

            p{
                font-weight: 900;
                margin-top: -10px;
            }
        }
    }
    
    .w50{
        width: 50%;
    }
    
    .w100{
        width: 100%;
    }
    
    .btnContainer{
        display: flex;
        margin-top: 20px;

        .secondButton{
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            outline: inherit;
            margin: 0 0 0 auto;
        }
    }

    @media only screen and (max-width: 900px) {
        gap: 0px;
        margin: 0px;
        padding: 0px;

        label{
            margin-top: 10px;
            
        }

        .inputsContainer{
            display: block;
            .inputWrapper{
                .inputLabel{
                    margin-bottom: 4px;
                }
            }
        }

        .w50 {
            width: 100%;
        }

    }

`

export const TagsContainer = styled.div<TagsContainerProps>`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    .tags{
        padding: ${props => props.centered ? '10px': '10px 10px 10px 20px'};
        margin-right: 10px;
        margin-top: 10px;
        border-radius: 30px;
        color: var(--white);
        background-color: var(--azul-300);
        display: flex;
        align-items: center;
        
        .deleteTagIcon{
            margin-left: 10px;
            cursor: pointer;
        }

    }

`
import styled from "styled-components";

export const Container = styled.section`
    .headerTabela{    
        padding: 36px 64px 0px 64px;
        display: flex;
        justify-content: space-between;

        .nomePagina{
            font-size: 2rem;
        }     

        @media only screen and (max-width: 900px) {
            padding: 36px 20px 0px 20px;
        }

    }
    
    .infoContainer{
        padding:  0px 64px;
        margin: 15px auto;
        display: grid;
        grid-column-gap: 15px;
        grid-row-gap: 15px;
        min-height: 350px;
        min-width: 300px;

        @media only screen and (max-width: 900px) {
            padding: 0px;
        }
        
        .graphContainer, .barContainer{
            position: relative;
            background-color: var(--white);
            border-radius: 27px;
            padding-top: 30px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        }
        
        .graphContainer{
            min-height: 300px;
            
            @media only screen and (min-width: 1690px) {
                grid-column: 1 / 3;
            }

        }
        
        .barContainer{
            min-height: 300px;

            @media only screen and (min-width: 1690px) {
                grid-column: 3 / 3;
            }

        }

        .title{
            font-size: 1rem;
            font-weight: bold;
            color: var(--roxo-700);
            position: absolute;
            top: 20px;
            left: 30px;
        }

    }
`

export const Table = styled.main`
    padding:  0px 64px;
    margin: 0 auto;
    overflow: auto;

    .tabelaContainer{
        width: 100%;
        background-color: var(--background);
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 20px;
        padding-bottom: 30px;
        border-radius: 30px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        table{
            width: 100%;
            border: none;
            thead{
                border: none;
                tr{
                    border: none;
                    td{
                        border: none;
                        font-size: 1rem;
                        font-weight: bold;
                        padding-bottom: 26px;
                        color: var(--thead-color);
                    }
                    .primeiro{

                        padding-left: 86px;
                    }

                    .ultimo{
                        padding-right:48px;
                    }
                }
            }
            tbody{
                border: none;
                tr{
                    border: none;
                    td{
                        border: none;
                        padding:18px 0;
                        color: var(--tbody-color);
                    }
                    .primeiro{

                        padding-left: 86px;
                    }

                    .ultimo{
                        padding-right:48px;
                        cursor: pointer;
                    }

                    &:hover{
                        transition: 0.2s;
                        background-color: var(--table-hover);
                    }
                }
            }
        }
    }

    
    @media only screen and (max-width: 900px) {
        padding: 0px;
        margin: 0px;
    }
`

export const CardsContainer = styled.div`
    padding:  24px 64px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    grid-row-gap: 30px;
    grid-column-gap: 30px;

    @media only screen and (max-width: 900px) {
        padding: 10px 30px 0;
        grid-row-gap: 10px;
        grid-column-gap: 10px;
    }

    .card{
        display: flex;
        flex-direction: column;
        background: linear-gradient(290deg ,var(--amarelo-300) 0%,  var(--laranja-500) 75%); 
        min-width: 120px;
        min-height: 10rem;
        border-radius: 27px;
        justify-content: center;
        align-items: center;

        p {
            color: var(--white);

            &.title {
                font-size: 2rem;
                font-weight: bold;
            }
            
            &.text {
                font-size: 1rem;
            }
    
        }

        @media only screen and (max-width: 900px) {
            min-height: 60px;
        }
    }
`
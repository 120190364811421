import {
    BrowserRouter,
    Routes as Router,
    Route,
} from "react-router-dom";

import { Cadastro } from "../pages/Cadastro/Cadastro";
import { CadastroEtapa1 } from "../pages/Cadastro/CadastroEtapa1";
import { CadastroEtapa2 } from "../pages/Cadastro/CadastroEtapa2";
import { CadastroEtapa3 } from "../pages/Cadastro/CadastroEtapa3";
import { CadastroEtapa4 } from "../pages/Cadastro/CadastroEtapa4";
import { CadastroEtapa5 } from "../pages/Cadastro/CadastroEtapa5";
import { CadastroEtapa6 } from "../pages/Cadastro/CadastroEtapa6";
import { CadastroEtapa7 } from "../pages/Cadastro/CadastroEtapa7";
import { CadastroPre } from "../pages/Cadastro/CadastroPre";
import { Administrativo } from "../pages/Dashboard/Administrativo";
import { Conta } from "../pages/Dashboard/Conta";
import { Parceiros } from "../pages/Dashboard/Parceiros";
import { Dashboard } from "../pages/Dashboard/Dashboard";
import { AguardandoAnalise } from "../pages/Dashboard/AguardandoAnalise";
import { OperacaoRouter } from "../pages/Dashboard/Operacao/OperacaoRouter";
import { Operacao } from "../pages/Dashboard/Operacao/Operacao";
import { CriarQrCode } from "../pages/Dashboard/Operacao/CriarQrCode";
import { Login } from "../pages/login";
import { Home } from "../pages/Dashboard/Home";
import { PrimeiroAcesso } from "../pages/PrimeiroAcesso";
import { RecuperarSenha } from "../pages/RecuperarSenha";
import { ResetarSenha } from "../pages/ResetarSenha";
import { PagarQrCode } from "../pages/PagarQrCode";
import { BlogRouter } from "../pages/Blog/BlogRouter";
import { BlogHome } from "../pages/Blog/Home";
import { BlogPost } from "../pages/Blog/Post";
import { BlogPreVisualizarPost } from "../pages/Blog/PreVisualizarPost";
import { AcademiaJeitto } from "../pages/Dashboard/AcademiaJeitto";
import { Enxoval } from "../pages/Dashboard/Enxoval";

export default function Routes() {

    return (
        <BrowserRouter>
            <Router>
                <Route path="/" element={<Login />} />
                <Route path="/recuperar-senha" element={<RecuperarSenha />} />
                <Route path="/resetar-senha/:id" element={<ResetarSenha />} />
                <Route path="/primeiro-acesso" element={<PrimeiroAcesso />} />
                <Route path="/cadastro-pre" element={<CadastroPre />} />
                <Route path="/cadastro-pre/:id" element={<CadastroPre />} />

                <Route path="/cadastro" element={<Cadastro />} >
                    <Route path="CadastroEtapa1" element={<CadastroEtapa1 />} />
                    <Route path="CadastroEtapa2" element={<CadastroEtapa2 />} />
                    <Route path="CadastroEtapa3" element={<CadastroEtapa3 />} />
                    <Route path="CadastroEtapa4" element={<CadastroEtapa4 />} />
                    <Route path="CadastroEtapa5" element={<CadastroEtapa5 />} />
                    <Route path="CadastroEtapa6" element={<CadastroEtapa6 />} />
                    <Route path="CadastroEtapa7" element={<CadastroEtapa7 />} />
                </Route>

                <Route path="/dashboard" element={<Dashboard />} >
                    <Route path="home" element={<Home />} />
                    <Route path="analise" element={<AguardandoAnalise />} />
                    <Route path="administrativo" element={<Administrativo />} />                    
                    <Route path="conta" element={<Conta />} />             
                    <Route path="/dashboard/operacao" element={<OperacaoRouter />}>                    
                        <Route path="/dashboard/operacao" element={<Operacao />} />
                        <Route path="/dashboard/operacao/criar-qrcode" element={<CriarQrCode />} />
                    </Route>
                    <Route path="academia-jeitto" element={<AcademiaJeitto />} />
                    <Route path="parceiros" element={<Parceiros />} />  
                    <Route path="enxoval" element={<Enxoval />} />  
                </Route>
                
                <Route path="/blog" element={<BlogRouter />} >
                    <Route path="" element={<BlogHome />} />
                    <Route path="/blog/:id" element={<BlogPost />} />
                    <Route path="/blog/pre-visualizar-post" element={<BlogPreVisualizarPost />} />
                </Route>

                <Route path="/pagar-qrcode/:id" element={<PagarQrCode />} />
            </Router>

        </BrowserRouter>
    )
}
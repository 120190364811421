import { useEffect } from "react";
import { useLocation } from "react-router";
import { ImHome } from 'react-icons/im';
import { IoPersonSharp } from 'react-icons/io5';

import useAuth from "../../hooks/useAuth";
import { Container } from "./styles";

import { MenuLateralItem } from "../MenuLateralItem";
import LogoBrancaSvg from '../../assets/imagens/logoBrancaSvg.svg';
import { ReactComponent as MoneyIcon } from '../../assets/icones/moneyCardIcon.svg'
import { ReactComponent as SocialIcon } from '../../assets/icones/socialCardIcon.svg'
import { ReactComponent as OperacaoIcon } from '../../assets/icones/operacaoCardIcon.svg'
import { ReactComponent as SairIcon } from '../../assets/icones/sairIcon.svg';
import { ReactComponent as HelpIcon } from '../../assets/icones/helpIcon.svg';
import { ReactComponent as NewsIcon } from '../../assets/icones/newsIcon.svg';
import { ReactComponent as Parceiros } from '../../assets/icones/parceiros.svg';
import { ReactComponent as EnxovalIcon } from '../../assets/icones/enxovalIcon.svg';

const botoes = [
    {
        titulo: 'Home',
        rota: "/home",
        icone: <ImHome fill="White" size={33} />,
    },
    {
        titulo: 'Minha conta',
        rota: "/conta",
        icone: <IoPersonSharp fill="White" size={22} />,
    },
    {
        titulo: 'Financeiro',
        rota: "/financeiro",
        icone: <MoneyIcon fill="White" height={22} width={29} />,
    },
    {
        titulo: 'Administrativo',
        rota: "/administrativo",
        icone: <SocialIcon fill="White" height={22} width={29} />,
    },
    {
        titulo: 'Operação',
        rota: "/operacao",
        icone: <OperacaoIcon fill="White" height={22} width={29} />,
    },
    {
        titulo: 'Academia Jeitto',
        rota: "/academia-jeitto",
        icone: <NewsIcon fill="White" height={29} width={29} />,
    },
    {
        titulo: 'Parceiros',
        rota: "/parceiros",
        icone: <Parceiros fill="White" height={29} width={29} />,
    },
    {
        titulo: 'Enxoval',
        rota: "/enxoval",
        icone: <EnxovalIcon fill="White" height={29} width={29} />,
    },
    {
        titulo: 'Ajuda',
        rota: "/ajuda",
        icone: <HelpIcon fill="White" height={29} width={29} />,
    },
]
const iconeSair = <SairIcon fill="White" height={28} width={25} />

interface MenuLateralProps {
    callback ?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function MenuLateral({callback} :MenuLateralProps ) {
    const {contaId} = useAuth();
    const { pathname } = useLocation();

    const filteredBotoes = () => {
        if(contaId === null){
            return botoes.filter(obj => obj.titulo !== 'Minha conta');
        } else return botoes   
    }

    useEffect(() => {

    }, [pathname]);
    
    return (
        <Container>
            <img src={LogoBrancaSvg} alt="logo jeitto" />

            <div className="menuItemWrapper">
                {
                    filteredBotoes().map((item) => (
                        <MenuLateralItem
                            active={pathname.includes(item.rota)}
                            rota={item.rota} Icone={item.icone}
                            titulo={item.titulo}
                            key={item.titulo}
                            callback={callback}
                        />
                    ))
                }
                <MenuLateralItem active={false} rota={"/logout"} Icone={iconeSair} titulo={"Sair"} sair={true} />
            </div>
        </Container>
    )
}
import styled from 'styled-components';

export const Container = styled.main`
  margin: 30px 0px;
  max-width: 60%;

  @media only screen and (max-width: 900px) {
    max-width: 90%;
  }

  .busca{
    font-size: 1.33rem;
    color: var(--laranja-500);
    font-weight: 900;
    margin-bottom: 15px;
  }

  .searchPostContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    
    @media only screen and (max-width: 900px) {
      grid-template-columns: 1fr;
      gap: 25px;

    }

  }

`

export const PostContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  .leftContainer{
    width: 100%;

    img{
      width: 100%;
      object-fit: contain;
      
    }
    
  }

  .rightContainer{
    display: flex;
    flex-direction: column;

    .categoria{
      color: var(--laranja-500);
      font-size: 0.85rem;
    }
    
    .titulo{
      font-size: 1.75rem;
      color: var(--thead-color);

      &:hover{
        color: var(--roxo-700);
        cursor: pointer;
      }
      
    }
    
    .data{
      font-size: 0.85rem;
      margin-top: 2px;
      margin-bottom: 10px;

    }

  }

`
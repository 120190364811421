import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Outlet, useOutletContext } from "react-router-dom";

import { IState } from "../../../store";
import { dadosVidaNovaProps, postCriarContaVendedorProps } from "../../../services/models";
import { Container } from "./styles";

import BackgroundCadastro from '../../../assets/imagens/backgroundCadastro.png'
import CustomizedSteppers from "../../../components/form/Steper";

const steps = [
    "Empresa",
    "Sócio",
    "Administrador",
    "Banco",
    "E-mail",
    "SMS",
    // "Validação PIX"
    "Confira os dados"

];
interface CadastroContextData {
    handleStep: () => void;
    handleStepBack: () => void;
    dadosParceiroID: string,
    dadosParceiro: postCriarContaVendedorProps,
    setDadosParceiro: (args: any) => React.Dispatch<React.SetStateAction<postCriarContaVendedorProps>>
}

export function Cadastro() {
    const location = useLocation();
    const dadosVidaNova = useSelector<IState, dadosVidaNovaProps>((state) => state.dadosVidaNova);
    const [currentStep, setCurrentStep] = useState(0);
    const [dadosParceiroID, _] = useState(location.search.replace('?[id]=', ''));
    const [dadosParceiro, setDadosParceiro] = useState<postCriarContaVendedorProps>({
        email: dadosVidaNova.email ? dadosVidaNova.email : '',
        conta: {
            razao_social: dadosVidaNova.razao_social ? dadosVidaNova.razao_social : '',
            cnpj: dadosVidaNova.cnpj ? dadosVidaNova.cnpj : '',
            nome: '',
            celular: dadosVidaNova.telefone ? dadosVidaNova.telefone : '',
            nome_fantasia: dadosVidaNova.nome_fantasia ? dadosVidaNova.nome_fantasia : '',
            site: '',
            segmento: '',
            setor: '',
            vendas_mensais: '',
        },
        conta_bancaria: {
            banco_nome: '',
            banco_cod: '',
            agencia: '',
            conta: '',
            conta_digito: '',
            tipo: '',
        }
    });
    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname == '/cadastro' || pathname == "/cadastro/") {
            navigate("/cadastro/cadastroEtapa1");
        }
    }, [pathname]);

    function handleStep() {
        if (currentStep + 1 != steps.length) {
            setCurrentStep(currentStep + 1);
        }

    }
    function handleStepBack() {
        if (currentStep - 1 >= 0) {
            setCurrentStep(currentStep - 1);
        }
    }

    return (
        <Container>
            <div className="box1">

                <div className="etapas">
                    <CustomizedSteppers steps={steps} currentStep={currentStep} />
                </div>
                <img src={BackgroundCadastro} alt="logo" />
            </div>
            <div className="box2">

                <Outlet context={{
                    handleStep, handleStepBack,
                    dadosParceiroID,
                    dadosParceiro, setDadosParceiro
                }} />
            </div>
        </Container>
    )
}

export function useCadastro() {
    return useOutletContext<CadastroContextData>();
}
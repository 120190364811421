import { useNavigate } from "react-router";
import { BackButton } from "../BackButton";
import { Container } from "./styles";
import LogoLaranja from "../../assets/imagens/logoLaranja.png"

interface HeaderCadastroProps {
    callback?: () => void;
}

export function HeaderCadastro({callback = () => null}: HeaderCadastroProps) {

    const navigate = useNavigate();

    function handleGoBack() {
        navigate(-1);
        callback();
    }

    return (
        <Container>
            <BackButton func={handleGoBack} />
            <img src={LogoLaranja} alt="logo" />
        </Container>
    )
}
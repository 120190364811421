import { getContaVendedor, postMe } from '../services/service';

const body = document.querySelector('body');

const getUserData = async (token: string) => {
  const resMe = await postMe(token);

  if(resMe.data.conta_id === null){
    return {nome: `${resMe.data.nome ?? resMe.data.email}`, email: `${resMe.data.email}`}

  } else {
    const {data} = await getContaVendedor(token, resMe.data.conta_id!)
    return {nome: `${data.nome}`, email: `${data.email}`}
  }

}

const useZendesk = () => {

  const startZendesk = async (token: string) => {

    //Verifica se o chat ja foi instanciado e esta escondido
    const hideScript = document.getElementById("ze-hideScript");

    if(hideScript){
      const showScript = document.createElement('script');    
      showScript.id = "ze-showScript";
      showScript.type = 'text/javascript';
      showScript.innerHTML = "zE('webWidget', 'show');"
      body?.appendChild(showScript);

      hideScript.remove();
      showScript.remove();

      const oldConfigScript = document.getElementById("ze-config");
      oldConfigScript?.remove();
      
      const {nome, email} = await getUserData(token);

      const configScript = document.createElement('script');    
      configScript.id = 'ze-config';
      configScript.type = 'text/javascript';
      configScript.innerHTML = `zE('webWidget', 'identify', {name: '${nome}', email: '${email}'});`
      body?.appendChild(configScript);
      
      return
      
    }

    const initScript = document.createElement('script');
    initScript.id = "ze-snippet"
    initScript.async = true;
    initScript.src = "https://static.zdassets.com/ekr/snippet.js?key=73559e75-6e75-440f-843f-98bc5770e94c";
    body?.appendChild(initScript);

    //Color Config
    //@ts-ignore
    window.zESettings = {
      webWidget: {
        color: {
          theme: '#340950'
        },
      }
    };
    
    const {nome, email} = await getUserData(token);    

    const configLangScript = document.createElement('script');    
    configLangScript.type = 'text/javascript';
    configLangScript.innerHTML = "zE('webWidget', 'setLocale', 'pt');"
    body?.appendChild(configLangScript);

    const configScript = document.createElement('script');    
    configScript.id = 'ze-config';
    configScript.type = 'text/javascript';
    configScript.innerHTML = `zE('webWidget', 'identify', {name: '${nome}', email: '${email}'});`
    body?.appendChild(configScript);

  }

  const removeZendesk = () => {
    //Verifica se o chat ja foi instanciado
    const launcher = document.getElementById('launcher');

    if(launcher){
      const hideScript = document.createElement('script');    
      hideScript.id = "ze-hideScript";
      hideScript.type = 'text/javascript';
      hideScript.innerHTML = "zE('webWidget', 'hide');"
      body?.appendChild(hideScript);

      return
    }

  }

  return {startZendesk, removeZendesk};

}

export default useZendesk;
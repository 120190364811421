import styled from "styled-components";


export const Container = styled.div`
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    
    svg{
        position: relative;
        width: 150px;
        height: 150px;
        animation: rotate 2s linear infinite;

        @keyframes rotate {
                0%{
                    transform: rotate(0deg);
                }
                100%{
                    transform: rotate(360deg);
                } 
            }
        circle{
            width: 100%;
            height: 100%;
            fill: none;
            stroke-width: 10;
            stroke: var(--roxo-700);
            stroke-linecap: round;
            transform: translate(5px ,5px);
            stroke-dasharray: 440;
            stroke-dashoffset: 440;
            animation: animate 4s linear infinite;

            @keyframes animate {
                0%,100%{
                    stroke-dashoffset: 440;
                }
                50%{
                    stroke-dashoffset: 0;
                }
                50.1%{
                    stroke-dashoffset: 880;
                }
            }
        }
    }
`
import { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Outlet, useLocation, useNavigate } from "react-router";
import { MenuLateral } from "../../../components/MenuLateral";
import { Container } from "./styles";


export function Dashboard() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [showMobileMenu, setshowMobileMenu] = useState(false);

    useEffect(() => {
        if (pathname == '/dashboard' || pathname == "/dashboard/") {
            navigate("/dashboard/home");
        }
    }, [pathname]);

    return (
        <Container>
            <div className="box1">
                <MenuLateral />
            </div>
            <div className="box2">

                <div className="mobileMenu">
                    {!showMobileMenu ?
                        <AiOutlineMenu
                            size={28}
                            color='#F58220' 
                            onClick={() => setshowMobileMenu(prev => !prev)}
                        />
                    :
                        <AiOutlineClose
                            size={28}
                            color='#F58220' 
                            onClick={() => setshowMobileMenu(prev => !prev)}
                        />
                    }
                </div>
                {
                showMobileMenu
                    &&
                <MenuLateral callback={setshowMobileMenu} />
                }
                <Outlet />
            </div>
        </Container>
    )
}
import { useState } from "react";
import { useParams } from "react-router"
import { toast } from "react-toastify";
import CurrencyInput from "react-currency-input-field";
import { AiOutlineExclamationCircle } from "react-icons/ai";

import { Container } from "./styles";
import { postAgendarPagamentoTransacoesProps } from "../../services/models";
import { 
  // getCep,
  getJeittoLeadTracking,
  postAgendarPagamentoTransacoes
} from "../../services/service";

import Logo from '../../assets/imagens/logo.png';
import heroPagarQrCode from '../../assets/imagens/heroPagarQrCode.png';
import { CustomInputMask } from "../../components/form/CustomInputMask";
import { ReactComponent as PhoneIcon } from "../../assets/icones/phoneIcon.svg";
import { ReactComponent as DadosIcon } from "../../assets/icones/dadosIcon.svg";
import { ReactComponent as AnaliseIcon } from "../../assets/icones/analiseIcon.svg";
import { ReactComponent as AutorizeIcon } from "../../assets/icones/autorizeIcon.svg";
import { ReactComponent as SocialIconInstagram } from "../../assets/icones/socialIconInstagram.svg";
import { ReactComponent as SocialIconFacebook } from "../../assets/icones/socialIconFacebook.svg";
import { ReactComponent as SocialIconYouTube } from "../../assets/icones/socialIconYouTube.svg";
import { ReactComponent as GooglePlay } from "../../assets/imagens/googlePlay.svg";
import { Loading } from "../../components/Loading/Loading";

// interface cepProps{
//   data:{
//     logradouro: string,
//     bairro: string,
//     localidade: string,
//     uf: string,
//     erro: string,
//   },
// }

export function PagarQrCode() {
  const params = useParams();
  const [dados, setDados] = useState({qr_code_id: params.id} as postAgendarPagamentoTransacoesProps);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const hadleAgendarPagamento = async() => {
    setLoading(true);
    try {
      const res = await postAgendarPagamentoTransacoes(dados);
      if(res.statusText === 'OK'){
        toast.success("Agendado com sucesso!");
        setSuccess(true);

        try {
          const cpf = dados.cpf.replaceAll('.','').replace('-','');
          // const tel = dados.ddd_phone.replace('(','').replace(')','').replace('-','').replaceAll(' ','')
          const leadUrl = window.location.href.replaceAll('&','AND');          
          // await getJeittoLeadTracking(cpf, tel, leadUrl);
          await getJeittoLeadTracking(cpf, leadUrl);
          setLoading(false);

        } catch (err) {
          console.log('Lead', err)
          setLoading(false);          
        }
      } else {
        toast.error("Ocorreu um erro! Tente novamente mais tarde");
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      toast.error("Ocorreu um erro! Tente novamente mais tarde");
      setLoading(false);
    }
  }

  // const getCepData = async (cep: string) => {
  //   setLoading(true);
  //   try {
  //     const res = await getCep(cep) as unknown as cepProps;
  //     if(res.data.erro){
  //       toast.warn('Cep não encontrado');
  //     }else {
  //       setDados(
  //         prev => ({...prev, endereco: {...prev.endereco,
  //           rua: res.data.logradouro,
  //           bairro: res.data.bairro,
  //           cidade: res.data.localidade,
  //           estado: res.data.uf,
  //         } })
  //       );
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // }

  return(
    <Container>
      <header className="header" >
        <img className="logo" src={Logo} alt="logo-jeitto" />
      </header>

      <section className="hero" >
        <div className="heroLeft" >
          <h2>Quase lá! Finalize seu pagamento com o Jeitto</h2>
          <h3>Somos um app de crédito digital. Aqui você pode ter limite todo mês para usar como quiser: fazer compras, pagar contas, transferir e muito mais.</h3>
        </div>
        <div className="heroRight" >
          <img className="imageHero" src={heroPagarQrCode} alt="aplicativo Jeitto" />
        </div>
      </section>

      <section className="formContainer">
        <h2>É só preencher e baixar o app para descobrir seu limite disponível!</h2>
        {!success ?
          <form>       
            <CustomInputMask
              placeholder={'CPF'}
              // className="tres-tres"
              mask={'999.999.999-99'}
              onChange={e => setDados(
                prev => ({...prev, cpf: e.target.value })
              )}
            />
            <div>
              <CurrencyInput
                placeholder={'Valor da compra'}              
                // className="um-tres" 
                className="valorInput"
                intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                prefix='R$'
                allowNegativeValue={false}
                decimalSeparator=","
                groupSeparator="."
                decimalsLimit={2}            
                maxLength={10}
                onValueChange={(value) => 
                  setDados(
                    // @ts-ignore
                    prev => ({...prev, value: parseFloat(value?.replace(',', '.')) })
                  )
                }
              />
              {/* {dados.value > 300 && <span className="valorWarning">o valor máximo é R$300,00</span>} */}
            </div>
             
            {/* <input
              placeholder={'Nome'}
              className="um-dois"
              onChange={e => setDados(
                prev => ({...prev, name: e.target.value })
              )}
            />
            <input
              placeholder={'Email'}
              className="dois-dois"
              onChange={e => setDados(
                prev => ({...prev, email: e.target.value })
              )}
            /> */}
            
            {/* <CustomInputMask
              placeholder={'Celular'}
              className="dois-tres"
              mask={'(99) 99999-9999'}
              onChange={e => setDados(
                prev => ({...prev, ddd_phone: e.target.value })
              )}
            /> */}

            {/* <CustomInputMask
              placeholder={'CEP'}
              className="um-tres"
              mask={'99999-999'}
              // @ts-ignore
              maskChar={null}
              value={dados.endereco.cep}
              onChange={e => {
                setDados(
                  prev => ({...prev, endereco: {...prev.endereco, cep: e.target.value} })
                );
                if(e.target.value.length === 9){
                  console.log()
                  getCepData(e.target.value);
                }
              }}
                
            />
            <input
              placeholder={'Rua'}
              className="endereco"
              value={dados.endereco.rua}
              onChange={e => setDados(
                prev => ({...prev, endereco: {...prev.endereco, rua: e.target.value} }) /////verificar
              )}
            />

            <input
              placeholder={'Bairro'}
              className="um-tres"
              value={dados.endereco.bairro}
              onChange={e => setDados(
                prev => ({...prev, endereco: {...prev.endereco, bairro: e.target.value} })
              )}
            />
            <input
              placeholder={'Cidade'}
              className="dois-tres"
              value={dados.endereco.cidade}
              onChange={e => setDados(
                prev => ({...prev, endereco: {...prev.endereco, cidade: e.target.value} })
              )}
            />
            <CustomInputMask
              placeholder={'Estado'}
              className="tres-tres"
              mask={'aa'}
              value={dados.endereco.estado}
              onChange={e => setDados(
                prev => ({...prev, endereco: {...prev.endereco, estado: e.target.value} })
              )}
            /> */}
            
            <button
              onClick={e => {e.preventDefault(); hadleAgendarPagamento();}}
            >
              Descobrir Limite
            </button>
          </form>
        :
          <div className="successMessage">
            <h3>Agora faça o download do app para continuar!</h3>
            <GooglePlay
              width={'200px'}
              height={'100px'}             
              onClick={() => window.open("https://play.google.com/store/apps/details?id=br.com.jeitto.meujeitto") }
            />
          </div>
        }
      </section>
      
      <section className="cardsContainer">
        <div className="card">
          <PhoneIcon className="icon" />
          <div className="cardInsideContainer">
            <h3>1</h3>
            <p><span>Baixe o app grátis</span> pelo Google Play.</p>
            <div className="rightBar"/>
          </div>
        </div>

        <div className="card">
          <DadosIcon className="icon" />
          <div className="cardInsideContainer">
            <h3>2</h3>
            <p>Cadastre-se apenas com seu <span>CPF e Valor.</span></p>
            <div className="rightBar"/>
          </div>
        </div>

        <div className="card">
          <AnaliseIcon className="icon" />
          <div className="cardInsideContainer">
            <h3>3</h3>
            <p>Saiba se seu limite de crédito foi aprovado <span>em menos de 2 min.</span></p>
            <div className="rightBar"/>
          </div>
        </div>

        <div className="card">
          <AutorizeIcon className="icon" />
          <div className="cardInsideContainer">
            <h3>4</h3>
            <p><span>Autorize o pagamento </span>no menu lateral dentro do app.</p>
          </div>
        </div>
      </section>

      <section className="aviso">
        <AiOutlineExclamationCircle size={80} className='iconMobile' />
        <AiOutlineExclamationCircle size={45} className='icon' />
        <p className="boldText">Não caia em golpes: <span className="lightText">Não solicitamos depósitos antecipados e nunca entramos em contato solicitando dados pessoais ou senhas.</span>
        </p>
      </section>

      <footer>
        <section className="footerTop">
            <div className="jeitto">
              <h3 className="title">Jeito</h3>

              <a href="https://jeitto.webflow.io/sobre-nos" >Conheça o Jeitto</a>
              <a href="https://jeitto.webflow.io/corporativo" >Parceiros e Investidores</a>
              <a href="https://meajuda.jeitto.com.br/hc/pt-br" >Me Ajuda</a>
              <a href="https://jeitto.webflow.io/quem-faz-o-jeitto" >Vem pro Jeitto</a>
              <a href="https://jeitto.webflow.io/blog" >Nosso Jeitto</a>
              <a href="https://jeitto.webflow.io/seguranca-digital" >Segurança</a>

              <div className="socialIcons">
                <SocialIconInstagram
                  style={{width: 30, cursor: 'pointer'}}
                  onClick={() => window.open("https://www.instagram.com/jeitto_app/") }
                />
                <SocialIconFacebook
                  style={{width: 30, cursor: 'pointer', margin: "0 10"}}
                  onClick={() => window.open("https://www.facebook.com/JeittoApp/") }
                />
                <SocialIconYouTube
                  style={{width: 30, cursor: 'pointer'}}
                  onClick={() => window.open("https://www.youtube.com/channel/UCv6kviK_Sy4dr2W3-st0Ekg")}
                />
              </div>

              <GooglePlay
                className="googleplayImg"
                onClick={() => window.open("https://play.google.com/store/apps/details?id=br.com.jeitto.meujeitto") }
              />
            </div>
            
            <div>
              
              <div className="servicos">
                <h3 className="title">Serviços</h3>
                <div className="links">
                  <div>
                  <a href="https://jeitto.webflow.io/credito-pra-voce" >Crédito para Você</a>
                  <p>Crédito rápido e sem burocracia para pagar as contas, recarregar, transferir ou contratar serviços online.</p>
                  </div>

                  <div>
                  <a href="https://jeitto.webflow.io/credito-para-seus-clientes" >Crédito para seus clientes</a>
                  <p>A gente dá uma mãozinha para os seus clientes fazerem o pagamento e você recebe na hora. Bom pra todo mundo!</p>
                  </div>

                  <div>
                  <a href="https://jeitto.webflow.io/transferencia" >Transferências</a>
                  <p>Você pode receber transferências pelo app e também usar o crédito Jeitto para transferir para qualquer instituição, sem pagar nada na hora.</p>
                  </div>
                  
                  <div>
                  <a href="https://jeitto.webflow.io/minha-fatura" >Minha Fatura</a>
                  <p>Você usa seu limite e uma fatura será gerada a cada mês, como se fosse um cartão de crédito. E claro, quando você não usar, não paga nada.</p>
                  </div>
                </div>
              </div>

              <div className="descubra">
                <h3 className="title">Descubra</h3>                
                <div className="links">
                  <div>
                  <a href="https://jeitto.webflow.io/credito-aprovado" >Como ser aprovado?</a>
                  <p>Nosso sistema aprova 3x mais que outras empresas, mas, se você não for aprovado de primeira, a dica é ir usando o app na função Carteira Digital para facilitar a liberação do seu crédito.</p>
                  </div>

                  <div>
                  <a href="https://jeitto.webflow.io/jeitto-integrapay#" >Meu limite pode aumentar?</a>
                  <p>Sim! Quanto mais você usar o app, mais a gente vai entendendo suas necessidades e maiores suas chances de aumento.</p>
                  </div>

                  <div>
                  <a href="https://jeitto.webflow.io/jeitto-integrapay#" >Como funcionam as tarifas?</a>
                  <p>Não cobramos juros pelo uso do crédito. O limite que você usar, virá na sua próxima fatura com uma tarifa única e transparente, que vai aparecer pra você na hora da confirmação de cada transação.</p>
                  </div>

                  <div>
                  <a href="https://jeitto.webflow.io/seguranca-digital" >O Jeitto é seguro?</a>
                  <p>Sim, estamos sempre desenvolvendo novas tecnologias para garantir a segurança dos nossos clientes. E nunca - nunca mesmo! - pedimos sua senha fora do app.</p>
                  </div>
                </div>

                <GooglePlay
                  className="googleplayImgMobile"
                  onClick={() => window.open("https://play.google.com/store/apps/details?id=br.com.jeitto.meujeitto") }
                />
              </div>

            </div>
        </section>

        <section className="footerBottom">
          <div className="cnpj">
            <img className="logo" src={Logo} alt="logo-jeitto" />
            
            <div className="dados">
              <span>© 2021 Jeitto. Todos os direitos reservados.</span>
              <span>Jeitto Meios de Pagamento LTDA</span>
              <span>CNPJ: 20.937.849/0001-01</span>

              <div className="socialIconsMobile">
                <SocialIconInstagram
                  style={{width: 30, cursor: 'pointer'}}
                  onClick={() => window.open("https://www.instagram.com/jeitto_app/") }
                />
                <SocialIconFacebook
                  style={{width: 30, cursor: 'pointer', margin: "0 10"}}
                  onClick={() => window.open("https://www.facebook.com/JeittoApp/") }
                />
                <SocialIconYouTube
                  style={{width: 30, cursor: 'pointer'}}
                  onClick={() => window.open("https://www.youtube.com/channel/UCv6kviK_Sy4dr2W3-st0Ekg")}
                />
              </div>
            </div>
          </div>

          <div className="termos">
            <a href="https://jeitto.webflow.io/politica-de-privacidade" >Política de Privacidade</a>
            <p>|</p>
            <a href="https://jeitto.webflow.io/termos-e-condicoes-de-uso" >Termos e Condições de Uso</a>
            <p>|</p>
            <a href="https://jeitto.webflow.io/scr" >Termo de Autorização de Consulta</a>
          </div>

          <h4>O Jeitto é um aplicativo de crédito que também atua como correspondente bancário visando facilitar o processo de contratação de empréstimos, seguimos as diretrizes das Resolução nº 3.954 do Banco Central do Brasil. Somos correspondente bancário da seguinte instituição: SOCINAL S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO, inscrita no CNPJ sob o nº 03.881.423/0001-56.</h4>
        </section>
      </footer>

    <Loading isLoading={loading} />
    </Container>
  )
}
import styled from 'styled-components';

export const Container = styled.main`
  margin: 30px 0px;
  max-width: 60%;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;

  }
  
  h2{
    margin-bottom: 15px;
    font-weight: 500;

  }
  
  span, p{
    font-size: 1.33rem;
    margin-top: 10px;

  }

  img{
    width: 60vw;
    height: auto;
    object-fit: contain;

  }

  .conteudo{      
    h2, h3, h4, p, span, a, img {
      margin: 0;
      padding: 0;
    }

    .ql-align-center{
      text-align: center;
      padding: 0px 4em;
    }

  }

  @media only screen and (max-width: 900px) {
    max-width: 90%;

    img{
      width: 90vw;
    }
  }

`

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  flex-wrap: wrap;
  
  p {
    padding: 10px 20px;
    margin-right: 10px;
    border-radius: 30px;
    color: var(--white);
    background-color: var(--azul-300);

    &:hover{
      cursor: pointer;
    }

  }

`
import React from 'react';
import { Provider } from 'react-redux';
import Routes from './routes';
import { store } from './store';
import GlobalStyles from './styles/Global';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <ToastContainer />
      <Provider store={store}>
        <div className="App">

          <GlobalStyles />
          <Routes />
        </div>
      </Provider>
    </>

  );
}

export default App;

import React, { ReactElement } from "react";
import { IconType } from "react-icons";
import { useNavigate } from "react-router";

import { Container } from "./styles";
import useAuth from "../../hooks/useAuth";
import { postLogout } from "../../services/service";

interface MenuLateralItemProps {
    titulo: string;
    rota: string;
    Icone: ReactElement<IconType>
    active: boolean;
    sair?: boolean;
    callback?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function MenuLateralItem({ titulo, active, rota, Icone, sair = false, callback, ...rest }: MenuLateralItemProps) {

    const navigate = useNavigate();
    const {token, contaId} = useAuth();
    
    function handleRoute() {
        if(rota === "/logout"){
            try {
                postLogout(token);
            } finally {
                localStorage.removeItem('@auth');
                navigate(`/`);                
            }
            return
        }

        if(token === null) return;

        if(rota === "/administrativo"){
            if(contaId === null){
                navigate(`/dashboard${rota}`);
                if(callback !== undefined){
                    callback(false);
                }
            } else {
                return
            }
        }
        
        if(rota === "/academia-jeitto"){
            if(contaId === null){
                navigate(`/dashboard${rota}`);
                if(callback !== undefined){
                    callback(false);
                }
            } else {
                navigate('/blog')
                return
            }
        }

        if(rota === "/ajuda" || rota === "/financeiro"){
            return
        }

        navigate(`/dashboard${rota}`);
        if(callback !== undefined){
            callback(false);
        }
    }

    return (
        <Container active={active} {...rest} sair={sair} onClick={handleRoute} titulo={titulo}>
            {Icone}
            <span>{titulo}</span>
            <span className="tooltip">Em Desenvolvimento</span>      
        </Container>
    )
}
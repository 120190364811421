import { useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import { useCadastro } from "../Cadastro";
import { Container } from "./styles";
import { postVerificarContatoEnviar } from "../../../services/service";
import { postCriarContaVendedorProps } from "../../../services/models";

import { CustomInputMask } from "../../../components/form/CustomInputMask";
import { InputText } from "../../../components/form/InputText";
import { SubmitButton } from "../../../components/form/SubmitButton";
import { HeaderCadastro } from "../../../components/HeaderCadastro";
import { Loading } from "../../../components/Loading/Loading";
import { SelectBanco } from "../../../components/SelectBanco";

export function CadastroEtapa4() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { handleStep, handleStepBack, dadosParceiro, setDadosParceiro } = useCadastro();

    async function handleNavigate(e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) {
        e?.preventDefault()
        if(
            dadosParceiro.conta_bancaria.agencia === '' ||
            dadosParceiro.conta_bancaria.banco_cod === '' ||
            dadosParceiro.conta_bancaria.banco_nome === '' ||
            dadosParceiro.conta_bancaria.conta === '' ||
            dadosParceiro.conta_bancaria.conta_digito === '' ||
            dadosParceiro.conta_bancaria.tipo === ''
        ) {
            toast.warning('Preencha todos os campos para continuar.')
            return
        }

        try {
            setLoading(true);
            const res = await postVerificarContatoEnviar(
                dadosParceiro.conta.cnpj,
                dadosParceiro.email,
                dadosParceiro.conta.celular,
            );
            setLoading(false);
            handleStep();
            navigate('/cadastro/cadastroEtapa5');
        } catch (err) {
            setLoading(false);
            console.log(err);
            toast.error('Nao foi possível enviar os tokens de confirmação para seu email e celular');
            
        }
    }
    
    return (
        <Container>
            <HeaderCadastro callback={handleStepBack}/>

            <form onSubmit={handleNavigate} >
                <div style={{gridColumn: '1 / 4'}}>
                    <label className="inputLabel">Banco</label>
                    <SelectBanco
                            hasBorder
                            setBancoNome={(arg) => {
                                setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                                    ...prev,
                                    conta_bancaria: {
                                        ...prev.conta_bancaria,
                                        banco_nome: arg
                                    }
                                }))
                            }}
                            setBancoCod={(arg) => {
                                setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                                    ...prev,
                                    conta_bancaria: {
                                        ...prev.conta_bancaria,
                                        banco_cod: arg
                                    }
                                }))
                            }}
                        />
                </div>
                {/* <div style={{gridColumn: '1 / 3'}}>
                    <label className="inputLabel">Nome do banco</label>
                    <InputText
                        type={"text"}
                        placeholder="Nome do banco"
                        value={dadosParceiro.conta_bancaria.banco_nome}
                        onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                            ...prev,
                            conta_bancaria: {
                                ...prev.conta_bancaria,
                                banco_nome: e.target.value
                            }
                        })
                        )}                    
                    />
                </div>
                <div style={{gridColumn: '3 / 4'}} >
                    <label className="inputLabel">Código do banco</label>
                    <CustomInputMask
                        type={"text"}
                        placeholder="000"
                        value={dadosParceiro.conta_bancaria.banco_cod}
                        onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                            ...prev,
                            conta_bancaria: {
                                ...prev.conta_bancaria,
                                banco_cod: e.target.value
                            }
                        })
                        )}
                        mask={'999'}
                        // @ts-ignore
                        maskChar={null}                  
                    />
                </div> */}
                <div style={{gridColumn: '1 / 2'}} >
                    <label className="inputLabel">Agência</label>
                    <CustomInputMask
                        type={"text"}
                        placeholder="0000"
                        value={dadosParceiro.conta_bancaria.agencia}
                        onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                            ...prev,
                            conta_bancaria: {
                                ...prev.conta_bancaria,
                                agencia: e.target.value
                            }
                        })
                        )}  
                        mask={'9999'}
                        // @ts-ignore
                        maskChar={null}                  
                    />
                </div>
                <div style={{gridColumn: '2 / 3'}} >
                    <label className="inputLabel">Conta</label>
                    <CustomInputMask
                        type={"text"}
                        placeholder="00000000000"
                        value={dadosParceiro.conta_bancaria.conta}
                        onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                            ...prev,
                            conta_bancaria: {
                                ...prev.conta_bancaria,
                                conta: e.target.value
                            }
                        })
                        )}
                        mask={'99999999999'}
                        // @ts-ignore
                        maskChar={null}   
                    />
                </div>
                <div style={{gridColumn: '3 / 4'}} >
                    <label className="inputLabel">Dígito</label>
                    <CustomInputMask
                        type={"text"}
                        placeholder="0"
                        value={dadosParceiro.conta_bancaria.conta_digito}
                        onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                            ...prev,
                            conta_bancaria: {
                                ...prev.conta_bancaria,
                                conta_digito: e.target.value
                            }
                        })
                        )}
                        mask={'9'}
                        // @ts-ignore
                        maskChar={0} 
                    />
                </div>
                <div style={{gridColumn: '1 / 4'}} >
                    <label className="inputLabel">Tipo de conta</label>
                    <div className="checkboxContainer" >
                        <label>
                        <input type="checkbox"
                            checked={dadosParceiro.conta_bancaria.tipo === 1 ? true : false}
                            onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                                ...prev,
                                conta_bancaria: {
                                    ...prev.conta_bancaria,
                                    tipo: 1
                                }
                            })
                            )} 
                        />
                        Conta Corrente
                        </label>
                        <label>
                        <input type="checkbox"
                            checked={dadosParceiro.conta_bancaria.tipo === 2 ? true : false}
                            onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                                ...prev,
                                conta_bancaria: {
                                    ...prev.conta_bancaria,
                                    tipo: 2
                                }
                            })
                            )} 
                        />
                        Conta Poupança
                        </label>                        
                    </div>
                    </div>                

                <SubmitButton
                    text="Continuar"
                    color='azul'
                    outline
                    onClick={handleNavigate}
                />


            </form>
            <Loading isLoading={loading} />
        </Container>
    )
}
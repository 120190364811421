import styled from "styled-components";

export const Container = styled.div`
    
    display: flex;
    flex-direction:column ;

    h1{
        padding: 10px;
        margin-bottom: 40px;
        font-size: 2rem;
        align-self: center;
        font-weight: 500;
    }
    form{
        padding: 10px;
        width: 100%;
        max-width: 500px;
        
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        .inputLabel{
            padding-top: 19px;
            padding-bottom: 13px;
            padding-left: 10px;
            display:inline-block;
            font-size: 1rem;
            font-weight: 600;
            
        }

        .dadosGrid{
            display: grid;
            gap: 0 10px;
        }

        .dadosBancoContainer{
            display: grid;
            gap: 0 10px;

            .checkboxContainer{
            [type="checkbox"] {
                vertical-align:middle;
                margin-right: 1%;
            }
            [type="checkbox"]:checked {
                accent-color: var(--roxo-700);
            }
            label {
                width: 120px;
            }
                display: flex;
                justify-content: space-evenly;
            }
        }

        button{
            align-self: center;
            margin: 80px 0 30px;
        }
    }
`
import styled, { keyframes } from "styled-components";

interface showProps{
    show: boolean
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const Modal = styled.div<showProps>`
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    justify-content: center;
    display: flex;
    animation: ${props => props.show ? fadeIn : fadeOut} 0.3s linear;
    transition: visibility 0.3s linear;
    visibility: ${props => props.show ? 'visible' : 'hidden' };

    .modalContainer{
        display: flex;
        flex-direction: column;
        background-color: var(--background);
        border-radius: 27px;
        padding: 2rem;
        align-self: center;
        min-width: 25vw;
        max-width: 40vw;
        max-height: 100vh;
        overflow: auto;

        animation: ${props => props.show ? fadeIn : fadeOut} 0.2s linear;
        transition: visibility 0.2s linear;
        visibility: ${props => props.show ? 'visible' : 'hidden' };

        @media (max-width: 900px) {
          width: 95vw;
          max-width: 95vw;
          max-height: 95vh;
        }
        
        form{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            margin: 5px 0px;

            .inputStyle{
                border: none;
                font-weight: normal;
                align-self: center;

                @media (max-width: 1300px) {
                  height: 0.8rem;
                  padding: 1.33rem;
                }
            }

            h3{          
                margin-bottom: 0.35rem;
            }

            .oneLineInputs{
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;

                .margin-right{
                    margin-right: 0.75rem;
                }                
                
                h4{
                    font-size: 1rem;
                    margin-right: 0.75rem;
                    max-width: 250px;
                }
            }

            .checkboxContainer{
              [type="checkbox"] {
                vertical-align:middle;
                margin-right: 1%;
              }
              [type="checkbox"]:checked {
                accent-color: var(--roxo-700);
              }
              label {
                width: 120px;
              }
              display: flex;
              justify-content: space-evenly;
            }

            .limparFiltroButtonContainer{
                display: flex;
                justify-content: flex-end;

                button{
                    background-color: transparent;
                    border: none;
                    color: rgba(52, 9, 80, 50%);
                }
            }
    
        }
    }
`
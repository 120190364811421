import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { useCadastro } from '../Cadastro';
import { Container } from "./styles";

import { CustomCurrencyInput } from "../../../components/form/CustomCurrencyInput";
import { CustomInputMask } from "../../../components/form/CustomInputMask";
import { InputText } from "../../../components/form/InputText";
import { SubmitButton } from "../../../components/form/SubmitButton";
import { postCriarContaVendedorProps } from '../../../services/models';

export function CadastroEtapa1() {
    const navigate = useNavigate();
    const { handleStep, dadosParceiro, setDadosParceiro } = useCadastro();

    function handleNavigate(e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) {
        e?.preventDefault()
        if(dadosParceiro.conta.nome_fantasia === ''){
            toast.warning('Preencha o nome fantasia para continuar.');
            return
        }
        if(dadosParceiro.conta.vendas_mensais === ''){
            toast.warning('Preencha o faturamento médio mensal para continuar.');
            return
        }
        handleStep();
        navigate('/cadastro/cadastroEtapa2');
    }

    return (
        <Container>
            <h1>Para criarmos o seu acesso, é necessário que <br /> preencha alguns dados.</h1>
            <form onSubmit={handleNavigate} >
                <div style={{gridColumn: '1 / 3'}} >
                    <label className="inputLabel">Razão Social</label>
                    <InputText
                        placeholder="Razão Social"
                        value={dadosParceiro.conta.razao_social}
                        onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                            ...prev,
                            conta: {
                                ...prev.conta,
                                razao_social: e.target.value
                            }
                        })
                        )}
                        disabled
                        style={{backgroundColor: 'transparent'}}
                    />
                </div>
                <div style={{gridColumn: '1 / 3'}} >
                    <label className="inputLabel">Nome Fantasia</label>
                    <InputText
                        placeholder="Nome Fantasia"
                        value={dadosParceiro.conta.nome_fantasia}
                        onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                            ...prev,
                            conta: {
                                ...prev.conta,
                                nome_fantasia: e.target.value
                            }
                        })
                        )}
                    />
                </div>
                <div style={{gridColumn: '1 / 2'}} >
                    <label className="inputLabel">CNPJ</label>
                    <CustomInputMask
                        placeholder="CNPJ"
                        value={dadosParceiro.conta.cnpj}
                        onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                            ...prev,
                            conta: {
                                ...prev.conta,
                                cnpj: e.target.value
                            }
                        })
                        )}
                        mask={"99.999.999/9999-99"}
                        alwaysShowMask={true}
                        disabled
                        style={{backgroundColor: 'transparent'}}
                    />
                </div>
                <div style={{gridColumn: '2 / 3'}} >
                    <label className="inputLabel">Faturamento médio mensal</label>
                    <CustomCurrencyInput
                        placeholder="R$ 1.000,00"
                        value={dadosParceiro.conta.vendas_mensais}                    
                        onValueChange={value => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                            ...prev,
                            conta: {
                                ...prev.conta,
                                vendas_mensais: value
                            }
                        })
                        )}
                        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                        prefix='R$'
                        allowNegativeValue={false}
                        decimalSeparator=","
                        groupSeparator="."
                        decimalScale={2}
                        maxLength={10}
                        //value={saque}
                        //onValueChange={text => { setSaque(text); console.log(text) }}
                    />
                </div>
                <div style={{gridColumn: '1 / 2'}} >
                    <label className="inputLabel">Segmento</label>
                    <InputText
                        placeholder="Segmento"
                        value={dadosParceiro.conta.segmento}
                        onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                            ...prev,
                            conta: {
                                ...prev.conta,
                                segmento: e.target.value
                            }
                        })
                        )}
                    />
                </div>
                <div style={{gridColumn: '2 / 3'}} >
                    <label className="inputLabel">Setor</label>
                    <InputText
                        placeholder="Setor"
                        value={dadosParceiro.conta.setor}
                        onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                            ...prev,
                            conta: {
                                ...prev.conta,
                                setor: e.target.value
                            }
                        })
                        )}
                    />
                </div>
                <div style={{gridColumn: '1 / 3'}} >
                    <label className="inputLabel">Site</label>
                    <InputText
                        placeholder="Site"
                        value={dadosParceiro.conta.site}
                        onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                            ...prev,
                            conta: {
                                ...prev.conta,
                                site: e.target.value
                            }
                        })
                        )}
                    />
                </div>

                <div style={{gridColumn: '1 / 3', margin: '0 auto'}} > 
                    <SubmitButton
                        text="Continuar"
                        color='azul'
                        outline
                        onClick={handleNavigate}
                    />
                </div>

            </form>
        </Container>
    )
}
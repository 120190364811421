import styled from "styled-components";

interface ContainerProps{
  textColor: string
}

export const Container = styled.div<ContainerProps>`
  /* Style The Dropdown Button */
  .dropbtn {
    background-color: transparent;
    color: ${props => props.textColor};
    padding: 16px;
    font-size: 1rem;
    border: none;
    cursor: default;
    display: flex;
    flex-direction: row;

      .icon-down{
        margin-left: 10px;
      }
      
  }

  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 130px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  /* Links inside the dropdown */
  .dropdown-content p {
    color: black;
    padding: 0.75rem 1rem;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content p:hover {background-color: #f1f1f1}

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

  /* Change the background color of the dropdown button when the dropdown content is shown */
  /* .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  } */
`
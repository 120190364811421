import { IoIosPerson } from "react-icons/io";
import { LoginInputs } from "../../components/form/LoginInputs";
import { SubmitButton } from "../../components/form/SubmitButton";
import { Card, Container } from "./styles";
import Logo from '../../assets/imagens/logo.png';
import { putUserAtualizarSenha } from "../../services/service";
import { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import { postLoginAction, postMeAction } from "../../actions";
import { useNavigate } from "react-router";


interface DadosAcessoProps {
    email: string;
    token: string;
    senha: string;
    confirmarSenha: string
}

const schema = Yup.object().shape({
    email: Yup
        .string()
        .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Insira um email valido')
        .required('E-mail obrigatório'),
    token: Yup
        .string()
        .required(),
    senha: Yup
        .string()
        .required(),
    confirmarSenha: Yup
        .string()
        .required()
        .oneOf([Yup.ref('senha'), null], 'Senhas não são iguais.')

})


export function PrimeiroAcesso() {
    const [dadosAcesso, setDadosAcesso] = useState<DadosAcessoProps>({} as DadosAcessoProps);
    const dispatch = useDispatch();
    const navigation = useNavigate();

    async function handleSubmit() {
        const yupRes = await yupValidation();

        if (!yupRes) return
        try {
            //atualiza os dados para o primeiro acesso
            const res = await putUserAtualizarSenha(dadosAcesso.email, dadosAcesso.token, dadosAcesso.senha, dadosAcesso.confirmarSenha);
            //inicia o login
            const token = await dispatch(postLoginAction(dadosAcesso.email, dadosAcesso.senha) as any);
            if (token) {
                const res = await dispatch(postMeAction(token.access_token) as any);
                    if (res) {
                        await localStorage.setItem(
                            '@auth',
                            JSON.stringify({
                                access_token: token.access_token,
                                token_type: token.token_type,
                                expires_in: token.expires_in,
                                login_time: new Date().getTime(),
                                conta_id: res.conta_id
                            })
                        );
                        return navigation("/dashboard/home")
                    }
            }
        } catch (error) {
            toast.error("Verifique seus dados e tente novamente");
        }
    }

    async function yupValidation() {

        const res = await schema
            .validate({
                email: dadosAcesso.email,
                token: dadosAcesso.token,
                senha: dadosAcesso.senha,
                confirmarSenha: dadosAcesso.confirmarSenha
            }).then(() => {
                return true
            }).catch((err) => {
                toast.error(err.errors[0]);
                return false
            })

        return res
    }
    return (
        <Container>
            <div className="leftContent">
                <Card>
                    <div className="avatar">
                        <IoIosPerson
                            className="icon"
                            size={24}
                        />
                    </div>
                    <h2 className="cardText">Primeiro acesso</h2>
                    <form>
                        <input autoComplete="password" type='password'
                            style={{opacity: 0, position: 'absolute', left: '-100000px'}}
                        />
                        <LoginInputs
                            containerClassName="inputStyle"
                            placeholder="Digite seu email*"
                            noIcon
                            onChange={(e) => {
                                setDadosAcesso({
                                    ...dadosAcesso,
                                    email: e.target.value
                                })
                            }}
                            color='#340950'
                        />
                        <LoginInputs
                            containerClassName="inputStyle"
                            placeholder="Digite seu token*"
                            noIcon
                            onChange={(e) => {
                                setDadosAcesso({
                                    ...dadosAcesso,
                                    token: e.target.value
                                })
                            }}
                            color='#340950'
                        />
                        <LoginInputs
                            containerClassName="inputStyle"
                            placeholder="Digite sua senha*"
                            password
                            onChange={(e) => {
                                setDadosAcesso({
                                    ...dadosAcesso,
                                    senha: e.target.value
                                })
                            }}
                            color='#340950'
                        />
                        <LoginInputs
                            containerClassName="inputStyle"
                            placeholder="Confirme sua senha*"
                            password
                            onChange={(e) => {
                                setDadosAcesso({
                                    ...dadosAcesso,
                                    confirmarSenha: e.target.value
                                })
                            }}
                            color='#340950'
                        />

                        <SubmitButton
                            type="button"
                            text="Acessar"
                            color="roxo"
                            className="submitButton"
                            onClick={handleSubmit}
                        />
                    </form>
                </Card>
            </div>

            <div className="rightContent">
                <img src={Logo} alt="logo-jeitto" />

                <h1 className="title">Primeiro acesso?</h1>
                <h2 className="text" >Informe os dados para continuar.</h2>
                <h2 className="text" >O Token você encontrará no seu e-mail.</h2>

            </div>
        </Container>
    )
}
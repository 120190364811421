import { FC } from "react";
import { CurrencyInputProps } from "react-currency-input-field";
import { Input } from "./styles";

interface CustomCurrencyInputProps extends CurrencyInputProps { }

export function CustomCurrencyInput({ ...rest }: CustomCurrencyInputProps) {
    return (
        < Input
            {...rest}
        />
    )


}
import React from "react";

import { Container, HiResContainer } from "./styles";

import bannerCompartilharQrCode from "../../assets/imagens/bannerCompartilharQrCode.jpg"

interface CompartilharQrCodeProps {
  empresa: string
  dataQrCode: string
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
  hiRes?: boolean
}

export function CompartilharQrCode({
  empresa = '',
  dataQrCode,
  setLoading = () => null,
  hiRes = false
}: CompartilharQrCodeProps) {

  if(hiRes){
    return (
      <HiResContainer>
        <div className='qrcodeContainer'>
          <img
            className='qrcode'
            src={`https://api.qrserver.com/v1/create-qr-code/?data=${window.location.origin.toString()}/pagar-qrcode/${dataQrCode}&amp;size=200x200`}
            alt="QR Code"
            onLoad={() => setLoading(false)}
          />
        </div>
          <p>{empresa.toUpperCase()}</p>
        <img
          className="background"
          src={bannerCompartilharQrCode}
          alt=''
        />
      </HiResContainer>
    )
  }else {
    return (
      <Container>
        <div className='qrcodeContainer'>
          <img
            className='qrcode'
            src={`https://api.qrserver.com/v1/create-qr-code/?data=${window.location.origin.toString()}/pagar-qrcode/${dataQrCode}&amp;size=200x200`}
            alt="QR Code"
            onLoad={() => setLoading(false)}
          />
        </div>
          <p>{empresa.toUpperCase()}</p>
        <img
          className="background"
          src={bannerCompartilharQrCode}
          alt=''
        />
      </Container>
    )
  }
}
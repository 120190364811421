import { Card, Container } from "./styles";
import Logo from '../../assets/imagens/logo.png';
import { LoginInputs } from "../../components/form/LoginInputs";
import { SubmitButton } from "../../components/form/SubmitButton";
import { IoIosPerson } from "react-icons/io";
import { useState } from "react";
import { postUserResetarSenha } from "../../services/service";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

interface DadosAcessoProps {
    email: string;

    senha: string;
    confirmarSenha: string
}

const schema = Yup.object().shape({
    email: Yup
        .string()
        .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Insira um email valido')
        .required('E-mail obrigatório'),
    senha: Yup
        .string()
        .required(),
    confirmarSenha: Yup
        .string()
        .required()
        .oneOf([Yup.ref('senha'), null], 'Senhas não são iguais.')

})

export function ResetarSenha() {
    const [dadosAcesso, setDadosAcesso] = useState<DadosAcessoProps>({} as DadosAcessoProps);

    const navigation = useNavigate();
    const params = useParams()

    async function yupValidation() {

        const res = await schema
            .validate({
                email: dadosAcesso.email,
                senha: dadosAcesso.senha,
                confirmarSenha: dadosAcesso.confirmarSenha
            }).then(() => {
                return true
            }).catch((err) => {
                toast.error(err.errors[0]);
                return false
            })

        return res
    }

    async function handleSubmit() {
        const yupRes = await yupValidation();

        if (!yupRes) return
        try {
            await postUserResetarSenha(dadosAcesso.email, dadosAcesso.senha, dadosAcesso.confirmarSenha, params.id!);

            toast.success("Senha redefinida com sucesso!");
            navigation('/')
        } catch (error) {
            toast.error("Verifique seus dados e tente novamente");
        }
    }


    return (
        <Container>
            <div className="leftContent">
                <Card>
                    <div className="avatar">
                        <IoIosPerson
                            className="icon"
                            size={24}
                        />
                    </div>
                    <h2 className="cardText" >Recuperar senha</h2>
                    <form >
                        <LoginInputs
                            containerClassName="inputStyle"
                            placeholder="Digite seu email*"
                            noIcon
                            onChange={(e) => {
                                setDadosAcesso({
                                    ...dadosAcesso,
                                    email: e.target.value
                                })
                            }}
                            color="#340950"
                        />

                        <LoginInputs
                            containerClassName="inputStyle"
                            placeholder="Digite sua senha*"
                            password
                            onChange={(e) => {
                                setDadosAcesso({
                                    ...dadosAcesso,
                                    senha: e.target.value
                                })
                            }}
                            color="#340950"
                        />
                        <LoginInputs
                            containerClassName="inputStyle"
                            placeholder="Confirme sua senha*"
                            password
                            onChange={(e) => {
                                setDadosAcesso({
                                    ...dadosAcesso,
                                    confirmarSenha: e.target.value
                                })
                            }}
                            color="#340950"
                        />

                        <SubmitButton
                            type="button"
                            text="Redefinir senha"
                            color="roxo"
                            className="submitButton"
                            onClick={handleSubmit}
                        />
                    </form>
                </Card>
            </div>

            <div className="rightContent">
                <img src={Logo} alt="logo-jeitto" />


                <h1 className="title" >Recuperação de senha</h1>
                <h2 className="text" >Insira sua nova senha.</h2>
            </div>
        </Container>
    )
}


import React from "react";
import styled from "styled-components";

interface ContainerProps {
  hasBorder: boolean
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  max-height: 40px;
  padding: 10px;
  display: flex;
  padding-left: 20px;
  outline: none;
  background-color:var(--input-background);
  color: var(--roxo-700);
  font-size: 1rem;
  border-radius: 18px;
  font-weight: 300;
  border: ${props => props.hasBorder ? "1px solid var(--roxo-700)" : "none"};

  input{
    width: 100%;
    color: var(--input-text-color);
    background-color: transparent;
    outline: none;
    border: none;
  }

  input::placeholder{
      color: var(--input-text-color);
  }

`
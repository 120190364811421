import { AiFillCaretDown } from "react-icons/ai";
import { Container } from "./styles";

interface dropdownProps{
  title: string,
  textColor?: string,
  content: string[],
  callback: (item: string) => void
}

export function Dropdown ({title = 'titulo', textColor = 'white', content = ['item'], callback}: dropdownProps) {
  return(
    <Container textColor ={textColor} >
      <div className="dropdown">

        <div className="dropbtn">
          <span>
            {title} 
          </span>
          <div>
            <AiFillCaretDown
              size={14}
              className='icon-down'
            />
          </div>
        </div>

        <div className="dropdown-content">
          {content.map((item, index) => (
            <p 
              key={item}
              onClick={() => callback(item)}
            >
              {item}
            </p>
          ))}          
        </div>
        
      </div>
    </Container>
  )
}
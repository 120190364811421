import React, { useState } from "react";
import { IoIosArrowDown } from 'react-icons/io';

import { Container, MesButton, MesDropdown, MesDropdownButton } from "./styles";

interface SelecionarDataProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  callback: (mes: number) => void;
}

const meses = ["JANEIRO", "FEVEREIRO", "MARÇO", "ABRIL", "MAIO", "JUNHO", "JULHO", "AGOSTO", "SETEMBRO", "OUTUBRO", "NOVEMBRO", "DEZEMBRO"];

export function SelecionarData({ callback, ...rest }: SelecionarDataProps) {
  const currentMonth = meses[new Date().getMonth()];
  const [showDropdown, setShowDropdown] = useState(false);
  const [mes, setMes] = useState(currentMonth);

  const handleSelecionarMes = (obj: string) => {
    setMes(obj);
    setShowDropdown(false);
  }

  return(
    <Container>
      <MesButton animate={showDropdown} onClick={() => setShowDropdown(true)}>
        {mes} 
        <IoIosArrowDown className="icon" size={20} />
      </MesButton>
      {showDropdown &&
        <MesDropdown>
          {meses.map( obj => 
            <MesDropdownButton
              key={obj}
              onClick={() => {
                handleSelecionarMes(obj);
                callback(meses.indexOf(obj)+1);
              }
            }>
              {obj}
            </MesDropdownButton>
          )}
        </MesDropdown>
      }
    </Container>
  )
}
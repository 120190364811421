import { IoMdMenu } from 'react-icons/io';
import { IoIosArrowDown } from 'react-icons/io';
import { FiSearch } from 'react-icons/fi';
import Avatar from "react-avatar";

import { Container, Header } from "./styles";

import { HeaderDasboard } from "../../../components/HeaderDasboard";
import CadastroConcluido from '../../../assets/imagens/cadastroConcluido.svg'
import { Loading } from "../../../components/Loading/Loading";


export function AguardandoAnalise() {

    return (
        <Container>
            <Loading isLoading={false} />
            {false && <Header>
                {/* <span className="location">Home</span> */}
                {/* <div className="inputSearchWrapper">
                    <FiSearch />
                    <input type="text" />
                </div> */}
                <div className="profileWrapper">
                    <div className="profile">
                        <Avatar textSizeRatio={3} size="53px" name="João Guerber" className="avatar" />
                        {/* <IoMdMenu
                            size={60}
                            className="menuMobileBtn"
                        /> */}
                        <div className="profileContent">
                            <label className="name">João Guerber</label>
                            <label className="userID">05529884139</label>
                        </div>
                        {/* <IoIosArrowDown className="arrowDownIcon" size={19} fill="#4C4B97" /> */}
                    </div>
                    <div className="profileOptions">
                        <ul>
                            <li>Editar perfil</li>
                        </ul>
                    </div>
                </div>
            </Header>}

            <section className="homeContent">
                <h2>Seu perfil está sendo avaliado!<br/>Você receberá um e-mail quando for aprovado.</h2>

                <img src={CadastroConcluido} alt="Esperar" />
            </section>
        </Container>
    )
}
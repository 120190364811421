import React, { useEffect, useState } from "react";

import useAuth from "../../hooks/useAuth";
import { getBancos } from "../../services/service";

import { Container } from "./styles";

interface SelectBancoProps {
  hasBorder?: boolean;
  setBancoNome: (arg: string) => void;
  setBancoCod: (arg: string) => void;
}

export function SelectBanco({ hasBorder = false, setBancoNome, setBancoCod }: SelectBancoProps){
  const {token} = useAuth();
  const [data, setData] = useState([{banco: 'Ocorreu um erro. Tente novamente mais tarde'}]);

  const loadData = async () => {
    try {
      const res = await getBancos(token);
      setData(res.data);
    } catch (err) {
      console.log(err);
      
    }
  }

  useEffect(() => {
    loadData();
  },[])

  return(
    <Container hasBorder={hasBorder}>
      <input
        placeholder="Banco"
        list="bancos"
        onBlur={e => {
          setBancoNome(e.target.value.slice(6));
          setBancoCod(e.target.value.slice(0, 3));
        }}
      />
        <datalist id="bancos">        
          {data.map((obj, id) => <option id={obj.banco} >{obj.banco}</option>)}
        </datalist>
    </Container>
  )
}
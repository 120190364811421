import { useEffect, useRef, useState } from "react";
import usePreviewPost from "../../../hooks/usePreviewPost";

import { newBlogPostProps } from "../../../services/models";
import { Container, TagContainer } from "./style";

const Tags = ({tags = [{tag: ''}]}) => {
  return (
    <TagContainer>{
      tags.map((tag, index) => 
        <p
          key={index}
          onClick={() => window.open(`/blog?filter[tags]=${tag.tag}`, "_blank", "noopener noreferrer")}
        >
          {tag.tag}
        </p>
      )
    }</TagContainer>
  )
}

export function BlogPreVisualizarPost (){
  const {preview} = usePreviewPost();
  const [post] = useState<newBlogPostProps>({
    titulo: preview.titulo,
    descricao: preview.descricao,
    banner: preview.banner,
    tags: preview.tags,
    categoria_id: preview.categoria_id,
    conteudo: preview.conteudo,
  });
  const spanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    spanRef.current!.innerHTML = post.conteudo ? post.conteudo : '';
  })
  
  return (
    <Container>
      <h1>{post.titulo}</h1>
      <h2>{post.descricao}</h2>
      {post.banner ?
        //@ts-ignore
        <img src={post.banner} alt={'capa'} />
      : null}      
      <Tags
        //@ts-ignore
        tags={post.tags}
      />
      <div style={{marginBottom: '20px'}} />
      <span ref={spanRef} className='conteudo' />
    </Container>
  )
}
import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    position: relative;
    display:flex ;
    align-items: center;

    input{
        width: 100%;
        height: 40px;
        padding:10px;
        padding-left: 20px;
        outline: none;
        background-color:var(--input-background);
        color: var(--roxo-700);
        font-size: 1rem;
        border-radius: 18px;
        font-weight: 300;
        border: 1px solid var(--roxo-700);
        


        &::placeholder{
            color: var(--input-text-color);
        }
    }

    .icon{
        cursor: pointer;

        position:absolute;
        right: 10px;

    }
`
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Container } from './styles';

interface QuillTextEditorProps {
  value: string;
  setValue: (arg: string)=>void;
}

export function QuillTextEditor({value, setValue, ...props}: QuillTextEditorProps) {

  return (
    <Container>
      <ReactQuill
        {...props}
        theme="snow"
        value={value}
        onChange={setValue}
        modules={
          {
            toolbar: [
              [{ 'header': [2, 3, 4, false] }, { 'align': [] }],
              // ['blockquote'],
              [{'color': ['#340950', '#07B7E6', '#F58220', '#F15A22', '#F8CF5A', '#5CEE79', '#FFF']}, {'background': ['#340950', '#07B7E6', '#F58220', '#F15A22', '#F8CF5A', '#5CEE79', '#FFF']}, 'bold', 'italic', 'underline','strike'],
              [
                {'list': 'ordered'}, {'list': 'bullet'},
                // {'indent': '-1'}, {'indent': '+1'}
              ],
              ['link', 'image', 'video'],
              // ['clean']
            ],
          }
        }      
        formats={[
          'header', 'align', 
          'color', 'background', 'bold', 'italic', 'underline', 'strike', 'blockquote',
          'list', 'bullet', 'indent',
          'link', 'image', 'video'
        ]}
      />
    </Container>
  )
}
import styled from 'styled-components';

export const Container = styled.section`
  --spacing: 160px;
  @media (max-width: 900px) {
    --spacing: 20px;
  }

  .header, .footer{
    background-image: linear-gradient(90deg, var(--laranja-500), var(--laranja-600));
    /* padding: 20px 0px; */
    width: 100%;
    height: 100px;
    display: flex;
    align-content: center;
    justify-content: space-between;

    @media only screen and (max-width: 900px) {       
      flex-wrap: wrap;
      min-height: 100px;
      height: 150;
    }

    .logo{
      width: 76px;
      margin: 0px 30px 0px var(--spacing);
      object-fit: contain;

      @media only screen and (max-width: 900px) {       
        margin: 0px 10px 0px var(--spacing);
      }

    }

    .headerContainer{
      margin: 0px var(--spacing);
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 900px) {
        margin: 0px;
        width: 100%;
        justify-content: space-evenly;
      }
      
      form{
        position: relative;

        .search-icon{
          position: absolute;
          top: 6px;
          left: 6px;
          color: var(--white);
        }

        input{
          width: 300px;
          height: 35px;
          padding: 10px;
          padding-left: 32px;
          outline: none;
          background-color:var(--input-background);
          color: var(--white);
          font-size: 1rem;
          border-radius: 18px;
          font-weight: 300;
          border: 1px solid var(--white);

          &::placeholder{
              color: var(--white);
          }

          @media only screen and (max-width: 900px) {
            width: 150px;        
          }
        }
      
      }

      .person-icon{
        color: var(--white);
        margin-left: 10px;
        cursor: pointer;
      }

    }

    .rightFooterContainer{
      display: flex;
      flex-direction: row;
      align-items: center;
      /* justify-content: center; */
      color: var(--white);

      p{
        line-height: 1.5rem;
      }
    }
    
    .socialIconsContainer{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 0px var(--spacing); 
    }

  }

    .content{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      /*100vh - (header e footer)  */
      min-height: calc(100vh - (2 * 100px));
      height: auto;

    }

`;

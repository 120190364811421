import styled from 'styled-components';

export const Container = styled.section`

    .header{    
      padding: 36px 64px 0px 64px;
      display: flex;
      justify-content: space-between;

      .nomePagina{
          font-size: 2rem;
      }

    }

    .content{
      display: flex;
      flex-direction: column;
      background-color: var(--background);
      margin: 36px 64px 0px 64px;
      border-radius: 27px;
      padding: 30px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

      @media only screen and (max-width: 900px) {
        width: 100%;
        height: 100%;
        padding: 20px;
        margin: 0px;
        margin-top: 30px;

      }

    }

`;

import React from "react";
import { 
    Banner1,
    Banner2,
    Banner3,
    Banner4
 } from "../../../assets/imagens/enxoval";

import { Container } from "./styles";

export function Enxoval() {

    return (
        <Container>
            <header className="headerTabela">
                <h1 className="nomePagina">Enxoval</h1>                
            </header>
            <main>
                <div className="tabelaContainer">
                    <h3 style={{marginBottom: '30px'}}>Clique nas imagens para fazer o download</h3>

                    <div className="imagesContainer">
                        <a href="https://www.dropbox.com/sh/2952zr9hc6x8fpz/AAAxWiuXJ2tJVDeMlZQrcl4za/_sa%C3%ADda?dl=0&preview=Banner+enxoval+m%C3%ADdia+120x600.png&subfolder_nav_tracking=1" target="_blank" rel="noopener noreferrer">
                            <img src={Banner1} alt='Banner 120x600' />
                        </a>
                        
                        <div className="secondBlock">
                            <a href="https://www.dropbox.com/sh/2952zr9hc6x8fpz/AAAxWiuXJ2tJVDeMlZQrcl4za/_sa%C3%ADda?dl=0&preview=Banner+enxoval+m%C3%ADdia+240x240.png&subfolder_nav_tracking=1" target="_blank" rel="noopener noreferrer">
                                <img src={Banner2} alt='Banner 240x240' />
                            </a>
                            <a href="https://www.dropbox.com/sh/2952zr9hc6x8fpz/AAAxWiuXJ2tJVDeMlZQrcl4za/_sa%C3%ADda?dl=0&preview=Banner+enxoval+m%C3%ADdia+300x250.png&subfolder_nav_tracking=1" target="_blank" rel="noopener noreferrer">
                                <img src={Banner3} alt='Banner 300x250' />
                            </a>
                            <a href="https://www.dropbox.com/sh/2952zr9hc6x8fpz/AAAxWiuXJ2tJVDeMlZQrcl4za/_sa%C3%ADda?dl=0&preview=Banner+enxoval+m%C3%ADdia+720x90.png&subfolder_nav_tracking=1" target="_blank" rel="noopener noreferrer">
                                <img src={Banner4} alt='Banner 720x90' />
                            </a>
                        </div>
                    </div>
                </div>
            </main>
        </Container>
    )
}
import styled from "styled-components";

interface ColorProps{
    color: string
}

export const Container = styled.div<ColorProps>`
position: relative;
display: flex;
align-items: center;

input{
    width: 25em;
    height: 40px;
    background-color: transparent !important;
    padding: 25px 20px;
    padding-left: 50px;
    outline: none;
    color: ${props => props.color};
    font-size: 1rem;
    border-radius: 30px;
    font-weight: 300;
    border: 1px solid ${props => props.color};

    &::placeholder{
        color: ${props => props.color};
    }
}

    .left-icon{
        cursor: pointer;

        position:absolute;
        right: 20px;
        color: ${props => props.color};

    }
    
    .right-icon{

        position:absolute;
        left: 20px;
        color: ${props => props.color};

    }
`

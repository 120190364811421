import styled from 'styled-components';

export const Container = styled.section`
  min-height: 100vh;
  width: 100%;
  display:flex ;
  flex-direction: row;
  

  .mobileMenu{
    position: absolute;
    right: 5px;
    top: 5px;

    @media only screen and (min-width: 900px) {
     display: none;
    }

  }

  .box1, .box2{
    min-height : 100vh ;
  }
  .box1{
    position: relative;
    width: 350px;
    background-color: var(--background-dashboard);
  }
  .box2{
    background-color: var(--background-dashboard);
    width: calc(100% - 350px);
    
  }

  @media only screen and (max-width: 900px) {
    .box1{
      display: none;
    }
    .box2{
      width: 100%
    }
  }
`;

import React, { useRef, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { IoPerson, IoSearch } from "react-icons/io5";

import useAuth from "../../../hooks/useAuth";
import { getBlogCategoriasList } from "../../../services/service";
import { Container } from "./styles";

import Logo from '../../../assets/imagens/logo.png';
import { ReactComponent as SocialIconInstagram } from "../../../assets/icones/socialIconInstagram.svg";
import { ReactComponent as SocialIconFacebook } from "../../../assets/icones/socialIconFacebook.svg";
import { ReactComponent as SocialIconYouTube } from "../../../assets/icones/socialIconYouTube.svg";
import { Dropdown } from "../../../components/Dropdown";

export function BlogRouter() {
    const navigate = useNavigate();
    const location = useLocation();
    const {token} = useAuth();
    const inputRef = useRef<HTMLInputElement>(null);
    const [dropdownData, serDropdownData] = useState<string[]>([]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(inputRef.current!.value === '') return
        
        navigate(`/blog?filter[titulo]=${inputRef.current!.value}`);
    };

    const handleNavigateDashboard = () => {
        navigate('/dashboard');
    };
    
    const handleNavigateBlogHome = () => {
        if(location.pathname === '/blog' && location.search === ''){
            window.location.reload();
        } else {
            navigate('/blog');
        }
    };
    
    const parseDropdownContent = (arr: {nome: string, descricao: string}[]) => {
        const newArr = arr.map(item => {
            return item.nome
        })
        serDropdownData(newArr);
    };

    useEffect(() => {
        (async() => {
            try {
                const res = await getBlogCategoriasList(token);
                parseDropdownContent(res.data.data);
            } catch (err) {
                console.log(err);
            }
        })()
    },[])

    return ( 
        <Container>
            <header className="header" >
                <img className="logo" src={Logo} alt="logo-jeitto" onClick={handleNavigateBlogHome} />

                <div className="headerContainer">
                    <Dropdown
                        title="Categorias"
                        content={dropdownData}
                        callback={(arg) => navigate(`/blog?filter[categoria]=${arg}`)}
                    />                    
                    <form onSubmit={handleSubmit} >
                        <IoSearch
                            onClick={handleNavigateDashboard}
                            className="search-icon"
                            size={24}
                        />
                        <input ref={inputRef} placeholder='Buscar notícias'/>
                    </form>
                    <IoPerson
                        onClick={handleNavigateDashboard}
                        className="person-icon"
                        size={24}
                    />
                </div>
            </header>
            <div className="content">
                <Outlet />
            </div>
            <footer className="footer">
                <div className="rightFooterContainer">
                    <img className="logo" src={Logo} alt="logo-jeitto" onClick={handleNavigateBlogHome} />
                    <div>
                        <p>2022 Jeitto S.A. - Instituição de pagamento. 32.432.642/4532-54</p>
                        <p>Rua J-35, Q 66, L 12, Setor Jaó, Goiânia, GO - 7564239-543</p>
                    </div>
                </div>

                <div className="socialIconsContainer">
                    <SocialIconInstagram
                    style={{width: 30, cursor: 'pointer'}}
                    onClick={() => window.open("https://www.instagram.com/jeitto_app/") }
                    />
                    <SocialIconFacebook
                    style={{width: 30, cursor: 'pointer', margin: "0 10"}}
                    onClick={() => window.open("https://www.facebook.com/JeittoApp/") }
                    />
                    <SocialIconYouTube
                    style={{width: 30, cursor: 'pointer'}}
                    onClick={() => window.open("https://www.youtube.com/channel/UCv6kviK_Sy4dr2W3-st0Ekg")}
                    />
                </div>

            </footer>
        </Container>
    )
}
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    h1{
        padding: 0 20px ;
        font-size: 2rem;
        align-self: center;
        font-weight: 500;
        align-self: center;
        margin-bottom: 50px;
    }
    .radioWrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 28px;
        width: 100%;
        max-width: 500px;
        margin:0 auto;
        padding: 2%;
        
        button{
            margin-top:80px;
            align-self:center;
            margin-bottom: 30px;
        }
    }


`

export const RadioCard = styled.div`
    width: 100%;
    max-width: 450px;
    line-height: 1.5rem;

    input{
        display: none;
    }

    .customRadio{        
        width: 26px;
        height: 26px;
        border:1px solid var(--roxo-700);
        background-color: white;
        display: block;
        color: white;
        cursor: pointer;
        padding: 2px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
    .radioCheck{
        padding: 25px;
        gap: 20px;
        display: flex;
        flex-direction:row ;
        content: '';
        background-color: transparent;
        width: 100%;
        height: 100%;
        border:1px solid var(--roxo-700);
        cursor: pointer;
        align-items: center;
        justify-content: space-between;


        .radioContentWrapper{
            font-size: 0.75rem;
            font-weight: 300;
            width: 90%;
        }
    }
    input:checked + .radioCheck{
        /* border: none; */
        background: var(--laranja-600);
       
        .radioContentWrapper{
            color: white;
        }
        .customRadio{
            border: none;
            background-color: var(--roxo-700);
        }
    }

    
`;
import styled from 'styled-components';

export const Container = styled.button`
  height:56px;
  width: 56px;
  border-radius: 50%;
  background-color: var(--azul-300);
  border: none;
  display: flex;
  align-items:center;
  justify-content:center;
  svg{
    fill: var(--white);
  }
`;

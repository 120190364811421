import { useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import { useCadastro } from "../Cadastro";
import { Container, InputSms } from "./styles";

import { SubmitButton } from "../../../components/form/SubmitButton";
import { HeaderCadastro } from "../../../components/HeaderCadastro";
import { ReactComponent as EmailIcon } from '../../../assets/icones/emailIcon.svg';
import { postVerificarContato, postVerificarContatoReenviar } from "../../../services/service";
import { Loading } from "../../../components/Loading/Loading";

// doc input sms https://www.npmjs.com/package/react-code-input
export function CadastroEtapa5() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState('');
    const { handleStep, handleStepBack, dadosParceiro } = useCadastro();

    async function handleNavigate(e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) {
        e?.preventDefault()
        if(token.length !== 6) return

        try {
            setLoading(true);
            const res = await postVerificarContato(
                dadosParceiro.conta.cnpj,
                'email',
                token,
            );
            setLoading(false);
            handleStep();
            navigate('/cadastro/cadastroEtapa6');
        } catch (err) {
            setLoading(false);
            console.log(err);
            toast.error('O token de verificação de e-mail está errado.');            
        }
    }

    const handleReenviarToken = async () => {
        try {
            setLoading(true);
            const res = await postVerificarContatoReenviar(
                dadosParceiro.conta.cnpj,
                'email',
            );
            if(res.status === 200){
                setLoading(false);                
                toast.success('O token de verificação de e-mail foi reenviado.');
            } else {
                setLoading(false);
                toast.error('Ocorreu um erro. Tente novamente mais tarde.');
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            toast.error('Ocorreu um erro. Tente novamente mais tarde.');          
        }
    }
    console.log(dadosParceiro)
    return (
        <Container>
            <HeaderCadastro callback={handleStepBack}/>
            <h1>Digite o token de validação de <span>e-mail</span></h1>
            <h3>
                Uma mensagem de texto foi enviada para o seu e-mail cadastrado.
                <br />Digite o código abaixo para prosseguir com o cadastro.
            </h3>
            <div
                className="reenviarToken"
                onClick={handleReenviarToken}
            >
                <EmailIcon                
                    width={50}
                    height={33}
                />
                <h2>REENVIAR TOKEN</h2>
            </div>
            <form onSubmit={handleNavigate} >
                <div className="campos">
                    <InputSms
                        value={token}
                        onChange={value => setToken(value)}
                        name="sms"
                        fields={6}
                        inputMode="verbatim"
                    />
                </div>
                <SubmitButton
                    type="submit"
                    text="Continuar"
                    color='azul'
                    outline
                    onClick={handleNavigate}
                />
            </form>
            <Loading isLoading={loading} />
        </Container>
    )
}
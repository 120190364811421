import styled from 'styled-components';

export const Container = styled.div`  
  .paginationContainer{
  display: flex;
  justify-content: center;
  align-items: center;

    .pageNumber{
      border: none;
      background-color: transparent;
      font-size: 1.25rem;
    }

    .icon{
      cursor: pointer;
    }

  }

`
import styled from "styled-components";

interface PagarButtonProps {
    isSelected: boolean,
}

interface QrCodeButtonProps {
    isShown: boolean,
}

export const Container = styled.section`

    .title{
        font-size: 2rem;
        font-weight: bold;
        color: var(--roxo-700);

    }

    .bar{
        width: 100%;
        align-self: center;
        border-top: 1px solid var(--roxo-700);
        margin-top: 30px;
        margin-bottom: 30px;
    }

    form{
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin: 60px 0px;

        .selecionarIdButton{
            width: 50%;
            height: 40px;
            padding:10px;
            padding-left: 20px;
            outline: none;
            background-color:var(--input-background);
            color: var(--roxo-700);
            font-size: 1rem;
            border-radius: 18px;
            border: none;
            font-weight: 100;
            align-self: center;
            text-align: left;


            &::placeholder{
                color: var(--input-text-color);
            }
        }

        .inputStyle{
            border: none;
            font-weight: normal;
            width: 50%;
            align-self: center;
        }
   
    }

`

export const PagarButton = styled.button<PagarButtonProps>`
    background-color: transparent;
    border: none;
    color: var(--roxo-700);
    font-size: 1.25rem;
    padding-bottom: 5px;

    font-weight: ${props => props.isSelected ? 'bold' : 'normal' };
    border-bottom: ${props => props.isSelected ? "4px solid": 'none' };
`

export const QrCodeButton = styled.button<QrCodeButtonProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 118px;
    height: 118px;
    border: none;
    background-color: var(--roxo-700);
    border-radius: 16px;
    opacity: 0.8;
    color: var(--white);
    padding: 20px;
    position: absolute;
    top: 95px;
    right: 60px;
    visibility: ${props => props.isShown ? 'visible' : 'hidden' };

    &:hover{
        opacity: 1;

    }
            
        
`

export const SubmitButton = styled.button`
    width: 213px;
    height: 49px;
    background-color: var(--white);
    color: var(--roxo-700);
    border: 2px solid var(--roxo-700);
    border-radius: 30px;
    align-self: center;

    &:hover {
        background-color: var(--roxo-700);
        color: var(--white);
        border: none;

    }
`
import { useEffect, useState } from "react";
import { FiCheckCircle } from "react-icons/fi";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import useAuth from "../../../../hooks/useAuth";
import { getTodosContaVendedor, postCriarQrCode } from "../../../../services/service";

import { CustomInputMask } from "../../../../components/form/CustomInputMask";
import { InputText } from "../../../../components/form/InputText";
import { FullScreenModal } from "../../../../components/FullScreenModal";
import { Loading } from "../../../../components/Loading/Loading";
import PageNavigation from "../../../../components/PageNavigation";

import { Container, SubmitButton } from "./styles";

interface criarQrCodeDataProps{
    descricao: string;
	expiracao: string;
}

export function CriarQrCode() {
    const navigate = useNavigate();
    const {token, contaId} = useAuth();
    const [criarQrCodeData, setCriarQrCodeData] = useState({} as criarQrCodeDataProps);
    const [showSelectIdModal, setShowSelectIdModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState({nome: '', id: ''});
    
    const parseData = (data: string) => {
        if(data === null || data === undefined || data === ''){
            return '';
        } else {
            const [D, M, A] = data.split('/')
            return `${A}-${M}-${D}`
        }
    }

    const handleSubmit = async () => {
        try {
            const res = await postCriarQrCode(token, criarQrCodeData.descricao, parseData(criarQrCodeData.expiracao), contaId === null ? selectedUser.id : contaId)
            if(res.statusText === "Created"){
                toast.success("Seu Qr Code foi criado com sucesso!")
                navigate('../')
            }else{
                toast.error("Ocorreu um erro! Tente novamente mais tarde")

            }
        } catch(err) {
            console.log(err);
            toast.error("Ocorreu um erro! Tente novamente mais tarde")

        }
    }

    const SelectUserIdModal = () => {

    const [data, setData] = useState([{nome: '', email: '', id: ''}]);
    const [pagina, setPagina] = useState({current: 1, last: 1});
    const [loading, setLoading] = useState(false);

    const mudarPaginaData = async() => {
        setLoading(true);
        try {
            const res = await getTodosContaVendedor(
                token, 
                `?page=${pagina.current}`
            );
            setData(res.data.data);
            setPagina(prev => ({...prev, last: res.data.last_page}));
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    }
    
    useEffect(() => {
        mudarPaginaData();
    },[pagina.current])

        return(
            loading
            ?
            <Loading isLoading={loading} />
            :
            <FullScreenModal show={showSelectIdModal} setShow={setShowSelectIdModal} >
                <table cellPadding={0} cellSpacing={20}>
                            <thead>
                                <tr>
                                    <td>NOME</td>
                                    <td>EMAIL</td>
                                    <td></td>
                                </tr>
                            </thead>

                            <tbody>
                                    {data?.map((obj, idx) => (
                                    <tr key={idx}>
                                        <td>{obj.nome}</td>
                                        <td>{obj.email}</td>
                                        <td>                                        
                                            <FiCheckCircle                                        
                                                size={26}
                                                color="#5CEE79"
                                                fill='transparent'
                                                style={{cursor: 'pointer'}}
                                                onClick={() => {
                                                    setShowSelectIdModal(false);
                                                    setSelectedUser({nome: obj.nome, id: obj.id});
                                                }}
                                            />                                            
                                        </td>
                                    </tr>
                                    ))}
                            </tbody>
                        </table>

                        <PageNavigation pagina={pagina} setPagina={setPagina} />
            </FullScreenModal>
        )
    }

    return (
        <Container>
            <h2 className="title">Criar Qr Code</h2>
            <div className="bar" />

            <form>
                {contaId === null &&
                    <button
                        className="selecionarIdButton"
                        onClick={(e) => {
                            e.preventDefault();
                            setShowSelectIdModal(true);
                        }}
                    >
                        {selectedUser.id === '' ? 'Selecione uma conta' : selectedUser.nome}
                    </button>
                }

                <InputText
                    value={criarQrCodeData.descricao}
                    onChange={(e) => {
                        setCriarQrCodeData( prev => ({
                            ...prev,
                            descricao: e.target.value
                        }))
                    }}
                    className="inputStyle"
                    placeholder={"Descrição"}
                />                

                <CustomInputMask
                    value={criarQrCodeData.expiracao}
                    onChange={(e) => {
                        setCriarQrCodeData( prev => ({
                            ...prev,
                            expiracao: e.target.value
                        }))
                    }}
                    mask={"99/99/9999"}
                    placeholder={"Data de expiração"}
                    className="inputStyle"
                />

                <SubmitButton onClick={e => {e.preventDefault(); handleSubmit();}} >CRIAR QR CODE</SubmitButton>
            </form>

            {showSelectIdModal && <SelectUserIdModal />}
        </Container>
    )
}
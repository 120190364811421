import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Bar, BarChart, Cell, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import useAuth from "../../../hooks/useAuth";
import { getDashboard, getTodasTransacoes } from "../../../services/service";
import { CardsContainer, Container, Table } from "./styles";

import { SelecionarData } from "../../../components/SelecionarData";
import { Loading } from "../../../components/Loading/Loading";

interface Status {
		transacoes: number;
		status: number;
		status_name: string;
		status_description: string;
}

interface Grafico2 {
		transacoes: number;
		status: number;
		status_name: string;
		status_description: string;
}

interface Grafico1 {
		mes: string;
		transacoes: number;
}

interface DashboardData extends Array<any>{
		media_por_dia: number;
		status: Status[];
		total_transacoes: number;
		grafico_2: Grafico2[];
		grafico_1: Grafico1[];
}

interface transacoesTableProps {
  id: string;
  nsu_transaction: string;
  value: string;
  cpf: string;
  name: string;
  email: string;
  agency_code: string;
  expiration_date: string;
  created_at: string;
  status: number;
}

export function Home() {
    const navigate = useNavigate();
    const {token} = useAuth();
    const [data, setData] = useState<DashboardData[]>([]);
    const [dataTransacoes, setDataTransacoes] = useState<transacoesTableProps[]>([]);
    const [loading, setLoading] = useState(false);

    const getData = async (mes? : number) => {
        setLoading(true);
        try {
            const res = await getDashboard(token, mes);
            setData(res.data);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const getTransacoes = async () => {
      setLoading(true);
      try {
        const res = await getTodasTransacoes(token);
        setDataTransacoes(res.data.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }

    const parseValor = (valor: string) => {
      if(valor === null || valor === undefined || valor === ''){
          return 0.00.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      } else {
          return parseFloat(valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      }
    }
    
    const parseData = (data: string, dataEHoraCriacao = false, dataCriacao = false) => {
      if(data === null || data === undefined || data === ''){
          return 'Sem vencimento';
      } else if (dataEHoraCriacao) {
          return `${new Date(data).toLocaleTimeString('pt-BR', {hour: '2-digit', minute: '2-digit'})} - ${new Date(data).toLocaleDateString('pt-BR')}`;
      } else if (dataCriacao) {
          return `${new Date(data).toLocaleDateString('pt-BR')}`;
      } else {
          const [yyyy,mm,dd] = data.split('-');
          return `${dd}/${mm}/${yyyy}`;
          // return `${new Date(data).toLocaleDateString('pt-BR')}`;
      }
    }

    const parseStatus = ( status: number | undefined) => {
      switch (status) {
        case 0: 
          return <span style={{padding: '5px 10px', borderRadius: '32px', backgroundColor: '#C9DBF2', color: '#75B1ED'}} >Pendente</span>;
        
        case 1: 
          return <span style={{padding: '5px 10px', borderRadius: '32px', backgroundColor: '#F1E3D4', color: 'orange'}} >Expirada</span>;
        
        case 2: 
          return <span style={{padding: '5px 10px', borderRadius: '32px', backgroundColor: '#C9ECE7', color: '#00B57D'}} >Confirmada</span>;
        
        case 3: 
          return <span style={{padding: '5px 10px', borderRadius: '32px', backgroundColor: '#ECC9D2', color: '#ED757D'}} >Sem limite</span>;
        
        case 4: 
          return <span style={{padding: '5px 10px', borderRadius: '32px', backgroundColor: '#ECC9D2', color: '#ED757D'}} >Erro</span>;
        
        case 5: 
          return <span style={{padding: '5px 10px', borderRadius: '32px', backgroundColor: '#ECC9D2', color: '#ED757D'}} >Sem limite POS/PRE</span>;
        
        case 6: 
          return <span style={{padding: '5px 10px', borderRadius: '32px', backgroundColor: '#ECC9D2', color: '#ED757D'}} >Valor não alcançado</span>;
        
        case 7: 
          return <span style={{padding: '5px 10px', borderRadius: '32px', backgroundColor: '#ECC9D2', color: '#ED757D'}} >Limite bloqueado</span>;
        
        case 8: 
          return <span style={{padding: '5px 10px', borderRadius: '32px', backgroundColor: '#ECC9D2', color: '#ED757D'}} >Negado</span>;
        
        default:
          return <span style={{padding: '5px 10px', borderRadius: '32px', backgroundColor: '#ECC9D2', color: '#ED757D'}} >Erro</span>;
      }
    }

    useEffect(() => {
        getData();
        getTransacoes();
    },[])

    return (
        <Container>
            <header className="headerTabela">
                <h1 className="nomePagina">DASHBOARD</h1>
                <SelecionarData callback={getData}/>
            </header>

            <CardsContainer>
                {data[1]?.status[0]?.transacoes &&
                <div className="card">
                  <p className="title" >{data[1]?.status[0]?.transacoes}</p>
                  <p className="text" >{data[1]?.status[0]?.status_description?.toUpperCase()}</p>
                </div>
                }
                {data[1]?.status[1]?.transacoes &&
                <div className="card">
                    <p className="title" >{data[1]?.status[1]?.transacoes}</p>
                    <p className="text" >{data[1]?.status[1]?.status_description?.toUpperCase()}</p>
                </div>
                }                
                {data[1]?.status[2]?.transacoes &&
                <div className="card">
                    <p className="title" >{data[1]?.status[2]?.transacoes}</p>
                    <p className="text" >{data[1]?.status[2]?.status_description?.toUpperCase()}</p>
                </div>
                }
                <div className="card">
                    <p className="title" >{data[0]?.media_por_dia}</p>
                    <p className="text" >MÉDIA DIÁRIA</p>
                </div>
                <div className="card">
                    <p className="title" >{data[2]?.total_transacoes}</p>
                    <p className="text" >TOTAL DE TRANSAÇÕES</p>
                </div>                
            </CardsContainer>

            <div className="infoContainer">
                <div className="graphContainer">
                    <h2 className="title">TOTAL DE TRANSAÇÕES</h2>          
                    <ResponsiveContainer width='99%'>
                        <LineChart data={data[4]?.grafico_1} margin={{ top: 40, bottom: 20, left: 20, right: 40 }}>
                            <XAxis
														 dataKey="mes" 
														 axisLine={false} 
														 padding={{ left: 30 }} 
														 tick={{ fill: '"#340950"' }} 
														 tickLine={{ stroke: 'none' }} 
														/>
                            <YAxis 
															dataKey="transacoes" 
															axisLine={false} 
															tick={{ fill: '"#340950"' }} 
															tickLine={{ stroke: 'none' }} 
															// domain={[100, 1200]}
															tickCount={7} 
															interval="preserveStartEnd"
														/>
                            <Line dataKey="transacoes" dot={false} type="monotone"  stroke="#4CC8EC" strokeWidth={3}  />
                            <Tooltip cursor={false} contentStyle={{ border: 'none', background: 'transparent' }} 
                                formatter={ (value: string, name: any) => {
                                    name = null;
                                    return [value, name]
                                }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>

                {data[1]?.status?.length > 0  &&              
                <div className="barContainer">
                    <h2 className="title">STATUS DE TRANSAÇÕES</h2>
                    <ResponsiveContainer width='99%'>
                        <BarChart
													data={data[1]?.status}
													margin={{ top: 40, bottom: 20, left: 20, right: 40 }}
												>
                            <XAxis
                            	dataKey="status_description" 
                            	axisLine={false} 
                              padding={{ left: 30 }}
                              tick={{ fill: '"#340950"' }} 
                              tickLine={{ stroke: 'none' }} 
                            />
                            <YAxis
															dataKey="transacoes"
															axisLine={false} 
															// domain={[100, 500]}
															tick={{ fill: '"#340950"' }} 
															tickLine={{ stroke: 'none' }} 
															interval="preserveStartEnd"
														/>
                            <Tooltip cursor={false} contentStyle={{ border: 'none', background: 'transparent' }} 
                                formatter={ (value: string, name: any) => {
                                    name = null;
                                    return [value, name]
                                }}
                            />
                            <Bar dataKey="transacoes" style={{width: 22, }} radius={30} barSize={22} >
                            {
                            	data[3]?.grafico_2.map((entry, index) => (
																	<Cell key={`cell-${index}`}
																		fill={
																			index === 0 ? '#F58220'
																			: index === 1 ? '#5CEE79' 
																					: '#07B7E6'
																		}                                        
																	/>
															))
                            }
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                }
            </div>

            <Table>
                <div className="tabelaContainer">
                    <table cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                              <td>Data de criação</td>
                              <td>Vencimento</td>
                              {/* <td>Nome</td> */}
                              <td>CPF</td>
                              <td>Valor</td>
                              <td>Status</td>
                              {/* <td>NSU</td> */}
                              {/* <td>Email</td> */}
                              {/* <td>Unidade</td> */}
                            </tr>
                        </thead>

                        <tbody>                            
                          {dataTransacoes.slice(0, 10).map( (obj, idx) => (
                            <tr key={idx}>
                              <td style={{width: '1%'}}>{parseData(obj.created_at, true)}</td>
                              <td style={{width: '1%'}}>{parseData(obj.expiration_date, false, true)}</td>
                              {/* <td style={{width: '1%'}}>{obj.name}</td> */}
                              <td style={{width: '1%'}}>{obj.cpf}</td>
                              <td style={{width: '1%'}}>{parseValor(obj.value)}</td>
                              <td style={{width: '1%'}}>{parseStatus(obj.status)}</td>
                              {/* <td style={{width: '1%'}}>{obj.nsu_transaction}</td> */}
                              {/* <td style={{width: '1%'}}>{obj.email}</td> */}
                              {/* <td style={{width: '1%'}}>{obj.agency_code}</td> */}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                </div>
            </Table>
            <Loading isLoading={loading} />
        </Container>
    )
}
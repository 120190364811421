import styled from "styled-components"

export const Container = styled.section`
    height: 100vh;
    width: 100%;

    .content{
        min-height : 100vh ;
        width: 100%;
        
        background-image: linear-gradient(290deg ,#F15A22 0%,  #F58220 75%);
        display:flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;
        padding:80px 20px 80px 20px;
    }

    img{
        height: 152px;
        margin-bottom: 30px;
    }

    .submitContainer{
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }
    
    .fazerCadastro{
        display:flex ;
        margin-top: 40px;
        align-items: center;
        font-weight: 500;
        color: var(--white);
        a{
            color: var(--amarelo-300);
            padding-left: 0.4em;
            cursor: pointer;
        }
    }

    /* @media only screen and (max-width: 900px) {
        .box1{
            display:none ;
        }
        .box2{
            img{
                display: block;
            }
            width:100%;
        }
    } */
`

export const Esqueceu = styled.span`
    display: flex;
    background: transparent;
    border: none;
    color: var(--amarelo-300);
    justify-content: flex-end;
    margin-bottom: 5px;

    cursor: pointer;

    @media only screen and (max-width: 900px) {
    font-size: 0.8em;
    }
`;
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;    
  }
  
  
  :root{
   --white: #ffffff;
   --black: #000;
   --background: #ffffff;
   --table-hover:#f9f8fa;
   --thead-color: rgba(52,9,80, 55%);

   --roxo-700: #340950;

   --input-background: rgba(52,9,80,5%);
   --input-text-color: rgba(52,9,80,50%);
   --input-label: rgba(76,75,151, 55%);


   --azul-300:#07B7E6;
   --azul-250: #4CC8EC;
   
   --laranja-500:#F58220;
   --laranja-600:#F15A22;

   --amarelo-300:#F8CF5A;
   
    -verde-300:#5CEE79;

   --background-dashboard: #F4F4FD;

   --tbody-color: #4C4B97;
  }
  
  @media (max-width: 1800px) {
    html{
        font-size: 75%;
    }
  }

  @media (max-width: 1300px) {
    html{
        font-size: 60%;
    }
  }
  
  /* @media (max-width: 720px) {
    html{
        font-size: 87.5%;
    }
  } */
  
  body{
    background-color: var(--backgorund);
    color: var(--roxo-700);
  }
  
  body, input, textarea, select, button {
    font: 400 1rem 'Montserrat', sans-serif;
  }
  
  button{
    cursor: pointer;
  }
  
  a{
    color: inherit;
    text-decoration: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
  }

`;

import styled, { css } from 'styled-components';

interface ContainerProps {
  active: boolean,
  sair: boolean,
  titulo: string,
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    gap: 25px;
    height: 58px;
    padding: 0 26px;
    cursor: pointer;
    margin-bottom: 9px;
    position: relative;
    
    
    ${({ active }) => active ? css`
        background-color: var(--laranja-600);
    `:
    css`
        background-color: transparent;
    `
  };
    border-top-left-radius: 29px;
    border-bottom-left-radius: 29px;
  &:hover{
    transition: 0.2s;
    background-color: var(--laranja-600);
  }
  span{
    font-size:1.063rem ;
    font-weight: 500;
    color: var(--white);
    position: absolute;
    left: 79px;
  }
  ${({ sair }) => sair ? css`
      margin-top: auto;
      margin-bottom: 110px;
      
    `:
    css`
        
    `
  };

  .tooltip{
    visibility: hidden;
    background-color: black;
    color: #fff;
    font-size: 1rem;
    text-align: center;
    padding: 10px;
    border-radius: 8px;
    position: absolute;
    top: -18px;
    z-index: 1;
  }

  &:hover .tooltip {
    ${props => {
      // if(props.titulo === 'Home'){
      //   return('visibility: visible;')
      // }
      if(props.titulo === 'Financeiro'){
        return('visibility: visible;')
      }
      if(props.titulo === 'Ajuda'){
        return('visibility: visible;')
      }
    }}
  }
  
`;
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import useAuth from "../../../hooks/useAuth";
import { blogPostProps } from "../../../services/models";
import { getBlogPosts } from "../../../services/service";
import { Container, PostContainer } from "./styles";

import PageNavigation from "../../../components/PageNavigation";
import { Loading } from "../../../components/Loading/Loading";

const Post = ({posts}: any) => {
  const navigate = useNavigate();

  return(
    posts.map((obj: blogPostProps, index: number) => (
      <PostContainer key={index}>
        <div className="leftContainer" >
          {obj.banner ?
            <img src={obj.banner} alt={''}
            onClick={() => navigate(`/blog/${obj.slug}`)}
            />
            :
            <div className="leftContainer" />
          }
        </div>

        <div className="rightContainer" >
          <h3 className="data" >{obj.criado_em}</h3>

          <div>
            <h3 className="categoria" >{obj.categoria.nome}</h3>
            <h2 className="titulo" onClick={() => navigate(`/blog/${obj.slug}`)}>{obj.titulo}</h2>
          </div>
        </div>
      </PostContainer>
    ))
  )
};

export function BlogHome(){
  const {token} = useAuth();
  const location = useLocation();
  const [posts, setPosts] = useState<blogPostProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [pagina, setPagina] = useState({current: 1, last: 1});
  const [totalBusca, setTotalBusca] = useState(0);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const res = await getBlogPosts(token, pagina.current, location.search.replace('?', '&')) as any;
        setPosts(res.data.data);
        setPagina(prev => ({...prev, last: res.data.meta.last_page}));
        setLoading(false);

        if(location.search.includes('titulo')){
          setTotalBusca(res.data.meta.total);
        }

      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    })()
  },[pagina.current, location]);

  return (
    <Container>

      {
        !loading &&
        location.search.includes('titulo')  &&
        <p className="busca">Foram encontrados {totalBusca} resultados.</p>
      }
      
      <div className="searchPostContainer" >
        <Post posts={posts} />
      </div>

      <PageNavigation pagina={pagina} setPagina={setPagina}/>

      <Loading isLoading={loading} />
    </Container>
  )
}
import { Input } from "./styles";

interface InputTextProps extends React.InputHTMLAttributes<HTMLInputElement> { }

export function InputText({ ...rest }: InputTextProps) {
    return (
        <Input
            {...rest}
        />
    )
}
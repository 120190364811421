import styled, { keyframes } from "styled-components";

interface showProps {
    show: boolean,
}

const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(.25);
    opacity: 0;
  }
`;

export const Container = styled.section`
    
    .headerTabela{
    
    padding: 36px 64px 0px 64px;

        .nomePagina{
            font-size: 2rem;
        }

    }

    main{
        padding:  24px 64px;
        margin: 0 auto;

        .pesquisar{
            display: flex;
            flex-direction: row;
            justify-content: space-between;             
            
        }

        .tabelaContainer{
            width: 100%;
            background-color: var(--background);
            padding: 30px;
            border-radius: 30px;

            table{
                width: 100%;
                border: none;
                margin-top: 30px;
                thead{
                    border: none;
                    tr{
                        border: none;
                        td{
                            border: none;
                            font-size: 1rem;
                            font-weight: bold;
                            padding-bottom: 26px;
                            color: var(--thead-color);
                        }

                    }
                }
                tbody{
                    border: none;
                    tr{
                        border: none;
                        td{
                            border: none;
                            padding:18px 0;
                            color: var(--tbody-color);
                        }
                        .primeiro{

                        }

                        .ultimo{      
                            display: flex;
                            justify-content: flex-end;
                            /* cursor: pointer; */
                        }

                        &:hover{
                            transition: 0.2s;
                            background-color: var(--table-hover);
                        }
                    }
                }
            }

            
        }

        @media only screen and (max-width: 900px) {
            padding:  24px 0 0 0;
        }
    }
`

export const MiniModal = styled.div<showProps>`
    width: 100%;
    position: relative;

    div{
        position: absolute;
        bottom: -100%;
        right: 0;
        /* width: 100%; */
        /* height: 360px; */
        padding: 10px 30px;
        outline: none;
        background-color: #F0EFF9;
        color: var(--roxo-700);
        font-size: 1rem;
        border-radius: 18px;
        border: 1px solid var(--roxo-700);
        font-weight: normal;
        border: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        animation: ${props => props.show ? fadeIn : fadeOut} 0.2s linear;
        transition: visibility 0.2s linear;
        visibility: ${props => props.show ? 'visible' : 'hidden' };

        .optionsButton{
            border: none;
            background-color: transparent;

            margin: 8px 0px;
        }
    }

`
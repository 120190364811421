import styled, { css, keyframes } from 'styled-components';

interface ButtonProps {
  animate: boolean,
}

const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(.25);
    opacity: 0;
  }
`;

export const Container = styled.div`
  position: relative;
`

export const MesButton = styled.button<ButtonProps>`
  min-width: 150px;
  height: 28px;
  background-color: var(--input-background);
  outline: none;
  border: none;
  border-radius: 25px;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  
  opacity: 100;
  visibility: ${props => props.animate ? 'hidden' : 'visible'};
  animation: ${props => props.animate ? fadeOut : fadeIn} ${props => props.animate ? '0.2s' : '0.3s'} linear;
  transition: visibility  ${props => props.animate ? '0.2s' : '0.3s'} linear;
  
  .icon {
    margin-left: 15px;

  }
`
export const MesDropdown = styled.div`
  min-width: 150px;
  background-color: #F0EFF9;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 25px;
  position: absolute;
  top: 0;
  cursor: pointer;
  z-index: 100;
`
export const MesDropdownButton = styled.div`
  padding: 10px;
  text-align: center;

  &:hover {
    background-color: var(--white);
  }
`
import styled from 'styled-components';

interface TagsContainerProps {
  centered?: Boolean;
}

export const Container = styled.main`
  margin: 30px 0px;
  max-width: 60%;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;

  }
  
  h2{
    margin-bottom: 15px;
    font-weight: 500;

  }
  
  span, p{
    font-size: 1.33rem;
    margin-top: 10px;

  }

  img{
    width: 60vw;
    height: auto;
    object-fit: contain;

  }

  .loading{
        position: relative;
        width: 150px;
        height: 150px;
        animation: rotate 2s linear infinite;

        @keyframes rotate {
                0%{
                    transform: rotate(0deg);
                }
                100%{
                    transform: rotate(360deg);
                } 
            }
        circle{
            width: 100%;
            height: 100%;
            fill: none;
            stroke-width: 10;
            stroke: var(--roxo-700);
            stroke-linecap: round;
            transform: translate(5px ,5px);
            stroke-dasharray: 440;
            stroke-dashoffset: 440;
            animation: animate 4s linear infinite;

            @keyframes animate {
                0%,100%{
                    stroke-dashoffset: 440;
                }
                50%{
                    stroke-dashoffset: 0;
                }
                50.1%{
                    stroke-dashoffset: 880;
                }
            }
        }
    }

    .conteudo{      
      h2, h3, h4, p, span, a, img {
        margin: 0;
        padding: 0;
      }

      .ql-align-center{
        text-align: center;
        padding: 0px 4em;
      }

      /* & :not(.ql-align-center)::first-letter { */
      /* p:first-of-type::first-letter {
        font-size: 5rem;
      } */

    }

    @media only screen and (max-width: 900px) {
      max-width: 90%;

      img{
        width: 90vw;
      }
    }

`

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  flex-wrap: wrap;
  
  p {
    padding: 10px 20px;
    margin-right: 10px;
    border-radius: 30px;
    color: var(--white);
    background-color: var(--azul-300);

    &:hover{
      cursor: pointer;
    }

  }

`

export const CopiarLinkContainer = styled.div`
  margin-top: 30px;
  position: relative;

  .icon-link-container{
    position: absolute;
    top: 9px;
    left: 12px;
    cursor: pointer;
  }
  .icon-link{
    color: var(--white);
  }

  button{
    border: 0;
    background-image: linear-gradient(90deg, var(--laranja-500), var(--laranja-600));
    padding: 15px 40px;
    border-radius: 25px;
    color: var(--white);
    font-weight: 500;

    &:hover{
      background-image: linear-gradient(180deg, var(--laranja-500), var(--laranja-600));

    }
  }
`

export const EditarPostHeader = styled.div`
  width: 100%;
  height: 40px;
  background-color: var(--roxo-700);
  position: absolute;
  top: 100px;
  left: 0;
  display: flex;
  justify-content: space-around;

  div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    p{
      font-size: 1rem;
      color: var(--white);
      margin: 0;
    }

    .editarIcon{
      color: var(--white);
      margin-left: 10px;
    }
  }

`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 22px;
    width: 60vw;

    .inputsContainer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 13px;
    }

    .inputWrapper{
        display: flex;
        flex-direction: column;
        .inputLabel{
            font-size: 400;
            font-size: 0.875rem;
            color: var(--input-label);
            text-transform: uppercase;
            margin-left: 13px;
            margin-bottom: 12px;
        }
        .inputLabelError{           
            font-size: 1rem;
            color: var(--laranja-600);
            margin-left: 13px;
        }
        .inputStyle{
            border: none;
            font-weight: bold;
            height: 49px;
            border-radius: 40px;
            background-color: var(--background-dashboard);
            
        }
        .chooseContainer{
            min-height: 49px;
            padding-left: 20px;
            padding-top: 10px;
            border: none;
            border-radius: 40px;
            background-color: var(--background-dashboard);
            display: flex;
            align-items: center;

            p{
                font-weight: 900;
                margin-top: -10px;
            }
        }
    }
    
    .w50{
        width: 50%;
    }
    
    .w100{
        width: 100%;
    }

    @media only screen and (max-width: 900px) {
        gap: 0px;
        margin: 0px;
        padding: 0px;
        width: 90vw;

        label{
            margin-top: 10px;
            
        }

        .inputsContainer{
            display: block;
            .inputWrapper{
                .inputLabel{
                    margin-bottom: 4px;
                }
            }
        }

        .w50 {
            width: 100%;
        }

        .btnContainer{
            align-self: center;
            margin-top: 20px;
        }
    }

`

export const TagsContainer = styled.div<TagsContainerProps>`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    .tags{
        padding: ${props => props.centered ? '10px': '10px 10px 10px 20px'};
        margin-right: 10px;
        margin-top: 10px;
        border-radius: 30px;
        color: var(--white);
        background-color: var(--azul-300);
        display: flex;
        align-items: center;
        
        .deleteTagIcon{
            margin-left: 10px;
            cursor: pointer;
        }

    }

`
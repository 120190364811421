import React from "react";
import { IoCaretBack, IoCaretForward } from "react-icons/io5";

import { Container } from "./styles";

interface navigationProps{
  pagina: {
    current: number,
    last: number
  }, 
  setPagina: React.Dispatch<React.SetStateAction<{
    current: number,
    last: number
  }>>
}

export default function PageNavigation({pagina, setPagina}: navigationProps){
  return(
    <Container>
    {pagina.last > 1 ?
      <div className="paginationContainer">                    
        <IoCaretBack
          size={30}
          onClick={() => setPagina(prev => ({...prev, current: prev.current - 1}) )}
          style={pagina.current > 1 ? {visibility: 'visible'} : {visibility: 'hidden'}}
          className={'icon'}
        />                 
        <span className="pageNumber" >{pagina.current}</span>
        <IoCaretForward
          size={30}
          onClick={() => setPagina(prev => ({...prev, current: prev.current + 1}) )}
          style={pagina.current < pagina.last ? {visibility: 'visible'} : {visibility: 'hidden'}}
          className={'icon'}
        />
      </div>
    :
      null
    }
    </Container>
  )
}
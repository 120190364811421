import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { Container } from "./styles";


export function OperacaoRouter() {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    // useEffect(() => {
    //     if (pathname == '/dashboard/teste' || pathname == "/dashboard/teste") {
    //         navigate("/dashboard/teste");
    //     }
    // }, [pathname]);

    return ( 
        <Container>
            <header className="header">
                <h1 className="nomePagina">OPERAÇÃO</h1>
            </header>
            <div className="content">
                <Outlet />
            </div>
        </Container>

    )
}
import styled from "styled-components";

export const Container = styled.div`

    display: flex;
    flex-direction:column ;

   
    form{
        margin-top: 50px;
        width: 100%;
        max-width: 500px;
        padding-inline: 10px;
        margin-inline: auto;
        display: grid;
        gap: 10px;

        .inputLabel{
            padding-top: 19px;
            padding-bottom: 13px;
            padding-left: 10px;
            display:inline-block;
            font-size: 1rem;
            font-weight: 600;
        }

        button{
            grid-column: 2 / 3;
            margin: 80px auto 30px;
            text-align:center; 
        }

        .checkboxContainer{
            [type="checkbox"] {
                vertical-align:middle;
                margin-right: 1%;
            }
            [type="checkbox"]:checked {
                accent-color: var(--roxo-700);
            }
            label {
                width: 120px;
            }
                display: flex;
                justify-content: space-evenly;
        }
    }
`
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { MdEdit } from "react-icons/md";
import { TiDelete } from "react-icons/ti";

import { deleteBlogCategoria, deleteBlogTag, getBlogCategorias, getBlogTags, postBlogNovaCategoria, postBlogNovaTag, postBlogNovoPost, putBlogEditarCategoria } from "../../../services/service";
import { newBlogPostProps } from "../../../services/models";
import useAuth from "../../../hooks/useAuth";
import usePreviewPost from "../../../hooks/usePreviewPost";
import { Container, Form, TagsContainer } from "./styles";

import { InputText } from "../../../components/form/InputText";
import { InputTextArea } from "../../../components/form/InputTextArea";
import { SubmitButton } from "../../../components/form/SubmitButton";
import { Loading } from "../../../components/Loading/Loading";
import { OutlinedButton } from "../../../components/OutlinedButton";
import PageNavigation from "../../../components/PageNavigation";
import { FullScreenModal } from "../../../components/FullScreenModal";
import { QuillTextEditor } from "../../../components/QuillTextEditor";

const CriarNoticia = ({token, setLoading}: any) => {
  const navigate = useNavigate();
  const {createPreview, deletePreview} = usePreviewPost();
  const [newPost, setNewPost] = useState({} as newBlogPostProps);
  const [newPostErrors, setNewPostErrors] = useState({} as any);
  const [tags, setTags] = useState<{id: string, tag: string}[]>([]);
  const [categoria, setCategoria] = useState({} as {id: string, nome: string, descricao: string});
  const [showSelecionarTagsModal, setShowSelecionarTagsModal] = useState(false);
  const [showSelecionarCategoriaModal, setShowSelecionarCategoriaModal] = useState(false);

  const handleCriarNoticia = async (e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    setNewPostErrors({});
    setLoading(true);
    try {
      const res = await postBlogNovoPost(token, newPost);
      console.log(res);
      if(res.status === 201){
        toast.success('Notícia criada com sucesso');
        deletePreview();
        setLoading(false);
        navigate(0);
        // setNewPost({
        //   titulo: '',
        //   descricao: '',
        //   conteudo: '',
        //   categoria_id: '',
        //   tags: [{id: ''}],
        //   banner: ''
        // });
      }
    } catch (err) {
      console.log(err);
      //@ts-ignore
      console.log(err.response.data.errors);
      toast.error('Não foi possível criar essa Notícia');
      //@ts-ignore
      setNewPostErrors(err.response.data.errors);
      setLoading(false);
    }
  }

  const SelecionarCategoriaModal = () => {
    const [categorias, setCategorias] = useState<{nome: string, descricao: string, id: string}[]>([]);
    const [pagina, setPagina] = useState({current: 1, last: 1});

    const handleLoadCategorias = async () => {
      // setLoading(true);
      try {
        const res = await getBlogCategorias(token, pagina.current) as any;
        setCategorias(res.data.data);
        setPagina(prev => ({...prev, last: res.data.last_page}));
        // setLoading(false);

      } catch (err) {
        console.log(err);
        // setLoading(false);
      } 
    }

    useEffect(() => {
      handleLoadCategorias();
    },[pagina.current])

    return (
      <FullScreenModal 
        show={showSelecionarCategoriaModal}
        setShow={setShowSelecionarCategoriaModal}
      >
        <h2>Selecione a categoria</h2>
        {categorias.length < 1
        ?
          <div style={{height: '200px', width: '200px', display: "flex", justifyContent: "center"}}>
            <svg className="loading">
              <circle cx="70" cy="70" r="70"></circle>
            </svg>
          </div>
        :
          <TagsContainer centered >
          {categorias.map((obj, idx) => (
            <div key={idx} className="tags"
              style={{cursor: "pointer"}}
              onClick={() => {
                setShowSelecionarCategoriaModal(false);
                setCategoria(obj);
                setNewPost(prev => ({...prev, categoria_id: obj.id}))
              }}
            >
              {obj.nome}
            </div> 
          ))}
        </TagsContainer>
        }
        <PageNavigation pagina={pagina} setPagina={setPagina} />
      </FullScreenModal>
    )
  }
  
  const SelecionarTagsModal = () => {
    const [allTags, setAllTags] = useState<{tag: string, id: string}[]>([]);
    const [choosenTags, setChoosenTags] = useState<{tag: string, id: string}[]>(tags);
    const [pagina, setPagina] = useState({current: 1, last: 1});

    const handleLoadCategorias = async () => {
      // setLoading(true);
      try {
        const res = await getBlogTags(token, pagina.current) as any;
        setAllTags(res.data.data);
        setPagina(prev => ({...prev, last: res.data.last_page}));
        // setLoading(false);

      } catch (err) {
        console.log(err);
        // setLoading(false);
      } 
    }
   
    const handleAddTag = (obj: {tag: string, id: string}) => {
      if(choosenTags.some(item => item.id === obj.id)) return;
      setChoosenTags(prev => {
        let arr = prev.slice();
        arr.push(obj);
        return arr;
      })
    }

    const handleRemoveTag = (obj: {tag: string, id: string}) => {
      setChoosenTags(prev => {
        let arr = prev.slice();
        let arr2 = arr.filter(item => item.id !== obj.id);
        return arr2;
      })
    }

    const handleAdionarTags = (e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
      e?.preventDefault();      
      const tagsId = choosenTags.map(obj => ({id: obj.id}))

      setShowSelecionarTagsModal(false);
      setTags(choosenTags);
      setNewPost(prev => ({...prev, tags: tagsId}));

    }

    useEffect(() => {
      handleLoadCategorias();
    },[pagina.current])

    return (
      <FullScreenModal 
        show={showSelecionarTagsModal}
        setShow={setShowSelecionarTagsModal}
      >
        <h2>Tags selecionadas</h2>
        <TagsContainer centered >
          {choosenTags.map((obj, idx) => (
            <div key={idx} className="tags"
              style={{cursor: "pointer"}}
              onClick={() => handleRemoveTag(obj)}
            >
              {obj.tag}
            </div> 
          ))}
        </TagsContainer>
        <h2>Selecione as Tags</h2>
        {allTags.length < 1
        ?
          <div style={{height: '200px', width: '200px', display: "flex", justifyContent: "center"}}>
            <svg className="loading">
              <circle cx="70" cy="70" r="70"></circle>
            </svg>
          </div>
        :
          <TagsContainer centered >
          {allTags.map((obj, idx) => (
            <div key={idx} className="tags"
              style={{cursor: "pointer"}}
              onClick={() => handleAddTag(obj)}
            >
              {obj.tag}
            </div> 
          ))}
        </TagsContainer>
        }
        <PageNavigation pagina={pagina} setPagina={setPagina} />
        <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
        <OutlinedButton
          show={showSelecionarTagsModal}
          onClick={handleAdionarTags}
          >
        ADICIONAR TAGS
        </OutlinedButton>
        </div>
      </FullScreenModal>
    )
  }

  const handlePreVisualizar = () =>{
    createPreview({
      titulo: newPost.titulo,
      descricao: newPost.descricao,
      //@ts-ignore
      banner: newPost.banner ? URL.createObjectURL(newPost.banner!) : '',
      tags: tags,
      categoria_id: newPost.categoria_id,
      conteudo: newPost.conteudo,
    });
    window.open('/blog/pre-visualizar-post', '_blank', 'noopener noreferrer');
  }

  return (
      <Form onSubmit={handleCriarNoticia}>
            <div className="inputsContainer">
                <div className="inputWrapper w100">
                    <label className="inputLabel">TÍTULO</label>
                    <InputText
                        onChange={(e) => {
                          setNewPost( prev => ({...prev, titulo: e.target.value}))
                        }}
                        value={newPost.titulo}
                        className="inputStyle"
                        placeholder={""}
                    />
                    {newPostErrors.titulo ? <div className="inputLabelError">{newPostErrors.titulo}</div> : null}
                </div>
              </div>
              <div className="inputsContainer">
              <div className="inputWrapper w100">
                  <label className="inputLabel">Descrição</label>
                  <InputTextArea
                      onChange={(e) => {
                        setNewPost( prev => ({...prev, descricao: e.target.value}))
                      }}                          
                      value={newPost.descricao}
                      className="inputStyle"
                      placeholder={""}
                      style={{minHeight: '60px', minWidth: '50%', maxWidth: '100%'}}
                  />
                  {newPostErrors.descricao ? <div className="inputLabelError">{newPostErrors.descricao}</div> : null}
              </div>
          </div>

          <div className="inputsContainer">
                <div className="inputWrapper w100">
                    <label className="inputLabel">Capa da notícia</label>
                    <InputText
                        // value={newPost.tags}
                        onChange={(e) => {
                            setNewPost( prev => ({
                              ...prev,
                              banner: e.target.files![0]
                            }))
                        }}
                        className="inputStyle"
                        placeholder={""}
                        type='file'
                    />
                    {newPostErrors.banner ? <div className="inputLabelError ">{newPostErrors.banner}</div> : null}
                    {newPost.banner ?
                      <img src={URL.createObjectURL(newPost.banner as any)} alt="capa" />
                    :null }
                </div>
            </div>

            <div className="inputsContainer">
                <div className="inputWrapper w50">
                    <label className="inputLabel">TAGS</label>
                    <div className="chooseContainer" style={{cursor: 'pointer'}} 
                      onClick={() => setShowSelecionarTagsModal(true)}
                    >
                      <TagsContainer centered >
                        {tags.map((obj, idx) => (
                          <div key={idx} className="tags" >
                            {obj.tag}
                          </div> 
                        ))}
                      </TagsContainer>
                    </div>
                    {newPostErrors.tags ? <div className="inputLabelError">{newPostErrors.tags}</div> : null}
                </div>
                <div className="inputWrapper w50">
                    <label className="inputLabel">CATEGORIA</label>
                    <div className="chooseContainer" style={{cursor: 'pointer'}} 
                      onClick={() => setShowSelecionarCategoriaModal(true)}
                    >
                      <p>{categoria.nome}</p>
                    </div>
                    {newPostErrors.categoria_id ? <div className="inputLabelError">{newPostErrors.categoria_id}</div> : null}
                </div>
            </div>

            <div className="inputsContainer" >
              <div className="inputWrapper w100">
                  <label className="inputLabel">Postagem</label>
                  {/* <InputTextArea
                      value={newPost.conteudo}
                      onChange={(e) => {
                        setNewPost( prev => ({...prev, conteudo: e.target.value}))
                      }}                          
                      className="inputStyle"
                      placeholder={""}
                      style={{minHeight: '100px', minWidth: '50%', maxWidth: '100%'}}
                  /> */}
                  <QuillTextEditor
                    value={newPost.conteudo}
                    setValue={(arg: string) => setNewPost(prev => ({...prev, conteudo: arg}))}
                  />
                  {newPostErrors.conteudo ? <div className="inputLabelError">{newPostErrors.conteudo}</div> : null}
              </div>
            </div>            

            <div className="btnContainer">
                <SubmitButton type="button" onClick={handleCriarNoticia} color="laranja" text="SALVAR" /> 
                <button
                  type='button'
                  className="secondButton"
                  onClick={handlePreVisualizar}
                >
                <p>PRÉ-VISUALIZAR NOTÍCIA</p>
              </button>              
          </div>

            {showSelecionarCategoriaModal ? <SelecionarCategoriaModal /> : null}
            {showSelecionarTagsModal ? <SelecionarTagsModal /> : null}
        </Form>
  )
}

const CriarCategoria = ({token, setLoading}: any) => {
    const [categorias, setCategorias] = useState<{nome: string, descricao: string, id: string}[]>([]);
    const [pagina, setPagina] = useState({current: 1, last: 1});
    const [novaCategoria, setNovaCategoria] = useState({nome: '', descricao: ''});
    const [editarCategoria, setEditarCategoria] = useState({nome: '', descricao: '', id: ''});
    const [showModalDeletarCategoria, setShowModalDeletarCategoria] = useState(false);
    const [showModalEditarCategoria, setShowModalEditarCategoria] = useState(false);

    const handleLoadCategorias = async () => {
      setLoading(true);
      try {
        const res = await getBlogCategorias(token, pagina.current) as any;
        setCategorias(res.data.data);
        setPagina(prev => ({...prev, last: res.data.last_page}));
        setLoading(false);        

      } catch (err) {
        console.log(err);
        setLoading(false);
      } 
    }
    
    const handleCriarCategoria = async (e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
      e?.preventDefault();
      setNovaCategoria({nome: '', descricao: ''});
      setLoading(true);
      try {
        const res = await postBlogNovaCategoria(token, novaCategoria.nome, novaCategoria.descricao);
        console.log(res);
        if(res.status === 201){
          handleLoadCategorias();
        }
      } catch (err) {
        console.log(err);
        toast.error('Não foi possível criar essa Categoria')
        setLoading(false);
      }
    }

    const handleEditarCategoria = async (
      {id, nome, descricao}: {id: string, nome: string, descricao: string},
      e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>,
    ) => {
      e?.preventDefault();
      setEditarCategoria({nome: '', descricao: '', id: ''});
      setShowModalEditarCategoria(false);
      setLoading(true);      
      try {
        const res = await putBlogEditarCategoria(
          token,
          parseInt(editarCategoria.id),
          editarCategoria.nome,
          editarCategoria.descricao
        );
        console.log(res);
        if(res.status === 200){
          handleLoadCategorias();
        }
      } catch (err) {
        console.log(err);
        toast.error('Não foi possível editar essa Categoria')
        setLoading(false);
      }
    }

    const handleDeletarCategoria = async (id: number) => {
      setShowModalDeletarCategoria(false);
      setLoading(true);
      try {
        const res = await deleteBlogCategoria(token, id);
        console.log(res);
        if(res.status === 200){
          handleLoadCategorias();
        }
      } catch (err) {
        console.log(err);
        toast.error('Ocorreu um erro. Não foi possível deletar essa Categoria')
        setLoading(false);
      }
    }

    useEffect(() => {
      handleLoadCategorias();
    },[pagina.current]);

    return (
        <>
        <Form onSubmit={handleCriarCategoria} >
            <div className="inputsContainer">
                <div className="inputWrapper w100">
                    <label className="inputLabel">NOME</label>
                    <InputText
                        onChange={(e) => {
                          setNovaCategoria(prev => ({...prev, nome: e.target.value}))
                        }}                          
                        value={novaCategoria.nome}
                        className="inputStyle"
                        placeholder={""}
                    />
                </div>
            </div>
            <div className="inputsContainer">
                <div className="inputWrapper w100">
                    <label className="inputLabel">DESCRIÇÃO</label>
                    <InputTextArea
                        onChange={(e) => {
                          setNovaCategoria(prev => ({...prev, descricao: e.target.value}))
                        }}                          
                        value={novaCategoria.descricao}
                        className="inputStyle"
                        placeholder={""}
                        style={{minHeight: '100px', minWidth: '50%', maxWidth: '100%'}}
                    />
                </div>
            </div>
            <div className="btnContainer">
                <SubmitButton type="button" onClick={handleCriarCategoria} color="laranja" text="SALVAR" />
            </div>
          </Form>
          
          <div style={{marginTop: '30px'}} />
          <h2 className="nomeSessao">EDITAR CATEGORIAS</h2>

          <TagsContainer>
            {categorias.map((obj, idx) => (
              <div key={idx} className="tags" >
                {obj.nome}
                <MdEdit
                  size={24}
                  className="deleteTagIcon"
                  onClick={() => {setShowModalEditarCategoria(true); setEditarCategoria({...obj});}}
                />
                <TiDelete
                  size={24}
                  className="deleteTagIcon"
                  onClick={() => {setShowModalDeletarCategoria(true); setEditarCategoria({...obj});}}
                />
              </div> 
            ))}
          </TagsContainer>
          <PageNavigation pagina={pagina} setPagina={setPagina} />
            <FullScreenModal show={showModalDeletarCategoria} setShow={setShowModalDeletarCategoria}>
                <h2>Deseja deletar a categoria {editarCategoria.nome}?</h2>
                <h3 style={{marginTop: '10px'}} >Descrição:</h3>
                <h4 style={{display: 'flex', flexWrap: 'wrap', maxWidth: '40vw'}}>{editarCategoria.descricao}</h4>
                <h4 style={{marginTop: '10px', marginBottom: '25px'}}>Essa ação é irreversível</h4>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <OutlinedButton
                    show={showModalDeletarCategoria}
                    onClick={() => handleDeletarCategoria(parseInt(editarCategoria.id))}             
                    >
                    DELETAR CATEGORIA
                  </OutlinedButton>
                </div>
            </FullScreenModal>
            <FullScreenModal show={showModalEditarCategoria} setShow={setShowModalEditarCategoria}>
                <h2>Deseja editar a categoria?</h2>

                <Form onSubmit={(e) => handleEditarCategoria(editarCategoria, e)} >
                  <div className="inputsContainer">
                      <div className="inputWrapper w100">
                          <label className="inputLabel">NOME</label>
                          <InputText
                              onChange={(e) => {
                                setEditarCategoria(prev => ({...prev, nome: e.target.value}))
                              }}                          
                              value={editarCategoria.nome}
                              className="inputStyle"
                              placeholder={""}
                          />
                      </div>
                  </div>
                  <div className="inputsContainer">
                      <div className="inputWrapper w100">
                          <label className="inputLabel">DESCRIÇÃO</label>
                          <InputTextArea
                              onChange={(e) => {
                                setEditarCategoria(prev => ({...prev, descricao: e.target.value}))
                              }}                          
                              value={editarCategoria.descricao}
                              className="inputStyle"
                              placeholder={""}
                              style={{minHeight: '20vh', maxHeight: '50vh', minWidth: '30vw', maxWidth: '45vw'}}
                          />
                      </div>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <OutlinedButton
                      show={showModalEditarCategoria}
                      onClick={(e) => handleEditarCategoria(editarCategoria, e)} 
                      >
                      EDITAR CATEGORIA
                    </OutlinedButton>
                  </div>
                </Form>               
            </FullScreenModal>
          </>

    )
}

const CriarTag = ({token, setLoading}: any) => {
    const [tags, setTags] = useState<{tag: string, id: string}[]>([]);
    const [pagina, setPagina] = useState({current: 1, last: 1});
    const [nome, setNome] = useState('');
    const [deletarTag, setDeletarTag] = useState({tag: '', id: ''});
    const [showModalDeletarTag, setShowModalDeletarTag] = useState(false);

    const handleLoadTags = async () => {
      setLoading(true);
      try {
        const res = await getBlogTags(token, pagina.current) as any;
        setTags(res.data.data);
        setPagina(prev => ({...prev, last: res.data.last_page}));
        setLoading(false);        

      } catch (err) {
        console.log(err);
        setLoading(false);
      } 
    }
    
    const handleCriarTag = async (e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
      e?.preventDefault();
      setNome('');
      setLoading(true);
      try {
        const res = await postBlogNovaTag(token, nome);
        console.log(res);
        if(res.status === 201){
          handleLoadTags();
        }
      } catch (err) {
        console.log(err);
        toast.error('Não foi possível criar essa Tag')
        setLoading(false);
      }
    }

    const handleDeletarTag = async (id: number) => {
      setShowModalDeletarTag(false);
      setLoading(true);
      try {
        const res = await deleteBlogTag(token, id);
        console.log(res);
        if(res.status === 200){
          handleLoadTags();
        }
      } catch (err) {
        console.log(err);
        toast.error('Ocorreu um erro. Não foi possível deletar essa Tag')
        setLoading(false);
      }
    }

    useEffect(() => {
      handleLoadTags();
    },[pagina.current]);

    return (
        <>
        <Form onSubmit={handleCriarTag} >
            <div className="inputsContainer">
                <div className="inputWrapper w100">
                    <label className="inputLabel">NOME</label>
                    <InputText
                        onChange={(e) => {setNome(e.target.value)}}                      value={nome}
                        className="inputStyle"
                        placeholder={""}
                    />
                </div>
            </div>

              <div className="btnContainer">
                  <SubmitButton type="button" onClick={handleCriarTag} color="laranja" text="SALVAR" />
              </div>
          </Form>
          
          <div style={{marginTop: '30px'}} />
          <h2 className="nomeSessao">DELETAR TAGS</h2>

          <TagsContainer>
            {tags.map((obj, idx) => (
              <div key={idx} className="tags" >
                {obj.tag}
                <TiDelete
                  size={24}
                  className="deleteTagIcon"
                  onClick={() => {setShowModalDeletarTag(true); setDeletarTag({...obj});}}
                />
              </div> 
            ))}
          </TagsContainer>
          <PageNavigation pagina={pagina} setPagina={setPagina} />
            <FullScreenModal show={showModalDeletarTag} setShow={setShowModalDeletarTag}>
                <h2>Deseja deletar a tag {deletarTag.tag}?</h2>
                <h4 style={{marginTop: '10px', marginBottom: '25px'}}>Essa ação é irreversível</h4>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <OutlinedButton
                    show={showModalDeletarTag}
                    onClick={() => handleDeletarTag(parseInt(deletarTag.id))}             
                  >
                    DELETAR TAG
                  </OutlinedButton>
                </div>
            </FullScreenModal>
          </>

    )
}

const BuscarNoticias = () => {
  const navigate = useNavigate();
  const [tituloPost, setTituloPost] = useState('');
  
  const handleBuscar = (e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    if(tituloPost === '') return

    navigate(`/blog?filter[titulo]=${tituloPost}`)
    return
  }

  return(
    <Form onSubmit={handleBuscar} >
      <div className="inputsContainer">
        <div className="inputWrapper w100">
          <label className="inputLabel">TÍTULO</label>
          <InputText
            onChange={(e) => {setTituloPost(e.target.value)}}
            value={tituloPost}
            className="inputStyle"
            placeholder={""}
          />
        </div>
      </div>
      <div className="btnContainer">
          <SubmitButton
            type="submit"
            onClick={handleBuscar}
            color="laranja"
            text="BUSCAR"
          />
          <button
            type='button'
            className="secondButton"
            onClick={() => navigate('/blog')}
          >
          <p>VER TODAS AS NOTÍCIAS</p>
        </button>
      </div>
    </Form>
  )
}

export function AcademiaJeitto() {
  const navigate = useNavigate();
  const {token, contaId} = useAuth();
  const [loading, setLoading] = useState(false);
  const [criarNoticia, setCriarNoticia] = useState<"NOTÍCIA" | "CATEGORIA" | "TAG" >('NOTÍCIA');

  useEffect(() => (
    (() => {
      if(contaId !== null){
        navigate("/dashboard");
      }
    })()
  ),[])

  if(contaId !== null){
    return <></>
  }

  return (
    <Container>
      <header className="headerTabela">
          <h1 className="nomePagina">ACADEMIA JEITTO</h1>
      </header>

      <main>
        <div className="container">

            <div className="selectContainer" >
                <h2 className="nomeSessao">CRIAR {criarNoticia}</h2>

                <div className="selectContainerButtons" >
                    <OutlinedButton
                        show={true} 
                        onClick={() => setCriarNoticia(prev => prev === 'TAG' ? "NOTÍCIA" : "TAG" )}
                    >
                        {criarNoticia !== 'TAG' ? 'TAG' : 'VOLTAR'}
                    </OutlinedButton>
                    <div style={{marginLeft: '20px'}} />
                    <OutlinedButton
                        show={true} 
                        onClick={() => setCriarNoticia(prev => prev === 'CATEGORIA' ? "NOTÍCIA" : "CATEGORIA" )}
                    >
                        {criarNoticia !== 'CATEGORIA' ? 'CATEGORIA' : 'VOLTAR'}
                    </OutlinedButton>
                </div>

            </div>

            {criarNoticia === "NOTÍCIA"
                ? <CriarNoticia token={token} setLoading={setLoading} />
                : criarNoticia === "CATEGORIA"
                    ? <CriarCategoria token={token} setLoading={setLoading} />
                    : <CriarTag token={token} setLoading={setLoading} />
            }

        </div>
      </main>
      <main>
        <div className="container">
            <div className="selectContainer" >
                <h2 className="nomeSessao">BUSCAR NOTÍCIAS</h2>
            </div>

            <BuscarNoticias />
        </div>
      </main>

      <Loading isLoading={loading} />
    </Container >
)
}
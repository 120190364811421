import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { IState } from "../../../store";
import { dadosVidaNovaProps, postCriarContaVendedorProps } from "../../../services/models";
import { Container, RadioCard } from "./styles";
import { useCadastro } from "../Cadastro";

import { SubmitButton } from "../../../components/form/SubmitButton";
import { HeaderCadastro } from "../../../components/HeaderCadastro";

export function CadastroEtapa2() {
    const navigate = useNavigate();
    const dadosVidaNova = useSelector<IState, dadosVidaNovaProps>((state) => state.dadosVidaNova);
    const { handleStep, handleStepBack, dadosParceiro, setDadosParceiro } = useCadastro();

    function handleNavigate() {
        if(dadosParceiro.conta.nome === ''){
            toast.warning('Escolha um administrador para continuar.');
            return
        }

        handleStep();
        navigate('/cadastro/cadastroEtapa3');
    }

    const SelectSocio = () => {        
        const CardSocio = ({obj}: any) => {
            return (
                <RadioCard
                    id={obj.nome_socio}
                    onClick={() => {
                        setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                            ...prev,
                            conta: {
                                ...prev.conta,
                                nome: obj.nome_socio
                            }
                        }))
                    }}
                >
                    <input type="radio" checked={obj.nome_socio === dadosParceiro.conta.nome}/>
                    <label className='radioCheck' >
                        <label className='customRadio' />
                        <div className="radioContentWrapper">
                            <h2>Nome: {obj.nome_socio}</h2>
                            <div style={{marginBottom: '10px'}} />
                            <h2>Cargo: {obj.cargo_socio}</h2>
                        </div>
                    </label>
                </RadioCard>
            )
        }
        if(dadosVidaNova.socios.length > 0){
            return(
                <>
                {dadosVidaNova.socios?.map((obj, id) => <CardSocio key={id} obj={obj} />) }
                </>
            )
        } else {
            return (
                <>
                <CardSocio 
                    obj={{
                        nome_socio: dadosVidaNova.razao_social,
                        cargo_socio: 'ÚNICO SÓCIO'
                    }}
                />
                </>
            )
        }
    }

    return (
        <Container>
            <HeaderCadastro callback={handleStepBack}/>
            <h1>Selecionar nome do sócio administrador:</h1>

            <div className="radioWrapper">            
                <SelectSocio />
                <SubmitButton
                    text="Continuar"
                    color='azul'
                    outline
                    onClick={handleNavigate}
                />
            </div>


        </Container>
    )
}
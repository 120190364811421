import styled from "styled-components";

export const Container = styled.main`
    --spacing: 160px;
    @media (max-width: 900px) {
    --spacing: 20px;
  }

  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  .header{
    background-color: var(--laranja-500);
    padding: 10px 0px;
    position: fixed;
    width: 100%;
    z-index: 100;

    .logo{
      width: 76px;
      margin: 0px var(--spacing);
    }
  }

  .hero{
    display: flex;
    width: 100%;
    height: 580px;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;  
    flex-direction: row;
    justify-content: flex-start;
    border: 1px #000;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: var(--laranja-500);
    background-image: none;
    background-size: auto;
    background-repeat: repeat;

    @media only screen and (max-width: 900px) {
      flex-direction: column;

    }

    .heroLeft{
      margin-left: var(--spacing);
      display: flex;
      width: 55%;
      padding-top: 170px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      
      @media only screen and (max-width: 900px) {
        padding-top: 120px;
        width: 100%;
      }

      h2{
        width: auto;
        margin-top: 0;
        margin-bottom: 0;
        color: #fff;
        font-size: 50px;
        line-height: 54px;
        font-weight: 900;

        @media only screen and (max-width: 900px) {
          font-size: 22px;
          line-height: 30px;

        }
      }

      h3{
        width: auto;
        margin-top: 35px;
        margin-bottom: 0;
        color: #fff;
        font-size: 1rem;
        line-height: 24px;
        font-weight: 500;

        @media only screen and (max-width: 900px) {
          margin-top: 20px;
          margin-bottom: 20px;
          margin-right: 50px;
          font-size: 0.875rem;
          line-height: 18px;
        }

      }

    }

    .heroRight{
      margin-right: var(--spacing);      
      display: flex;
      width: 45%;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;

      @media only screen and (max-width: 900px) {
        width: 100%;
        justify-content: center;
        align-items: center;

      }

      .imageHero{
        border: 0;
        max-width: 82%;
        vertical-align: middle;
        display: inline-block;

        @media only screen and (max-width: 900px) {
          border: 0;
          max-width: 90%;
          vertical-align: middle;
          display: inline-block;
          margin-top: 20px;
        }
      }

    }

  }

  .formContainer{
    position: relative;
    top: -80px;
    width: 80%;
    /* min-height: 200px; */
    margin-right: auto;
    margin-left: auto;
    padding: 30px;
    border-radius: 20px;
    background-color: var(--background);
    gap: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    @media only screen and (max-width: 900px) {
      width: 90%;
      top: -70px;

    }

    h2{
      font-size: 24px;
      color: var(--laranja-500);
      margin-top: 0;
      margin-bottom: 20px;
      line-height: 32px;
      font-weight: 900;

      @media only screen and (max-width: 900px) {
        margin-bottom: 10px;
        font-size: 1.125rem;
        line-height: 22px;  
      }

    } 

    form{
      display: grid;
      align-items: center;
      grid-template-columns: repeat(auto-fit, minmax(254px, 1fr));
      grid-gap: 20px;

      input{
        outline: none;
        margin-right: 30px;
        margin-bottom: 0;
        border: 1px #000;
        border-radius: 4px;
        background-color: #f2f2f2;
        font-size: 1rem;
        font-weight: 500;
        height: 38px;
        padding: 8px 12px;
        color: #333;
        width: 100%;

      }

      /* .um-dois{
        grid-column: 1 / 4;
        
      }
      .dois-dois{
        grid-column: 4 / 6;
        
      }
      .um-tres{
        grid-column: 1 / 2;
        
      }
      .dois-tres{
        grid-column: 2 / 4;
        
      }
      .tres-tres{
        grid-column: 4 / 6;
        
      }
      .endereco{
        grid-column: 2 / 6;
      }

      @media only screen and (max-width: 900px) {
        .um-dois, .dois-dois, .um-tres, .dois-tres, .tres-tres, .endereco{
          grid-column: 1 / 6;
          
        }

      } */

      button{
        border: none;
        width: 100%;
        height: 40px;
        border-radius: 25px;
        background-color: var(--laranja-500);
        color: var(--white);
        /* grid-column: 2 / 4; */

        /* @media only screen and (max-width: 900px) {
          grid-column: 1 / 6;
        } */

      }
      
      .valorInput {
        position: relative;
        color: inherit;

        &::placeholder {
          color: inherit;

        }
        
      }
      .valorWarning{
        position: absolute;
        color: #F15A22;
        font-size: 0.85rem;

      }

    }

    .successMessage{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin-top: 80px;

      @media only screen and (max-width: 900px) {
        margin-top: 60px;
      }

      h3{
        font-size: 24px;
        color: var(--laranja-500);
        margin-top: 0;
        margin-bottom: 20px;
        line-height: 32px;
        font-weight: 900;

        @media only screen and (max-width: 900px) {
          margin-bottom: 10px;
          font-size: 1.125rem;
          line-height: 22px;  
        }
      }
    }

  }

  .cardsContainer{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-self: center;
    gap: 30px;
    margin: 40px var(--spacing) 160px;
    
    @media only screen and (max-width: 900px) {
      grid-template-columns: repeat(1, 1fr);
      margin: 0px 50px 80px;

    }

    .card{

      .icon{
        margin-left: 20px;
        margin-bottom: 10px;
      }

      .cardInsideContainer{
        display: flex;
        align-items: flex-start;

        h3{
          margin-right: 20px;
          color: var(--laranja-500);
          font-size: 40px;
          margin-top: -5px;

        }

        span{
          font-weight: 700;
        }

        .rightBar{
          width: 3px;
          height: 50px;
          margin-right: 15px;
          margin-left: 15px;
          background-color: #d2d2d2;
          margin-top: 10px;
          @media only screen and (max-width: 900px) {
            visibility: hidden;

          }
        }

      }
    }
  }

  .aviso{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #333;
    width: 100%;
    padding: 20px var(--spacing);
    color: var(--laranja-500);
    font-size: 1rem;

    .boldText{
      font-weight: 700;
      margin-left: 15px;
    }

    .lightText{
      font-weight: 200;

    }

    .iconMobile{
      display: none;
    }

    @media only screen and (max-width: 900px) {
      font-size: 0.625rem;
      line-height: 14px;
      padding: 0px var(--spacing);

      .icon{
        display: none;
      }

      .iconMobile{
        display: block;
      }

    }

  }

  footer{
    background-color: var(--black);
    width: 100%;
    padding: 50px 0;

    .footerTop{
      margin: 0 var(--spacing);
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;

      .title{
        margin-bottom: 25px;
        color: #828282;
        font-size: 1rem;
        line-height: 18px;
      }

      a{
        display: block;
        margin-bottom: 25px;
        color: var(--white);
        line-height: 18px;
        font-weight: 700;
        text-decoration: none;
      }

      p {
        color: var(--white);
        font-size: 0.75rem;
        margin-bottom: 25px;
        line-height: 20px;
      }

      @media only screen and (max-width: 900px) {
        flex-direction: column;

        .title{
          margin-bottom: 15px;
          
        }

        a{
          font-size: 0.875rem;
          line-height: 16px;
          margin-bottom: 10px;

        }

        p {
          margin-bottom: 15px;
        }

      }

      .jeitto{
        width: 50%;
        margin-right: 50px;

        .socialIcons{
          margin-bottom: 25px;
          
        }

        .googleplayImg{
          cursor: pointer;

        }

        @media only screen and (max-width: 900px) {
          margin-bottom: 20px;

          .socialIcons, .googleplayImg{
            display: none
          }
        }

      }

      .servicos{
        margin-bottom: 40px;

        @media only screen and (max-width: 900px) {
          margin-bottom: 20px;

        } 

      }

      .descubra{
        .googleplayImgMobile{
          display: none;
        }

        @media only screen and (max-width: 900px) {
          
          .googleplayImgMobile{
            margin-top: 15px;
            display: block;
          }

        }
      }

      .links{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;

        @media only screen and (max-width: 900px) {
          grid-template-columns: repeat(1, 1fr);

        }
      }

    }

    .footerBottom{
      margin: 0 var(--spacing);
      border-top: 1px white solid;

      .cnpj{
        display: flex;
        margin-top: 20px;

        img{
          width: 93px
        }

        .dados{
          display: flex;
          flex-direction: column;
          margin-left: 40px;

          span{
            color: var(--white);
            font-size: 0.75rem;
          }

          .socialIconsMobile{
            display: none;
          }

          @media only screen and (max-width: 900px) {
            margin: 10px 0;

            .socialIconsMobile{
              display: block;
            }

          }
        }

        @media only screen and (max-width: 900px) {
          flex-direction: column;
        }

      }

      .termos{
        display: flex;
        margin-top: 20px;

        a, p{
          color: var(--white);
          font-size: 0.875rem;
          font-weight: 400;
        }

        a {
          text-decoration: underline;
        }

        p {
          margin: 0 10px;
        }

        @media only screen and (max-width: 900px) {
          flex-direction: column;
          margin-top: 0px;

          a{
            font-size: 0.75rem;
            line-height: 18px;
          }

          p{
            display: none;
          }
        }


      }

      h4{
        margin-top: 20px;
        color: #828282;
        font-size: 11px;
        line-height: 17px;
      }
    }
  }

`
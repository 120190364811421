import { useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import { postCriarContaVendedorProps } from "../../../services/models";
import { putFinalizarCadastroPipefy } from "../../../services/service";
import { useCadastro } from "../Cadastro";
import { Container } from "./styles";

import { InputText } from "../../../components/form/InputText";
import { SubmitButton } from "../../../components/form/SubmitButton";
import { HeaderCadastro } from "../../../components/HeaderCadastro";
import { CustomInputMask } from "../../../components/form/CustomInputMask";
import { CustomCurrencyInput } from "../../../components/form/CustomCurrencyInput";
import { Loading } from "../../../components/Loading/Loading";

export function CadastroEtapa7() {
    const navigate = useNavigate();
    const { handleStepBack, dadosParceiroID, dadosParceiro, setDadosParceiro } = useCadastro();
    const [loading, setLoading] = useState(false);

    async function handleNavigate(e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) {
        e?.preventDefault()

        try {
            setLoading(true);
            const res = await putFinalizarCadastroPipefy(
                dadosParceiroID,
                dadosParceiro,
            );            
            setLoading(false);
            navigate('/dashboard/analise');                
            
        } catch (err) {
            setLoading(false);
            console.log(err);
            toast.error('Ocorreu um erro ao criar a conta. Verifique todos os campos e tente novamente');            
        }
    }

    // return (
    //     <Container>
    //         <HeaderCadastro />
    //         <h1>Iremos validar o seu pix fazendo um <br />
    //             envio de valor entre R$ 0,01 à R$ 0,20.</h1>
    //         <form onSubmit={handleNavigate} >
    //             <label className="inputLabel">Chave pix</label>
    //             <InputText
    //                 type={"text"}
    //                 placeholder="Chave pix"
    //             />
    //             <SubmitButton
    //                 text="Continuar"
    //                 color='azul'
    //                 outline
    //                 onClick={handleNavigate}
    //             />
    //         </form>
    //     </Container>
    // )
    return (
        <Container>
            <HeaderCadastro callback={handleStepBack}/>
            <h1>Por favor, confira seus dados para concluir a inscrição.</h1>
            <form onSubmit={handleNavigate} >
            <label className="inputLabel">Razão Social</label>
                <InputText
                    placeholder="Razão Social"
                    value={dadosParceiro.conta.razao_social}
                    onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                        ...prev,
                        conta: {
                            ...prev.conta,
                            razao_social: e.target.value
                        }
                    })
                    )}
                    disabled
                    style={{backgroundColor: 'transparent'}}
                />
                <label className="inputLabel">Nome Fantasia</label>
                <InputText
                    placeholder="Nome Fantasia"
                    value={dadosParceiro.conta.nome_fantasia}
                    onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                        ...prev,
                        conta: {
                            ...prev.conta,
                            nome_fantasia: e.target.value
                        }
                    })
                    )}
                />
                <div className="dadosGrid">
                    <div style={{gridColumn: '1 / 2'}} >
                        <label className="inputLabel">CNPJ</label>
                        <CustomInputMask
                            placeholder="CNPJ"
                            value={dadosParceiro.conta.cnpj}
                            onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                                ...prev,
                                conta: {
                                    ...prev.conta,
                                    cnpj: e.target.value
                                }
                            })
                            )}
                            mask={"99.999.999/9999-99"}
                            alwaysShowMask={true}
                            disabled
                            style={{backgroundColor: 'transparent'}}
                        />
                    </div>
                    <div style={{gridColumn: '2 / 3'}} >
                    <label className="inputLabel">Faturamento médio mensal</label>
                    <CustomCurrencyInput
                        placeholder="R$ 1.000,00"
                        value={dadosParceiro.conta.vendas_mensais}                    
                        onValueChange={value => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                            ...prev,
                            conta: {
                                ...prev.conta,
                                vendas_mensais: value
                            }
                        })
                        )}
                        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                        prefix='R$'
                        allowNegativeValue={false}
                        decimalSeparator=","
                        groupSeparator="."
                        decimalScale={2}
                        maxLength={10}
                        //value={saque}
                        //onValueChange={text => { setSaque(text); console.log(text) }}
                        />
                    </div>
                    <div style={{gridColumn: '1 / 2'}} >
                        <label className="inputLabel">Setor</label>
                        <InputText
                            placeholder="Setor"
                            value={dadosParceiro.conta.setor}
                            onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                                ...prev,
                                conta: {
                                    ...prev.conta,
                                    setor: e.target.value
                                }
                            })
                            )}
                        />
                    </div>
                    <div style={{gridColumn: '2 / 3'}} >
                        <label className="inputLabel">Segmento</label>
                        <InputText
                            placeholder="Segmento"
                            value={dadosParceiro.conta.segmento}
                            onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                                ...prev,
                                conta: {
                                    ...prev.conta,
                                    segmento: e.target.value
                                }
                            })
                            )}
                        />
                    </div>
                </div>
                <label className="inputLabel">Site</label>
                <InputText
                    placeholder="Site"
                    value={dadosParceiro.conta.site}
                    onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                        ...prev,
                        conta: {
                            ...prev.conta,
                            site: e.target.value
                        }
                    })
                    )}
                />

                <label className="inputLabel">Nome do administrador</label>
                <InputText
                    type={"text"}
                    placeholder="Nome do administrador"
                    value={dadosParceiro.conta.nome}
                    onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                        ...prev,
                        conta: {
                            ...prev.conta,
                            nome: e.target.value
                        }
                    })
                    )}
                    disabled
                    style={{backgroundColor: 'transparent'}}
                />
                <label className="inputLabel">Celular</label>
                <CustomInputMask
                    type={"tel"}
                    placeholder="Celular"
                    value={dadosParceiro.conta.celular}
                    onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                        ...prev,
                        conta: {
                            ...prev.conta,
                            celular: e.target.value
                        }
                    })
                    )}
                    mask="(99) 99999-9999"
                    // @ts-ignore
                    maskChar={null}
                    disabled
                    style={{backgroundColor: 'transparent'}}
                />
                <label className="inputLabel">E-mail</label>
                <InputText
                    type={"email"}
                    placeholder="E-mail"
                    value={dadosParceiro.email}
                    onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                        ...prev,
                        email: e.target.value
                    })
                    )}
                    disabled
                    style={{backgroundColor: 'transparent'}}
                />
                
                <div className="dadosBancoContainer">                
                    <div style={{gridColumn: '1 / 3'}}>
                        <label className="inputLabel">Nome do banco</label>
                        <InputText
                            type={"text"}
                            placeholder="Nome do banco"
                            value={dadosParceiro.conta_bancaria.banco_nome}
                            onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                                ...prev,
                                conta_bancaria: {
                                    ...prev.conta_bancaria,
                                    banco_nome: e.target.value
                                }
                            })
                            )}                    
                        />
                    </div>
                    <div style={{gridColumn: '3 / 4'}} >
                        <label className="inputLabel">Código do banco</label>
                        <CustomInputMask
                            type={"text"}
                            placeholder="000"
                            value={dadosParceiro.conta_bancaria.banco_cod}
                            onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                                ...prev,
                                conta_bancaria: {
                                    ...prev.conta_bancaria,
                                    banco_cod: e.target.value
                                }
                            })
                            )}
                            mask={'999'}
                            // @ts-ignore
                            maskChar={null}                  
                        />
                    </div>
                    <div style={{gridColumn: '1 / 2'}} >
                        <label className="inputLabel">Agência</label>
                        <CustomInputMask
                            type={"text"}
                            placeholder="0000"
                            value={dadosParceiro.conta_bancaria.agencia}
                            onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                                ...prev,
                                conta_bancaria: {
                                    ...prev.conta_bancaria,
                                    agencia: e.target.value
                                }
                            })
                            )}  
                            mask={'9999'}
                            // @ts-ignore
                            maskChar={null}                  
                        />
                    </div>
                    <div style={{gridColumn: '2 / 3'}} >
                        <label className="inputLabel">Conta</label>
                        <CustomInputMask
                            type={"text"}
                            placeholder="00000000000"
                            value={dadosParceiro.conta_bancaria.conta}
                            onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                                ...prev,
                                conta_bancaria: {
                                    ...prev.conta_bancaria,
                                    conta: e.target.value
                                }
                            })
                            )}
                            mask={'99999999999'}
                            // @ts-ignore
                            maskChar={null}   
                        />
                    </div>
                    <div style={{gridColumn: '3 / 4'}} >
                        <label className="inputLabel">Dígito</label>
                        <CustomInputMask
                            type={"text"}
                            placeholder="0"
                            value={dadosParceiro.conta_bancaria.conta_digito}
                            onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                                ...prev,
                                conta_bancaria: {
                                    ...prev.conta_bancaria,
                                    conta_digito: e.target.value
                                }
                            })
                            )}
                            mask={'9'}
                            // @ts-ignore
                            maskChar={0} 
                        />
                    </div>
                    <div style={{gridColumn: '1 / 4'}} >
                        <label className="inputLabel">Tipo de conta</label>
                        <div className="checkboxContainer" >
                            <label>
                            <input type="checkbox"
                                checked={dadosParceiro.conta_bancaria.tipo === 1 ? true : false}
                                onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                                    ...prev,
                                    conta_bancaria: {
                                        ...prev.conta_bancaria,
                                        tipo: 1
                                    }
                                })
                                )} 
                            />
                            Conta Corrente
                            </label>
                            <label>
                            <input type="checkbox"
                                checked={dadosParceiro.conta_bancaria.tipo === 2 ? true : false}
                                onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                                    ...prev,
                                    conta_bancaria: {
                                        ...prev.conta_bancaria,
                                        tipo: 2
                                    }
                                })
                                )} 
                            />
                            Conta Poupança
                            </label>                        
                        </div>
                    </div>
                    </div>

                <SubmitButton
                    text="Continuar"
                    color='azul'
                    outline
                    onClick={handleNavigate}
                />
            </form>
            <Loading isLoading={loading} />
        </Container>
    )
}
import styled from 'styled-components';

export const Container = styled.main`
    .homeContent{
        padding-top: 40px;
        padding-inline: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        h2{
            font-weight:600;
            font-size:1.25rem;
            max-width: 587px;
            margin-bottom: 76px;
        }
        img{
            /* height:100%;
            width:100%; */
            max-height: 50vh;
            /* max-width:603px; */

            @media only screen and (max-width: 900px) {
                max-width: 90vw;
            }
        }
    }
`;

export const Header = styled.header`
    width: 100%;
    display: flex;
    flex-direction:row;
    padding:  36px 64px;
    align-items: center;
  


  .location{
      font-size: 1.25rem;
      padding-right: 96px;
  }
  .inputSearchWrapper{
      width: 100%;
      max-width: 747px;
      height: 43px;
      position: relative;
      margin-right: 55px;
      input{
          width: 100%;
          height: 100%;
          background-color: transparent;
          border: 1px solid var(--roxo-700);
          border-radius: 18px;
          padding-left: 18px;
          font-size: 1.25rem;
      }

      svg{
          position: absolute;
          right: 14px;
          top: 14px;
          cursor: pointer;
      }
  }

  .profileWrapper{
      padding-left: 25px;
      padding-right: 25px;
      border-left: 1px solid #2F104D;
      /* border-right: 1px solid #2F104D; */
      margin-left:auto ;
      height: 66px;
      position: relative;
      display: flex;
      align-items: center;
      .profile{
        width: 240px;
        display: flex;
        flex-direction:row;
        justify-content: end;
        .menuMobileBtn{
            display:none;
            cursor: pointer;
        }
        .avatar{
            cursor: pointer;
            border-radius: 50%;
            margin-right: 16px;
        }
        .profileContent{
            justify-content: center;
            display:flex;
            flex-direction:column;
            
            .name{
                font-size: 1.25rem;
            }
            .userID{
                margin-top: 6px;
                color: rgba(52,9,80,50%);
            }

        }
        .arrowDownIcon{
            cursor: pointer;
            position: relative;
            top: 8px;
            margin-left: 15px;
        }
      }
     .profileOptions{
        display: none;

         position: absolute;
         background-color: var(--laranja-500);
         color: white;
         width: 100%;
         
         bottom: 0;
         height: 300px;
         top: 68px;
         ul{
             li{
                 list-style-type:none;
             }
         }
     }
  }

  @media only screen and (max-width: 1366px) {
    padding:  36px 34px;
    .location{
      font-size: 1.25rem;
      padding-right: 36px;
    }
    .inputSearchWrapper{
      margin-right: 25px;
    }

 } 
    /* @media only screen and (max-width: 1024px) {
        .profileWrapper{
            border-left: none;
            padding-right:0;
        .profile{
            width: 60px;
            .avatar{
                margin:0 ;
            }
            .profileContent{
                display:none ;

            }
            svg{
                display: none;
            }
        }
    }
    } */

    @media only screen and (max-width: 900px) {
        .profileWrapper{
          
        .profile{
            .menuMobileBtn{
                display:block;
            }
            .avatar{
                /* display:none ; */
            }
            
        }
    }
    }
    @media only screen and (max-width: 500px) {
        /* .location{
            padding-right: 20px;
        } */
        .profileWrapper{
            border-left: none;
            /* padding:0 ; */
        /* .profile{
            width: 53px;
            
        } */
    }
    }

`
import { Container, Label, Line, Step, Wrapper } from "./styles";

interface CustomizedSteppersProps {
    steps: string[];
    currentStep: number;
    index?: number;
}

export default function CustomizedSteppers({ steps, currentStep }: CustomizedSteppersProps) {
    return (
        <>
            <Container>
                {
                    steps.map((item, index) => {
                        return (
                            <Wrapper active={index == currentStep} key={index}>
                                {
                                    index + 1 != steps.length && (<Line active={index == currentStep} />)
                                }

                                <Step
                                    active={index == currentStep}
                                />
                                <Label active={index == currentStep} >
                                    {item}
                                </Label>
                            </Wrapper>
                        )
                    })
                }
            </Container>
        </>

    );
}

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from 'react-toastify'

import useZendesk from "../../hooks/useZendesk";
import { postLoginAction, postMeAction } from "../../actions";

import { Container, Esqueceu } from "./styles";
import Logo from '../../assets/imagens/logo.png';
import { SubmitButton } from "../../components/form/SubmitButton";
import { LoginInputs } from "../../components/form/LoginInputs";
import { Loading } from "../../components/Loading/Loading";

export function Login() {
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const {startZendesk, removeZendesk} = useZendesk();
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [loading, setLoading] = useState(false);

    async function handleLogin() {
        setLoading(true);
        try {
            const token = await dispatch(postLoginAction(email, senha) as any);
            if (token) {
                const res = await dispatch(postMeAction(token.access_token) as any);
                    if (res) {
                        await localStorage.setItem(
                            '@auth',
                            JSON.stringify({
                                access_token: token.access_token,
                                token_type: token.token_type,
                                expires_in: token.expires_in,
                                login_time: new Date().getTime(),
                                conta_id: res.conta_id
                            })
                        );                     
                        await startZendesk(token.access_token);
                        return navigation("/dashboard/home");
                    }
            }
        } catch (err) {
            toast.error("Verfique os dados e tente novamente");
            console.log(err);
        }
        finally{
            setLoading(false);
        }

    }

    useEffect(() => {
        removeZendesk();
    },[])

    return (
        <Container>
            <div className="content">
                <img src={Logo} alt="logo-jeitto" />

                <form >
                    <LoginInputs
                        placeholder="Email"
                        autoComplete="on" 
                        autoCorrect="true" 
                        onChange={(e: any) => setEmail(e.target.value)}
                    />
                    <div style={{marginBottom: 30}} />
                    <LoginInputs
                        placeholder="Senha"
                        password 
                        onChange={(e: any) => setSenha(e.target.value)}
                    />
                    <div style={{marginBottom: 30}} />

                    <Esqueceu>
                        <Link to={"/recuperar-senha"}>
                            Esqueci a senha
                        </Link>
                    </Esqueceu>
                    <Esqueceu>
                        <Link to={"/primeiro-acesso"}>
                            Primeiro acesso
                        </Link>
                    </Esqueceu>

                    <div className="submitContainer">
                        <SubmitButton type="button" text="Entrar" onClick={handleLogin} />
                    </div>
                </form>

                <div className="fazerCadastro">
                    <label>É seu primeiro acesso?</label>
                    <Link to={"/cadastro-pre"} className='link' >Faça seu Cadastro</Link>
                </div>
            </div>
            
            <Loading isLoading={loading} />
        </Container>
    )
};
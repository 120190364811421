import styled from "styled-components";

export const Container = styled.div`
    padding: 20px;
    display: flex;
    flex-direction:column ;

    h1{
        margin-top: 90px;
        margin-bottom: 50px;
        font-size: 2rem;
        align-self: center;
        font-weight: 500;
    }
    form{
        width: 100%;
        max-width: 500px;
        
        margin: 0 auto;
        display: grid;
        gap: 10px;

        .inputLabel{
            padding-top: 19px;
            padding-bottom: 13px;
            padding-left: 10px;
            display:inline-block;
            font-size: 1rem;
            font-weight: 600;
            
        }

        button{
            align-self: center;
            margin-top: 80px;
            margin-bottom: 30px;
        }
    }
`
import {
    POST_LOGIN,
    POST_ME,
    POST_CONTA_VENDEDOR_NOVA_VIDA
} from "../constants/actionsStrings";
import { 
    postLogin,
    postMe,
    postContaVendedorNovaVida
} from "../services/service";

export const postLoginAction = (email: string, password: string) => async (dispatch: any) => {
    try {
        const res = await postLogin(email, password);
        dispatch({
            type: POST_LOGIN,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        throw new Error(err as string)

    }

};

export const postMeAction = (token: string) => async (dispatch: any) => {
    try {
        const res = await postMe(token);
        dispatch({
            type: POST_ME,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        throw new Error(err as string)

    }
}

export const postContaVendedorNovaVidaAction = (id: string) => async (dispatch: any) => {
    try {
        const res = await postContaVendedorNovaVida(id);
        dispatch({
            type: POST_CONTA_VENDEDOR_NOVA_VIDA,
            payload: res.data,
        });
        return {success: true, data: res.data};
    } catch (err) {
        // throw new Error(err as string)
        console.log(err)
        return {success: false, data: {}};

    }
}
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 500px;

  .qrcodeContainer{
    width: 100px;
    padding: 8px;
    background-color: var(--white);
    border-radius: 0 0 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 30px;

    .qrcode{
      width: 100%;

    }

  }

  p{
      position: absolute;
      font-size: 16px;
      font-weight: 900;
      color: var(--white);
      top: 54px;
      left: 96px;

    }

  .background{
    width: 100%;
    
  }

  @media (max-width: 1300px){
    width: 300px;

    .qrcodeContainer{
      width: 60px;
      padding: 6px;
      right: 20px;

      .qrcode{
        width: 90%;
      }
  
    }

    p{
      position: absolute;
      font-size: 10px;
      top: 32px;
      left: 56px;

    }
  
  }
`

export const HiResContainer = styled.div`
  position: relative;

  .qrcodeContainer{
    width: 160px;
    padding: 8px;
    background-color: var(--white);
    border-radius: 0 0 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 50px;

    .qrcode{
      width: 100%;
    }

  }

  p{
      position: absolute;
      font-size: 28px;
      font-weight: 700;
      color: var(--white);
      top: 95px;
      left: 164px;
      //necessário para o download da imagem
      white-space: nowrap;

    }

`
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
const useAuth = () => {
    const history = useNavigate();

    const auth = () => {
        const auth = JSON.parse(localStorage.getItem('@auth')!);

        if (auth) {
            const timeDiference = (new Date().getTime() - auth.login_time) / 1000;

            if (timeDiference > auth.expires_in) {
                localStorage.removeItem('@auth');
                toast.warning('Seu tempo de acesso expirou!', { autoClose: false });
                history('/');
            }
            return auth;
        } else {
            return { access_token: null };
        }
    };
    return {token: auth().access_token, contaId: auth().conta_id};
};

export default useAuth;

import { useState } from "react";
import { IoMdEyeOff, IoMdEye } from 'react-icons/io';

import { Container } from './styles';

interface InputPasswordProps extends React.InputHTMLAttributes<HTMLInputElement> { }
export function InputPassword({ ...rest }: InputPasswordProps) {

    const [isHidden, setIsHidden] = useState(true);

    function toggleInpuType() {
        setIsHidden(!isHidden)
    }
    return (
        <Container>
            <input
                type={isHidden ? "password" : "text"}
                {...rest}
            />
            {
                isHidden ? (
                    <IoMdEye
                        onClick={toggleInpuType}
                        className="icon"
                        size={24}
                    />
                ) : (
                    <IoMdEyeOff
                        onClick={toggleInpuType}
                        className="icon"
                        size={24}
                    />
                )
            }

        </Container>

    )
} 
import React, { useEffect, useRef, useState } from "react";
import { FaCopy, FaLink } from "react-icons/fa";
import { FiDelete  } from "react-icons/fi";
import { RiQrCodeFill } from "react-icons/ri";
import { IoCloseCircle, IoSendSharp } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import domtoimage from 'dom-to-image';

import useAuth from "../../../../hooks/useAuth";
import { getTodasTransacoes, getTodosQrCode, deleteDeletarQrCode, postCompartilharQrCodeEmail, getTodosQrCodeContaId, getTodasTransacoesContaId } from "../../../../services/service";
import { Container, PagarButton, StatusButton, Table } from "./styles";

import { Loading } from "../../../../components/Loading/Loading";
import { CustomCurrencyInput } from "../../../../components/form/CustomCurrencyInput";
import { CustomInputMask } from "../../../../components/form/CustomInputMask";
import { InputText } from "../../../../components/form/InputText";
import { FullScreenModal } from "../../../../components/FullScreenModal";
import { OutlinedButton } from "../../../../components/OutlinedButton";
import PageNavigation from "../../../../components/PageNavigation";
import { CompartilharQrCode } from "../../../../components/CompartilharQrCode";

interface qrCodeTableProps {
    id: string;
    created_at: string;
    expiracao: string;
    descricao: string;
    transacao: [{
        value: any;
    }]
    conta: { 
        celular: string;
        cnpj: string;
        conta_pai_id: string;
        created_at: string;
        deleted_at: string | null;
        email: string;
        id: string;
        nome: string;
        nome_fantasia: string;
        razao_social: string;
        segmento: string;
        setor: string;
        site: string;
        tipo: string;
        updated_at: string;
        vendas_mensais: string;
        vendedor_api_id: string;
    }
}

interface transacoesTableProps {
    id: string;
    nsu_transaction: string;
    value: string;
    cpf: string;
    name: string;
    email: string;
    agency_code: string;
    expiration_date: string;
    created_at: string;
    status: number;
    qr_code: {
        conta_id: string;
        created_at: string;
        deleted_at: string |null;
        descricao: string;
        expiracao: string;
        id: string;
        updated_at: string;
    }
}

const parseValor = (valor: string) => {
    if(valor === null || valor === undefined || valor === ''){
        return 0.00.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    } else {
        return parseFloat(valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    }
}

const parseData = (data: string, dataEHoraCriacao = false, dataCriacao = false) => {
    if(data === null || data === undefined || data === ''){
        return 'Sem vencimento';
    } else if (dataEHoraCriacao) {
        return `${new Date(data).toLocaleTimeString('pt-BR', {hour: '2-digit', minute: '2-digit'})} - ${new Date(data).toLocaleDateString('pt-BR')}`;
    } else if (dataCriacao) {
        return `${new Date(data).toLocaleDateString('pt-BR')}`;
    } else {
        const [yyyy,mm,dd] = data.split('-');
        return `${dd}/${mm}/${yyyy}`;
        // return `${new Date(data).toLocaleDateString('pt-BR')}`;
    }
}

const parseValorForRequest = (valor: string) => {
    if(valor === null || valor === undefined || valor === ''){
        return '';
    } else {
        return valor.replaceAll('.', '').replace(',','.');
    }
}

const parseDataForRequest = (data: string) => {
    if(data === null || data === undefined || data === ''){
        return '';
    } else {
        const [D, M, A] = data.split('/')
        return `${A}-${M}-${D}`
    }
}

const parseStatus = ( status: number | undefined) => {
// cores VBANK
// 'pending' backgroundColor: '#F1E3D4' color: 'orange'
// 'active' backgroundColor: '#C9DBF2' color: '#75B1ED'
// 'enabled' backgroundColor: '#C9DBF2' color: '#75B1ED'
// 'approved' backgroundColor: '#C9ECE7' color: '#00B57D'
// 'denied' backgroundColor: '#ECC9D2' color: '#ED757D'
// cores Jeitto
// #F15A22
// #5CEE79

    switch (status) {
        case 0: 
            return <span style={{padding: '5px 10px', borderRadius: '32px', backgroundColor: '#C9DBF2', color: '#75B1ED'}} >Pendente</span>;
        
        case 1: 
            return <span style={{padding: '5px 10px', borderRadius: '32px', backgroundColor: '#F1E3D4', color: 'orange'}} >Expirada</span>;
        
        case 2: 
            return <span style={{padding: '5px 10px', borderRadius: '32px', backgroundColor: '#C9ECE7', color: '#00B57D'}} >Confirmada</span>;
        
        case 3: 
            return <span style={{padding: '5px 10px', borderRadius: '32px', backgroundColor: '#ECC9D2', color: '#ED757D'}} >Sem limite</span>;
        
        case 4: 
            return <span style={{padding: '5px 10px', borderRadius: '32px', backgroundColor: '#ECC9D2', color: '#ED757D'}} >Erro</span>;
        
        case 5: 
            return <span style={{padding: '5px 10px', borderRadius: '32px', backgroundColor: '#ECC9D2', color: '#ED757D'}} >Sem limite POS/PRE</span>;
        
        case 6: 
            return <span style={{padding: '5px 10px', borderRadius: '32px', backgroundColor: '#ECC9D2', color: '#ED757D'}} >Valor não alcançado</span>;
        
        case 7: 
            return <span style={{padding: '5px 10px', borderRadius: '32px', backgroundColor: '#ECC9D2', color: '#ED757D'}} >Limite bloqueado</span>;
        
        case 8: 
            return <span style={{padding: '5px 10px', borderRadius: '32px', backgroundColor: '#ECC9D2', color: '#ED757D'}} >Negado</span>;
        
        default:
            return <span style={{padding: '5px 10px', borderRadius: '32px', backgroundColor: '#ECC9D2', color: '#ED757D'}} >Erro</span>;
    }
}

export function Operacao() {
    const navigate = useNavigate();
    const location = useLocation();
    const {token, contaId} = useAuth();
    const ref = useRef<HTMLDivElement>(null);
    const bannerImageRef = useRef<HTMLDivElement>(null);
    const [showQrCodeScreen, setShowQrCodeScreen] = useState(true);
    const [showBuscarModal, setShowBuscarModal] = useState(false);
    const [showSetStausModal, setShowSetStausModal] = useState(false);
    const [showQrCodeLinkModal, setShowQrCodeLinkModal] = useState(false);
    const [showDeletarQrCodeModal, setShowDeletarQrCodeModal] = useState(false);
    const [dataQrCodeLinkModal, setDataQrCodeLinkModal] = useState({} as qrCodeTableProps);
    const [deletarQrCodeModalData, setDeletarQrCodeModalData] = useState({} as qrCodeTableProps);
    const [dataQrCode, setDataQrCode] = useState<qrCodeTableProps[]>([]);
    const [dataTransacoes, setDataTransacoes] = useState<transacoesTableProps[]>([]);
    const [pagina, setPagina] = useState({current: 1, last: 1});
    const [loading, setLoading] = useState(false);
    const [filterQrCode, setFilterQrCode] = useState({
        id: '',
        descricao: '',
        expiracao: '',
        conta_id: `${location?.search?.replace('?filter[conta_id]=', '')}`,
    });
    const [filterTransacoes, setFilterTransacoes] = useState({
        id: '',
        created_at_between_start: '',
        created_at_between_end: '',
        expired_date_between_start: '',
        expired_date_between_end: '',
        value_between_start: '',
        value_between_end: '',
        nsu_transaction: '',
        email: '',
        name: '',
        ddd_phone: '',
        cpf: '',
        agency_code: '',
        status: '',
        conta_id: `${location?.search?.replace('?filter[conta_id]=', '')}`,
    });

    const handleLoadData = async () => {       
        const loadData = async () => {
            setLoading(true);
            if (showQrCodeScreen) {
                try{
                    const res = await getTodosQrCode(token, 1, `filter[conta_id]=${filterQrCode.conta_id}`);           
                    setDataQrCode(res.data.data);        
                    setPagina({current: 1, last: res.data.last_page});
                    setLoading(false);
                } catch(err) {
                    console.log(err);
                    setLoading(false);
                }
            } else {
                try{
                    const res = await getTodasTransacoes(token, 1, `filter[conta_id]=${filterTransacoes.conta_id}`);
                    setDataTransacoes(res.data.data);
                    setPagina({current: 1, last: res.data.last_page});
                    // setFilterTransacoes({
                    //     id: '',
                    //     created_at_between_start: '',
                    //     created_at_between_end: '',
                    //     expired_date_between_start: '',
                    //     expired_date_between_end: '',
                    //     value_between_start: '',
                    //     value_between_end: '',
                    //     nsu_transaction: '',
                    //     email: '',
                    //     name: '',
                    //     ddd_phone: '',
                    //     cpf: '',
                    //     agency_code: '',
                    //     status: '',
                    //     conta_id: ''
                    // });
                    setLoading(false);
                } catch(err) {
                    console.log(err);
                    setLoading(false);
                }
            }
        }
        const loadDataParceiro = async () => {
            setLoading(true);
            if (showQrCodeScreen) {
                try{
                    const res = await getTodosQrCodeContaId(token, filterQrCode.conta_id, 1, `filter[conta_id]=${filterQrCode.conta_id}`);           
                    setDataQrCode(res.data.data);        
                    setPagina({current: 1, last: res.data.last_page});
                    setLoading(false);
                } catch(err) {
                    console.log(err);
                    setLoading(false);
                }
            } else {            
                try{
                    const res = await getTodasTransacoesContaId(token, filterTransacoes.conta_id, 1, `filter[conta_id]=${filterTransacoes.conta_id}`);
                    setDataTransacoes(res.data.data);
                    setPagina({current: 1, last: res.data.last_page});
                    setFilterTransacoes({
                        id: '',
                        created_at_between_start: '',
                        created_at_between_end: '',
                        expired_date_between_start: '',
                        expired_date_between_end: '',
                        value_between_start: '',
                        value_between_end: '',
                        nsu_transaction: '',
                        email: '',
                        name: '',
                        ddd_phone: '',
                        cpf: '',
                        agency_code: '',
                        status: '',
                        conta_id: ''
                    });
                    setLoading(false);
                } catch(err) {
                    console.log(err);
                    setLoading(false);
                }
            }
        }

        if(location.search && contaId){
            loadDataParceiro();            
        } else {
            loadData();
        }
    }
    
    const handleMudarPagina = async () => {
        const mudarPaginaQrCode = async () => {
            try{
            const res = await getTodosQrCode(
                token,
                pagina.current,
                `filter[conta_id]=${filterQrCode.conta_id}&filter[id]=${filterQrCode.id}&filter[descricao]=${filterQrCode.descricao}&filter[expiracao]=${parseDataForRequest(filterQrCode.expiracao)}`                        
                );                
                setDataQrCode(res.data.data);
                setLoading(false);     
            } catch(err) {
                console.log(err);
                setLoading(false);
            }
        }

        const mudarPaginaTransacoes = async () => {
            try{
                const res = await getTodasTransacoes(
                    token,
                    pagina.current,
                    `filter[conta_id]=${filterTransacoes.conta_id}&filter[id]=${filterTransacoes.id}&filter[created_at_between]=${parseDataForRequest(filterTransacoes.created_at_between_start)},${parseDataForRequest(filterTransacoes.created_at_between_end)}&filter[expired_date_between]=${parseDataForRequest(filterTransacoes.expired_date_between_start)},${parseDataForRequest(filterTransacoes.expired_date_between_end)}&filter[value_between]=${parseValorForRequest(filterTransacoes.value_between_start)},${parseValorForRequest(filterTransacoes.value_between_end)}&filter[nsu_transaction]=${filterTransacoes.nsu_transaction}&filter[email]=${filterTransacoes.email}&filter[name]=${filterTransacoes.name}&filter[ddd_phone]=${filterTransacoes.ddd_phone}&filter[cpf]=${(filterTransacoes.cpf).replaceAll('.','').replace('-','')}&filter[agency_code]=${filterTransacoes.agency_code}&filter[status]=${filterTransacoes.status}`
                    // limit=10
                   )
                setDataTransacoes(res.data.data);
                setPagina(prev => ({...prev, last: res.data.last_page}))
                setLoading(false);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        }

        const mudarPaginaQrCodeParceiro = async () => {
            try{
            const res = await getTodosQrCodeContaId(
                token,
                filterQrCode.conta_id,
                pagina.current,
                `filter[conta_id]=${filterQrCode.conta_id}&filter[id]=${filterQrCode.id}&filter[descricao]=${filterQrCode.descricao}&filter[expiracao]=${parseDataForRequest(filterQrCode.expiracao)}`                        
                );                
                setDataQrCode(res.data.data);
                setLoading(false);     
            } catch(err) {
                console.log(err);
                setLoading(false);
            }
        }

        const mudarPaginaTransacoesParceiro = async () => {
            try{
                const res = await getTodasTransacoesContaId(
                    token,
                    filterTransacoes.conta_id,
                    pagina.current,
                    `filter[conta_id]=${filterTransacoes.conta_id}&filter[id]=${filterTransacoes.id}&filter[created_at_between]=${parseDataForRequest(filterTransacoes.created_at_between_start)},${parseDataForRequest(filterTransacoes.created_at_between_end)}&filter[expired_date_between]=${parseDataForRequest(filterTransacoes.expired_date_between_start)},${parseDataForRequest(filterTransacoes.expired_date_between_end)}&filter[value_between]=${parseValorForRequest(filterTransacoes.value_between_start)},${parseValorForRequest(filterTransacoes.value_between_end)}&filter[nsu_transaction]=${filterTransacoes.nsu_transaction}&filter[email]=${filterTransacoes.email}&filter[name]=${filterTransacoes.name}&filter[ddd_phone]=${filterTransacoes.ddd_phone}&filter[cpf]=${(filterTransacoes.cpf).replaceAll('.','').replace('-','')}&filter[agency_code]=${filterTransacoes.agency_code}&filter[status]=${filterTransacoes.status}`
                    // limit=10
                   )
                setDataTransacoes(res.data.data);
                setPagina(prev => ({...prev, last: res.data.last_page}))
                setLoading(false);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        }
            
        setLoading(true);
        if(location.search && contaId){
            if (showQrCodeScreen) {
                mudarPaginaQrCodeParceiro();
            } else {
            mudarPaginaTransacoesParceiro();
            }            
        } else {
            if (showQrCodeScreen) {
                mudarPaginaQrCode();
            } else {
                mudarPaginaTransacoes();
            }  
        }
    }

    const handleCopyLink = (id: string) => {
        try {
            if( navigator.clipboard !== undefined){
                navigator.clipboard.writeText(`${window.location.origin.toString()}/pagar-qrcode/${id}`);
                toast.success(`O link para o Qr Code foi copiado!`);
            } else {
                const textArea = document.createElement("textarea");
                textArea.value = `${window.location.origin.toString()}/pagar-qrcode/${id}`;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                try {
                    document.execCommand('copy');
                    toast.success(`O link para o Qr Code foi copiado!\npagar-qrcode/${id}`);
                } catch (err) {
                    toast.warn(`Não foi possível copiar o link`);
                }
                finally{
                    document.body.removeChild(textArea);
                }
            }
        } catch (err) {
            console.log(err);
            toast.warn(`Não foi possível copiar o link`);
        }
    }

    const handleNavigateLink = (id: string) => {
        // navigate(`/pagar-qrcode/${id}`);
        window.open(`${window.location.origin.toString()}/pagar-qrcode/${id}`, '_blank');
    }

    const handleSetStatusSearch = (status: string) => {
        setFilterTransacoes( prev => ({
            ...prev,
            status: status
        }))
    }

    const QrCodeTable = () => {
        return (
            <Table>
                <div className="tabelaContainer">
                    <table cellPadding={20} cellSpacing={10}>
                        <thead>
                            <tr>
                                <td>Data de criação</td>
                                <td>Unidade</td>
                                <td className="centerColumn" >Link</td>
                                <td className="centerColumn" >QR Code</td>
                                <td className="centerColumn" >Vencimento</td>
                                <td className="centerColumn" >Valor Total Faturado</td>
                                <td>Descrição</td>
                                <td>ID</td>
                                <td className="centerColumn" >Deletar</td>
                            </tr>
                        </thead>

                        <tbody>                            
                            {dataQrCode.map( (obj, idx) => (                            
                                <tr key={idx}>
                                    <td>{parseData(obj.created_at, true)}</td>
                                    <td>{obj.conta.nome_fantasia}</td>
                                    <td style={{display: 'flex', justifyContent: "space-evenly"}} >
                                        <FaLink
                                            size={20}
                                            style={{color:'#340950', cursor: 'pointer'}}
                                            onClick={() => handleNavigateLink(obj.id)}
                                        />
                                        <FaCopy
                                            size={20}
                                            style={{color:'#340950', cursor: 'pointer'}}
                                            onClick={() => handleCopyLink(obj.id)}
                                        />
                                    </td>
                                    <td className="centerColumn" >
                                        <RiQrCodeFill
                                            size={24}
                                            style={{color:'#340950', cursor: 'pointer'}}
                                            onClick={() => {
                                                setLoading(true);
                                                setShowQrCodeLinkModal(true);
                                                setDataQrCodeLinkModal(obj);
                                            }}
                                        />
                                    </td>
                                    <td className="centerColumn" >{parseData(obj.expiracao)}</td>
                                    <td className="centerColumn" >{
                                        parseValor(
                                            obj.transacao.reduce((obj, obj2) => ({value: parseFloat(obj?.value) + parseFloat(obj2?.value)}), {value: 0}).value
                                        )
                                    }
                                    </td>
                                    <td>{obj.descricao}</td>
                                    <td>{obj.id}</td>
                                    <td className="centerColumn" >
                                        <FiDelete
                                            size={26}
                                            color="#F15A22"
                                            fill='transparent'
                                            style={{cursor: 'pointer'}}
                                            onClick={() => {
                                                setShowDeletarQrCodeModal(true);
                                                setDeletarQrCodeModalData(obj);
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Table>
        )
    }

    const TransacoesTable = () => {
        return (
            <Table>
                <div className="tabelaContainer">
                    <table cellPadding={20} cellSpacing={10}>
                        <thead>
                            <tr>
                                <td>Data de criação</td>
                                <td>Vencimento</td>
                                {/* <td>Nome</td> */}
                                <td>CPF</td>
                                <td>Valor</td>
                                <td>Status</td>
                                <td>NSU</td>
                                <td>Descrição</td>
                                <td>ID</td>
                                {/* <td>Email</td> */}
                                {/* <td>Unidade</td>                                 */}
                            </tr>
                        </thead>

                        <tbody>                            
                            {dataTransacoes.map( (obj, idx) => (
                                <tr key={idx}>
                                    <td style={{width: '1%'}}>{parseData(obj.created_at, true)}</td>
                                    <td style={{width: '1%'}}>{parseData(obj.expiration_date, false, true)}</td>
                                    {/* <td style={{width: '1%'}}>{obj.name}</td> */}
                                    <td style={{width: '1%'}}>{obj.cpf}</td>
                                    <td style={{width: '1%'}}>{parseValor(obj.value)}</td>
                                    <td style={{width: '1%'}}>{parseStatus(obj.status)}</td>
                                    <td style={{width: '1%'}}>{obj.nsu_transaction}</td>
                                    <td style={{width: '1%'}}>{obj?.qr_code?.descricao}</td>
                                    <td style={{width: '1%'}}>{obj.id}</td>
                                    {/* <td style={{width: '1%'}}>{obj.email}</td> */}
                                    {/* <td style={{width: '1%'}}>{obj.agency_code}</td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Table>
        )
    }

    const QrCodeLinkModal = () => {
        const [enviarPorWhats, setEnviarPorWhats] = useState(false);
        const [enviarPorEmail, setEnviarPorEmail] = useState(false);
        const [phone, setPhone] = useState('');
        const [email, setEmail] = useState('');

        const handleEnviarPorWhats = (e: React.FormEvent) => {
            e.preventDefault();
            const text = `Quase lá! Finalize seu pagamento com o Jeitto:%0a${window.location.origin.toString()}/pagar-qrcode/${dataQrCodeLinkModal.id}`.replace(/\ /g, '%20');

            if(phone.length < 14){
                toast.warning("Insira um número de telefone válido.");
                return
            }

            try {
                window.open(
                    `https://api.whatsapp.com/send?phone=55${phone}&text=${text}`,
                    '_blank'
                );
                setEnviarPorWhats(false);
                setPhone('');
            } catch (err) {
                console.log(err);                
            }
        }

        const handleEnviarPorEmail = async(e: React.FormEvent) => {
            e.preventDefault();

            try {
                setEnviarPorEmail(false);
                await postCompartilharQrCodeEmail(token, dataQrCodeLinkModal.id, email)
                toast.success("Email enviado com sucesso");
                setEmail('');
            } catch (err) {
                console.log(err);
                toast.error("Ocorreu um erro ao enviar  email. Tente novamente mais tarde.");
            }
        }

        const handleDonwloadQrCode = async () => {
            // return getDownloadQrCodeImage(`${window.location.origin.toString()}/pagar-qrcode/${dataQrCodeLinkModal.id}`);
            if(bannerImageRef.current === null) {
                toast.error('Ocorreu um erro de referência ao salvar a imagem.');
                return
            }
            try{
                const res = await domtoimage.toJpeg(bannerImageRef.current);                
                (() => {
                    let link = document.createElement('a');
                    link.download = 'QR Code.jpeg';
                    link.href = res;
                    link.click();
                })();
            } catch(err) {
                console.log(err);
                toast.error('Ocorreu um erro ao salvar a imagem. Tente novamente mais tarde');
            }
    }

        return(
            <FullScreenModal show={showQrCodeLinkModal} setShow={setShowQrCodeLinkModal}>
                <div style={{position: 'absolute', top: '1000000px'}} >
                    <div ref={bannerImageRef} >
                        <CompartilharQrCode
                            empresa={dataQrCodeLinkModal?.conta?.nome_fantasia}
                            dataQrCode={dataQrCodeLinkModal.id}
                            setLoading={setLoading}
                            hiRes
                        />
                    </div>
                </div>
                <CompartilharQrCode
                    empresa={dataQrCodeLinkModal?.conta?.nome_fantasia}
                    dataQrCode={dataQrCodeLinkModal.id}
                    setLoading={setLoading}
                />
                <div className="QrCodeLinkModalButtonsContainer">
                    {enviarPorWhats || enviarPorEmail
                    ?
                        enviarPorWhats ?
                        <form className="form" onSubmit={handleEnviarPorWhats}>
                            <p>Enviar por WhatsApp</p>
                            <CustomInputMask
                                value={phone}
                                onChange={e => setPhone(e.target.value)}
                                mask={'(99) 99999-9999'}
                                //@ts-ignore
                                maskChar={null}
                                autoFocus
                            />
                            <div className="QrCodeLinkModalEnviarButtonsContainer">
                                <IoCloseCircle
                                    size={26}
                                    color="#F15A22"
                                    fill='#F15A22'
                                    style={{cursor: 'pointer'}}
                                    onClick={() => setEnviarPorWhats(false)}
                                    type='button'
                                />
                                <IoSendSharp
                                    size={26}
                                    fill='#5CEE79'
                                    style={{cursor: 'pointer'}}
                                    onClick={handleEnviarPorWhats}
                                    type='submit'
                                />
                            </div>
                        </form>
                        :
                        <form className="form" onSubmit={handleEnviarPorEmail}>
                            <p>Enviar por email</p>
                            <InputText
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                autoFocus
                            />
                            <div className="QrCodeLinkModalEnviarButtonsContainer">
                                <IoCloseCircle
                                    size={26}
                                    fill='#F15A22'
                                    style={{cursor: 'pointer'}}
                                    onClick={() => setEnviarPorEmail(false)}
                                    />
                                <IoSendSharp
                                    size={26}
                                    fill='#5CEE79'
                                    style={{cursor: 'pointer'}}
                                    onClick={handleEnviarPorEmail}
                                />
                            </div>
                        </form>
                    :
                    <>
                        <OutlinedButton
                        show={showQrCodeLinkModal}
                        onClick={() => setEnviarPorWhats(true)}
                        style={{width: '160px'}}
                        >Enviar por WhatsApp</OutlinedButton>
                        <OutlinedButton
                        show={showQrCodeLinkModal}
                        onClick={handleDonwloadQrCode}
                        style={{width: '160px'}}
                        >Baixar QR Code</OutlinedButton>
                        <OutlinedButton
                        show={showQrCodeLinkModal}
                        onClick={() => setEnviarPorEmail(true)}
                        style={{width: '160px'}}
                        >Enviar por email</OutlinedButton>
                    </>}
                 </div>
            </FullScreenModal>
        )
    }

    const DeletarQrCodeModal = () => {
        
        const handleDeletarQrCode = async () => {
            setShowDeletarQrCodeModal(false);
            setLoading(true);
            try {
                const res = await deleteDeletarQrCode(token, deletarQrCodeModalData.id);
                if(res.statusText === "OK"){
                    await handleMudarPagina();
                    setLoading(false);
                    toast.success('Conta deletada com sucesso');
                } else {
                    toast.error('Ocorreu um erro. Tente novamente mais tarde');
                    setLoading(false);
                }
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        }

        return(
            <FullScreenModal show={showDeletarQrCodeModal} setShow={setShowDeletarQrCodeModal}>
                <p className="title">Deseja deletar o QrCode?</p>
                <form>
                    <div style={{marginTop: '30px', marginBottom: '25px'}}>
                        <div className="oneLineInputs" >
                            <h4>Data de criação:</h4>
                            <h4>{parseData(deletarQrCodeModalData.created_at, true)}</h4>             
                        </div>
                        <div className="oneLineInputs" >
                            <h4>Vencimento:</h4>
                            <h4>{parseData(deletarQrCodeModalData.expiracao)}</h4>             
                        </div>
                        <div className="oneLineInputs" >
                            <h4>Descrição:</h4>
                            <h4>{deletarQrCodeModalData.descricao}</h4>             
                        </div>
                    </div>
                <h4>Essa ação é irreversível</h4>
                </form>
                <OutlinedButton
                 show={showDeletarQrCodeModal}
                 onClick={handleDeletarQrCode}
                >Deletar QR Code</OutlinedButton>
            </FullScreenModal>
        )
    }

    useEffect(() => {
        handleLoadData();
    }, [showQrCodeScreen]);

    useEffect(() => {
        handleMudarPagina();
    },[pagina.current]);

    useEffect(() => {        
        const handleCloseSetStatusModalOnClick = (event: MouseEvent): void => {
            if(showSetStausModal){
                if (ref.current && !ref.current.contains(event.target as Node)) {
                    setShowSetStausModal(false);
                    handleSetStatusSearch('');
                }
            }
        }

        document.addEventListener('mousedown', handleCloseSetStatusModalOnClick);
        return () => {
            document.removeEventListener('mousedown', handleCloseSetStatusModalOnClick);
        };
    });

    return (
        <Container>

            <div className="pagarButtonContainer">
                <PagarButton
                    className="pagarButton"
                    isSelected={showQrCodeScreen}
                    onClick={() => setShowQrCodeScreen(true)}
                >
                    QRCodes
                </PagarButton>
                <PagarButton
                    className="pagarButton"
                    isSelected={!showQrCodeScreen}
                    onClick={() => setShowQrCodeScreen(false)}
                >
                    Transações
                </PagarButton>
            </div>

            <div className="searchBar">
                <OutlinedButton show={true} onClick={() => setShowBuscarModal(true)}>
                    Buscar
                </OutlinedButton>
                <OutlinedButton show={showQrCodeScreen} onClick={() => navigate('./criar-qrcode')}>
                    Criar QR Code
                </OutlinedButton>
            </div>

            {showQrCodeScreen
                ?
                <QrCodeTable />
                :
                <TransacoesTable />
            }

            <PageNavigation pagina={pagina} setPagina={setPagina} />

            <FullScreenModal show={showBuscarModal} setShow={setShowBuscarModal}>
                    {showQrCodeScreen
                        ?
                        <form>
                            <div className="limparFiltroButtonContainer" >
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if(contaId === null){
                                            setFilterQrCode({
                                                id: '',
                                                descricao: '',
                                                expiracao: '',
                                                conta_id: ''
                                            });
                                        }else{
                                            //@ts-ignore
                                            setFilterQrCode({
                                                id: '',
                                                descricao: '',
                                                expiracao: '',
                                                // conta_id: ''
                                            });
                                        }
                                    }}
                                >
                                    Limpar filtros
                                </button>
                            </div>

                            <h3>Filtar por dados</h3>
                            {contaId === null ?
                                <div className="oneLineInputs" >
                                    <InputText
                                        value={filterQrCode.conta_id}
                                        onChange={(e) => {
                                            setFilterQrCode( prev => ({
                                                ...prev,
                                                conta_id: e.target.value
                                            }))
                                        }}
                                        className="inputStyle"
                                        placeholder={"ID da conta"}
                                    />
                                </div>
                            : null }
                            <div className="oneLineInputs" >
                                <InputText
                                    value={filterQrCode.id}
                                    onChange={(e) => {
                                        setFilterQrCode( prev => ({
                                            ...prev,
                                            id: e.target.value
                                        }))
                                    }}
                                    className="inputStyle"
                                    placeholder={"ID do QR Code"}
                                />
                            </div>
                            <div className="oneLineInputs" >
                                <InputText
                                    value={filterQrCode.descricao}
                                    onChange={(e) => {
                                        setFilterQrCode( prev => ({
                                            ...prev,
                                            descricao: e.target.value
                                        }))
                                    }}
                                    className="inputStyle"
                                    placeholder={"Descrição"}
                                />
                            </div>
                            <div className="oneLineInputs" >
                                <CustomInputMask
                                    value={filterQrCode.expiracao}
                                    onChange={(e) => {
                                        setFilterQrCode( prev => ({
                                            ...prev,
                                            expiracao: e.target.value
                                        }))
                                    }}
                                    className="inputStyle"
                                    placeholder={"Expiração"}
                                    mask={'99/99/9999'}
                                    //@ts-ignore
                                    maskChar={null}
                                />
                            </div>

                            <OutlinedButton
                                type="submit"
                                style={{alignSelf: 'center'}}
                                show={showBuscarModal}
                                onClick={e => {
                                    e.preventDefault();
                                    setShowBuscarModal(false);
                                    if(pagina.current !== 1){
                                        setPagina(prev => ({...prev, current: 1}));
                                    } else {
                                        handleMudarPagina();
                                    }
                                }}
                            >
                                Buscar
                            </OutlinedButton>
                        </form>
                        :
                        <form>
                            <div>
                            <div className="limparFiltroButtonContainer" >
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if(contaId === null){
                                            setFilterTransacoes({
                                                id: '',
                                                created_at_between_start: '',
                                                created_at_between_end: '',
                                                expired_date_between_start: '',
                                                expired_date_between_end: '',
                                                value_between_start: '',
                                                value_between_end: '',
                                                nsu_transaction: '',
                                                email: '',
                                                name: '',
                                                ddd_phone: '',
                                                cpf: '',
                                                agency_code: '',
                                                status: '',
                                                conta_id: ''
                                            });
                                        } else{
                                            //@ts-ignore
                                            setFilterTransacoes({
                                                id: '',
                                                created_at_between_start: '',
                                                created_at_between_end: '',
                                                expired_date_between_start: '',
                                                expired_date_between_end: '',
                                                value_between_start: '',
                                                value_between_end: '',
                                                nsu_transaction: '',
                                                email: '',
                                                name: '',
                                                ddd_phone: '',
                                                cpf: '',
                                                agency_code: '',
                                                status: '',
                                                // conta_id: ''
                                            });
                                        }
                                    }}
                                >
                                    Limpar filtros
                                </button>
                            </div>
                            <h3>Filtar por data de criação</h3>
                            <div className="oneLineInputs" >
                                <h4>de</h4>
                                <CustomInputMask
                                    value={filterTransacoes.created_at_between_start}
                                    onChange={(e) => {
                                        setFilterTransacoes( prev => ({
                                            ...prev,
                                            created_at_between_start: e.target.value
                                        }))
                                    }}
                                    className="inputStyle margin-right"
                                    placeholder={"inicio"}
                                    mask={"99/99/9999"}                          
                                    // @ts-ignore
                                    maskChar={null}
                                />
                                <h4>a</h4>
                                <CustomInputMask
                                    value={filterTransacoes.created_at_between_end}
                                    onChange={(e) => {
                                        setFilterTransacoes( prev => ({
                                            ...prev,
                                            created_at_between_end: e.target.value
                                        }))
                                    }}
                                    className="inputStyle"
                                    placeholder={"fim"}
                                    mask={"99/99/9999"}                            
                                    // @ts-ignore
                                    maskChar={null}
                                />
                            </div>
                            </div>

                            <div>
                            <h3>Filtar por data de expiração</h3>
                            <div className="oneLineInputs" >
                            <h4>de</h4>
                            <CustomInputMask
                                value={filterTransacoes.expired_date_between_start}
                                onChange={(e) => {
                                    setFilterTransacoes( prev => ({
                                        ...prev,
                                        expired_date_between_start: e.target.value
                                    }))
                                }}
                                className="inputStyle margin-right"
                                placeholder={"inicio"}
                                mask={"99/99/9999"}                            
                                // @ts-ignore
                                maskChar={null}
                            />
                            <h4>a</h4>
                            <CustomInputMask
                                value={filterTransacoes.expired_date_between_end}
                                onChange={(e) => {
                                    setFilterTransacoes( prev => ({
                                        ...prev,
                                        expired_date_between_end: e.target.value
                                    }))
                                }}
                                className="inputStyle"
                                placeholder={"fim"}
                                mask={"99/99/9999"}                            
                                // @ts-ignore
                                maskChar={null}
                            />
                            </div>
                            </div>

                            <div>
                            <h3>Filtar por valor</h3>
                            <div className="oneLineInputs" >
                                <h4>de</h4>
                            <CustomCurrencyInput
                                intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                prefix='R$'
                                allowNegativeValue={false}
                                decimalSeparator=","
                                groupSeparator="."
                                decimalScale={2}
                                maxLength={10}
                                value={filterTransacoes.value_between_start}
                                onValueChange={(value) => 
                                    setFilterTransacoes(
                                    // @ts-ignore
                                    prev => ({...prev, value_between_start: value })
                                    )
                                }
                                className="inputStyle margin-right"
                                placeholder="R$ 0.00"
                            />
                            <h4>a</h4>                        
                            <CustomCurrencyInput
                                intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                prefix='R$'
                                allowNegativeValue={false}
                                decimalSeparator=","
                                groupSeparator="."
                                decimalScale={2}
                                maxLength={10}
                                value={filterTransacoes.value_between_end}
                                onValueChange={(value) => 
                                    setFilterTransacoes(
                                    // @ts-ignore
                                    prev => ({...prev, value_between_end: value})
                                    )
                                }
                                className="inputStyle"
                                placeholder="R$ 0.00"
                            />
                            </div>
                            </div>
                        
                            <h3>Filtar por dados</h3>
                            {/* <InputText
                                value={filterTransacoes.name}
                                onChange={(e) => {
                                    setFilterTransacoes( prev => ({
                                        ...prev,
                                        name: e.target.value
                                    }))
                                }}
                                className="inputStyle margin-right"
                                placeholder={"Nome"}
                            /> */}

                            {/* <InputText
                                value={filterTransacoes.email}
                                onChange={(e) => {
                                    setFilterTransacoes( prev => ({
                                        ...prev,
                                        email: e.target.value
                                    }))
                                }}
                                className="inputStyle"
                                placeholder={"Email"}
                            /> */}
                            
                            <div className="oneLineInputs" >
                                <InputText
                                    value={filterTransacoes.conta_id}
                                    onChange={(e) => {
                                        setFilterTransacoes( prev => ({
                                            ...prev,
                                            conta_id: e.target.value
                                        }))
                                    }}
                                    className="inputStyle"
                                    placeholder={"Conta ID"}
                                />
                            </div>
                            <div className="oneLineInputs" >
                                <InputText
                                    value={filterTransacoes.id}
                                    onChange={(e) => {
                                        setFilterTransacoes( prev => ({
                                            ...prev,
                                            id: e.target.value
                                        }))
                                    }}
                                    className="inputStyle"
                                    placeholder={"ID"}
                                />
                            </div>
                            <div className="oneLineInputs" >
                            {/* <CustomInputMask
                                value={filterTransacoes.ddd_phone}
                                onChange={(e) => {
                                    setFilterTransacoes( prev => ({
                                        ...prev,
                                        ddd_phone: e.target.value
                                    }))
                                }}
                                className="inputStyle margin-right"
                                placeholder={"Telefone"}
                                mask={'(99) 9999-99999'}
                                // @ts-ignore
                                maskChar={null}
                            />    */}
                            
                            <CustomInputMask
                                value={filterTransacoes.cpf}
                                onChange={(e) => {
                                    setFilterTransacoes( prev => ({
                                        ...prev,
                                        cpf: e.target.value
                                    }))
                                }}
                                className="inputStyle"
                                placeholder={"CPF"}
                                mask={'999.999.999-99'}
                                // @ts-ignore
                                maskChar={null}
                            /> 
                            </div>
                            <InputText
                                value={filterTransacoes.nsu_transaction}
                                onChange={(e) => {
                                    setFilterTransacoes( prev => ({
                                        ...prev,
                                        nsu_transaction: e.target.value
                                    }))
                                }}
                                className="inputStyle"
                                placeholder={"NSU"}
                            /> 
                            
                            <div className="oneLineInputs" >                        
                            <InputText
                                value={filterTransacoes.agency_code}
                                onChange={(e) => {
                                    setFilterTransacoes( prev => ({
                                        ...prev,
                                        agency_code: e.target.value
                                    }))
                                }}
                                className="inputStyle margin-right"
                                placeholder={"Unidade"}
                            />   

                            <StatusButton show={showSetStausModal} >
                                <button
                                    type="button"
                                    className="mainButton"                               
                                    onClick={e => {
                                        e.preventDefault();
                                        // handleSetStatusSearch('0');
                                        setShowSetStausModal(true);
                                    }}
                                >
                                    {filterTransacoes.status === '' ? 'Status' : parseStatus(parseInt(filterTransacoes.status))}
                                </button>
                                <div ref={ref} >
                                    <button
                                        type="button"
                                        className="optionsButton"                               
                                        onClick={e => {
                                            e.preventDefault();
                                            handleSetStatusSearch('0');
                                            setShowSetStausModal(false);
                                        }}
                                    >
                                        {parseStatus(0)}                            
                                    </button>
                                    <button
                                        type="button"
                                        className="optionsButton"                               
                                        onClick={e => {
                                            e.preventDefault();
                                            handleSetStatusSearch('1');
                                            setShowSetStausModal(false);
                                        }}
                                    >
                                        {parseStatus(1)}                            
                                    </button>
                                    <button
                                        type="button"
                                        className="optionsButton"                               
                                        onClick={e => {
                                            e.preventDefault();
                                            handleSetStatusSearch('2');
                                            setShowSetStausModal(false);
                                        }}
                                    >
                                        {parseStatus(2)}                            
                                    </button>
                                    <button
                                        type="button"
                                        className="optionsButton"                               
                                        onClick={e => {
                                            e.preventDefault();
                                            handleSetStatusSearch('3');
                                            setShowSetStausModal(false);
                                        }}
                                    >
                                        {parseStatus(3)}                            
                                    </button>
                                    <button
                                        type="button"
                                        className="optionsButton"                               
                                        onClick={e => {
                                            e.preventDefault();
                                            handleSetStatusSearch('4');
                                            setShowSetStausModal(false);
                                        }}
                                    >
                                        {parseStatus(4)}                            
                                    </button>
                                    <button
                                        type="button"
                                        className="optionsButton"                               
                                        onClick={e => {
                                            e.preventDefault();
                                            handleSetStatusSearch('5');
                                            setShowSetStausModal(false);
                                        }}
                                    >
                                        {parseStatus(5)}                            
                                    </button>
                                    <button
                                        type="button"
                                        className="optionsButton"                               
                                        onClick={e => {
                                            e.preventDefault();
                                            handleSetStatusSearch('6');
                                            setShowSetStausModal(false);
                                        }}
                                    >
                                        {parseStatus(6)}                            
                                    </button>
                                    <button
                                        type="button"
                                        className="optionsButton"                               
                                        onClick={e => {
                                            e.preventDefault();
                                            handleSetStatusSearch('7');
                                            setShowSetStausModal(false);
                                        }}
                                    >
                                        {parseStatus(7)}                            
                                    </button>
                                    <button
                                        type="button"
                                        className="optionsButton"                               
                                        onClick={e => {
                                            e.preventDefault();
                                            handleSetStatusSearch('8');
                                            setShowSetStausModal(false);
                                        }}
                                    >
                                        {parseStatus(8)}                            
                                    </button>
                                </div>
                            </StatusButton>
                            </div>

                            <OutlinedButton
                                type="submit"
                                style={{alignSelf: 'center'}}
                                show={showBuscarModal}
                                onClick={e => {
                                    e.preventDefault();
                                    setShowBuscarModal(false);
                                    if(pagina.current !== 1){
                                        setPagina(prev => ({...prev, current: 1}));
                                    } else {
                                        handleMudarPagina();
                                    }
                                }}
                            >
                                Buscar
                            </OutlinedButton>
                        </form>
                    }
            </FullScreenModal>

        <QrCodeLinkModal />
        <DeletarQrCodeModal />
        
        <Loading isLoading={loading} />
        </Container>
    )
}
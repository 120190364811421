import styled, { keyframes } from "styled-components"

const fadeIn = keyframes`
  from {
    transform: translateX(-80px);
    opacity: 0.3;
  }

  to {
    transform: translateX(0px);
    opacity: 1;
  }
`;

export const Container = styled.section`
	display: flex;
    background-image: linear-gradient(290deg , #F15A22 0%,  #F58220 75%);
    text-align: center;

    @media only screen and (max-width: 900px) {
        flex-direction: column-reverse;
    }

    .rightContent {
		display: flex;
		flex-direction: column;
        justify-content: center;
		align-items: center;
		width: 55%;
		height: 100vh;
        padding: 80px;

		@media only screen and (max-width: 900px) {
			width: 100vw;
			height: 100vh;
            padding: 80px 20px 80px 20px;

		}
	}

    .leftContent {
		display: flex;
		flex-direction: column;
        justify-content: center;
		align-items: center;
		width: 45%;
		height: 100vh;
        padding: 80px 20px 80px 20px;

		@media only screen and (max-width: 900px) {
			width: 100vw;
			height: 100vh;
			padding: 0px;
		}
    }


    img{
        height: 152px;
        margin-bottom: 30px;
    }

    .title{
        color: var(--white);
        font-size: 2,5rem;
        margin-bottom: 30px;
        
    }

    .text{
        color: var(--white);
        font-size:  2rem;
        margin-bottom: 10px;

    }

    .cardText{
        color: var(--roxo-700);
        font-size:  2rem;
        margin-bottom: 30px;

    }

    .submitButton{
        flex: 1;
        justify-content: center;
        margin-top: 60px;
        width: 100%;
    }

    .avatar{
        background-color: var(--roxo-700);
        width: 40px;
        height: 40px;
        border-radius: 40px;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon{
            color: var(--white);
        }
    }
     .inputStyle{
        margin-bottom: 30px;
    }

    
`

export const Card = styled.div`
    background-color: var(--background);
    display: flex;
    flex-direction: column;
    min-height: 400px;
    justify-content: center;
    align-items: center;
    padding: 40px;
    border-radius: 27px;

    animation: ${fadeIn} 0.5s linear;


    @media only screen and (max-width: 900px) {
        width: 100%;
    }
`
import styled, { keyframes } from "styled-components";

interface PagarButtonProps {
    isSelected: boolean,
}

interface QrCodeButtonProps {
    show: boolean,
}

interface StatusButtonProps {
    show: boolean,
}

const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(.25);
    opacity: 0;
  }
`;

export const Container = styled.main`
    
    .pagarButtonContainer{
        display: flex;
        justify-content: space-evenly;
        position: relative;
    }

    .title{
        font-size: 2rem;
        font-weight: bold;
        color: var(--roxo-700);

    }

    .bar{
        width: 100%;
        align-self: center;
        border-top: 1px solid var(--roxo-700);
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .searchBar{
        display: flex;
        justify-content: space-between;
    }

    .QrCodeLinkModalButtonsContainer{
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        form{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            
            p{
                white-space: nowrap;
                margin-right: 15px;
            }
            
            .QrCodeLinkModalEnviarButtonsContainer{
                width: 140px;
                display: flex;
                justify-content: space-between;
                margin-left: 15px;
                
            }
        }

        @media (max-width: 1300px) {
            width: 80%;
            flex-direction: column;
            align-self: center;
            gap: 10px;

            form{
                flex-direction: column;

                .QrCodeLinkModalEnviarButtonsContainer{
                    margin-left: 0px;
                    width: 100%;

                }
            }

        }
    }
    
`

export const PagarButton = styled.button<PagarButtonProps>`
    background-color: transparent;
    border: none;
    color: var(--roxo-700);
    font-size: 1.25rem;
    padding-bottom: 5px;

    font-weight: ${props => props.isSelected ? 'bold' : 'normal' };
    border-bottom: ${props => props.isSelected ? "4px solid": 'none' };
`

export const Table = styled.main`  
    margin-top: 30px;
    width: 100%;

    .tabelaContainer{
        width: 100%;

        table{
            width: 100%;
            border: none;

            thead{
                border: none;
                tr{
                    border: none;
                    td{
                        border: none;
                        font-size: 1rem;
                        font-weight: bold;
                        padding-bottom: 26px;
                        color: var(--thead-color);
                    }
                }
            }
            tbody{
                border: none;
                tr{
                    border: none;
                    td{
                        border: none;
                        padding: 18px 0;
                        color: var(--tbody-color);
                    }

                    &:hover{
                        transition: 0.2s;
                        background-color: var(--table-hover);
                    }
                }                
            }

            .centerColumn{
                text-align: center;
            }
            
        }
    }

    @media only screen and (max-width: 1690px) {
        height: calc(100vh - 324px);
        overflow:auto;

    }
    
    @media only screen and (max-width: 900px) {
        height: 100%;
    }

`

export const QrCodeButton = styled.button<QrCodeButtonProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 118px;
    height: 118px;
    border: none;
    background-color: var(--roxo-700);
    border-radius: 16px;
    opacity: 0.8;
    color: var(--white);
    padding: 20px;
    position: absolute;
    top: 60px;
    right: 30px;
    visibility: ${props => props.show ? 'visible' : 'hidden' };
    animation: ${props => props.show ? fadeIn : fadeOut} 0.2s linear;
    transition: visibility 0.2s linear;

    &:hover{
        opacity: 1;

    }
            
        
`

export const StatusButton = styled.div<StatusButtonProps>`
    width: 100%;
    position: relative;

    .mainButton{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        padding:10px;
        padding-left: 20px;
        outline: none;
        background-color:var(--input-background);
        color: var(--roxo-700);
        font-size: 1rem;
        border-radius: 18px;
        border: 1px solid var(--roxo-700);
        font-weight: normal;
        border: none;
        color: var(--input-text-color);

        @media (max-width: 1300px) {
            height: 0.8rem;
            padding: 1.33rem;
        }
    }

    div{
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 360px;
        padding: 10px;
        padding-left: 20px;
        outline: none;
        background-color: #F0EFF9;
        color: var(--roxo-700);
        font-size: 1rem;
        border-radius: 18px;
        border: 1px solid var(--roxo-700);
        font-weight: normal;
        border: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        animation: ${props => props.show ? fadeIn : fadeOut} 0.2s linear;
        transition: visibility 0.2s linear;
        visibility: ${props => props.show ? 'visible' : 'hidden' };

        .optionsButton{
            border: none;
            background-color: transparent;

            margin: 8px 0px;
        }
    }

`
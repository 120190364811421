import { Dispatch, ReactNode, SetStateAction, useEffect, useRef } from "react";
import { IoCloseCircle } from "react-icons/io5";
import { Modal } from "./styles";

interface FullScreenModalProps{
  show: boolean,
  setShow: Dispatch<SetStateAction<boolean>>,
  children: ReactNode
}

export function FullScreenModal({show, setShow, children, ...rest} : FullScreenModalProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {        
    const handleCloseModalOnClick = (event: MouseEvent): void => {
        if(show){
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setShow(false);
            }
        }
    }

    document.addEventListener('mousedown', handleCloseModalOnClick);
    return () => {
        document.removeEventListener('mousedown', handleCloseModalOnClick);
    };
  });

  return(
    <Modal show={show}>
      <div className="modalContainer" ref={ref}>
        <div style={{alignSelf: 'flex-end'}} >
          <IoCloseCircle size={38} onClick={() => setShow(false)} />
        </div>
        {children}
      </div>
    </Modal>
  )
}
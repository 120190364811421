import { AreaInput } from "./styles";

export function InputTextArea({ ...props }: React.InputHTMLAttributes<HTMLTextAreaElement>) {

  return (
      <AreaInput
          {...props}
      />
  )    

}
import styled from 'styled-components';

export const Container = styled.aside`
  
  height: 100vh;
  background: linear-gradient(290deg ,#F15A22 0%,  #F58220 75%);
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  width: 350px;
  position: fixed;
  z-index: 100;
  
  
  .menuItemWrapper{
    padding-left: 20px;
    height: 73%;
    display: flex;
    flex-direction: column;
  }

  img{
      display: block;
      height: 105px;
      margin: 72px auto 78px auto;
  }
`;

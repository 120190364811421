import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { Container } from "./styles";

import ImgCadastro from '../../../assets/imagens/cadastroSVG.svg';
import LogoLaranja from '../../../assets/imagens/logoLaranja.png';
import { SubmitButton } from "../../../components/form/SubmitButton";
import { postContaVendedorNovaVidaAction } from "../../../actions";
import { useState } from "react";
import { Loading } from "../../../components/Loading/Loading";

export function CadastroPre() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params: any = useParams();
    const [loading, setLoading] = useState(false);

    async function handleCadastro() {
        if(!params.id){
            toast.warning('Você precisa solicitar um link para poder se cadastrar');
            return
        }

        try {
            setLoading(true);
            const {success} = await dispatch(postContaVendedorNovaVidaAction(params.id) as any);
            if(success){
                setLoading(false);
                navigate(`/cadastro/CadastroEtapa1?[id]=${params.id}`, );
            } else {
                setLoading(false);
                toast.error('Não foi possível encontrar seus dados cadastrais');

            }
        } catch (err) {
            toast.error('Não foi possível alterar essa Notícia');
            console.log(err);
            setLoading(false);            
        }
    }

    return (
        <Container>
            <div className="box1">
                <img src={ImgCadastro} alt="logo" />

            </div>
            <div className="box2">
                <div className="subcontainer">
                    <img className="logo" src={LogoLaranja} alt="logo" />
                    <h1>
                        Seja bem vindo, vamos iniciar
                        {`\n`} seu cadastro?
                    </h1>
                    <Link to={""}>
                        Leia a nossa política de privacidade.
                    </Link>
                    <SubmitButton
                        text="Continuar"
                        color='azul'
                        outline
                        onClick={handleCadastro}
                    />
                </div>
            </div>
            <Loading isLoading={loading} />
        </Container>
    )
}
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import { postCriarContaVendedorProps } from "../../../services/models";
import { useCadastro } from "../Cadastro";
import { Container } from "./styles";

import { CustomInputMask } from "../../../components/form/CustomInputMask";
import { InputText } from "../../../components/form/InputText";
import { SubmitButton } from "../../../components/form/SubmitButton";
import { HeaderCadastro } from "../../../components/HeaderCadastro";

export function CadastroEtapa3() {
    const navigate = useNavigate();
    const { handleStep, handleStepBack, dadosParceiro, setDadosParceiro } = useCadastro();

    function handleNavigate(e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) {
        e?.preventDefault()
        if(dadosParceiro.conta.celular.length !== 15){
            toast.warning('Preencha o celular de contato para continuar.')
            return
        }
        if(dadosParceiro.email === ''){
            toast.warning('Preencha o e-mail de contato para continuar.')
            return
        }

        handleStep();
        navigate('/cadastro/cadastroEtapa4');
    }
    return (
        <Container>
            <HeaderCadastro callback={handleStepBack}/>

            <form onSubmit={handleNavigate} >
                <label className="inputLabel">Nome do administrador</label>
                <InputText
                    type={"text"}
                    placeholder="Nome do administrador"
                    value={dadosParceiro.conta.nome}
                    onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                        ...prev,
                        conta: {
                            ...prev.conta,
                            nome: e.target.value
                        }
                    })
                    )}
                    disabled
                    style={{backgroundColor: 'transparent'}}
                />
                <label className="inputLabel">Celular</label>
                <CustomInputMask
                    type={"tel"}
                    placeholder="Celular"
                    value={dadosParceiro.conta.celular}
                    onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                        ...prev,
                        conta: {
                            ...prev.conta,
                            celular: e.target.value
                        }
                    })
                    )}
                    mask="(99) 99999-9999"
                    // @ts-ignore
                    maskChar={null}
                />
                <label className="inputLabel">E-mail</label>
                <InputText
                    type={"email"}
                    placeholder="E-mail"
                    value={dadosParceiro.email}
                    onChange={e => setDadosParceiro((prev: postCriarContaVendedorProps) => ({
                        ...prev,
                        email: e.target.value
                    })
                    )}
                />
                {/* <label className="inputLabel">CPF</label>
                <CustomInputMask
                    mask="999.999.999-99"
                    placeholder="CPF"
                /> */}

                <SubmitButton
                    text="Continuar"
                    color='azul'
                    outline
                    onClick={handleNavigate}
                />


            </form>
        </Container>
    )
}
import styled from "styled-components";

export const Container = styled.div`

    display: flex;
    flex-direction:column ;

   
    form{
        margin-top: 50px;
        width: 100%;
        max-width: 500px;
        padding-inline: 10px;
        margin-inline: auto;
        display: flex;
        flex-direction: column;

        .inputLabel{
            padding-top: 19px;
            padding-bottom: 13px;
            padding-left: 10px;
            display:inline-block;
            font-size: 1rem;
            font-weight: 600;
            
        }

        button{
            align-self: center;
            margin-top: 80px;
            margin-bottom: 30px;
        }
    }
`
import styled from "styled-components";

export const Container = styled.section`
    
    .headerTabela{
    
    padding: 36px 64px 0px 64px;

        .nomePagina{
            font-size: 2rem;
        }

    }

    main{
        padding:  24px 64px;
        margin: 0 auto;

        .tabelaContainer{
            width: 100%;
            background-color: var(--background);
            padding: 30px;
            border-radius: 30px;
            overflow: auto;

            .imagesContainer{
               display: flex;
               
               .secondBlock{
                   display: flex;
                   flex-direction: column;
                   justify-content: space-between;
                   margin-left: 10px;
                }

            }
        }

        @media only screen and (max-width: 900px) {
            padding:  24px 0 0 0;
        }
    }

`
import styled from "styled-components";
import ReactCodeInput from 'react-code-input';

export const InputSms = styled(ReactCodeInput).attrs({
    inputStyle: {
        margin: '20px',
        width: '40px',
        borderRadius: '20px',
        fontSize: '3rem',
        height: '59px',
        textAlign: 'center' as const,
        backgroundColor: 'transparent',
        color: 'var(--roxo-700)',
        border: '1px solid var(--roxo-700)'
    }
})`
    @media (max-width: 900px){
        input{
            margin: 10px!important;
        
        }   
    }
`

export const Container = styled.div`

    display: flex;
    flex-direction:column ;
    align-items: center;
    .campos{
        justify-content:center ;
        display:flex;
        margin-top: 80px;
    }
    h1{
        padding: 0 20px ;
        font-size: 2rem;
        align-self: center;
        font-weight: 500;
        align-self: center;
        margin-bottom: 20px;
        span{
            color: var(--laranja-500);
        }
    }

    h3{
        padding-inline: 10px;
        font-size: 0.875rem;
        font-weight: 500;
        margin-bottom: 36px;
        text-align: center;
    }

    .reenviarToken{
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        h2{
            color: var(--azul-300)
        }
        
        .phoneIcon{
            fill: var(--azul-300)
        }
    }
   
    form{
        width: 100%;
        padding-inline: 10px;
        margin-inline: auto;
        display: flex;
        flex-direction: column;

        button{
            align-self: center;
            margin-top: 80px;
            margin-bottom: 30px;
        }
    }
`
import styled, { css } from 'styled-components';


interface StepPropsProps {
    active: boolean
}

export const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    max-width: 700px;
    height: 100px;
    
    position: relative;
    display: flex;
    align-items: center;
    flex-direction:row;
   
    .wrapper{
        position:relative;
        width: calc(100% / 6);
    }
    
`;

export const Wrapper = styled.div<StepPropsProps>`
    
    position:relative;
    width: calc(100% / 6);
    height: 60px;
    ${({ active }) => active ? css`
       top: -7px;
    `: css`
        
    `
    }
`

export const Step = styled.div<StepPropsProps>`
    background-color: white;
    border-radius: 50%;
    
    ${({ active }) => active ? css`
        
        width: 33px;
        height: 33px;
    `: css`
        transition: 1;
        width: 18px;
        height: 18px;
    `
    }

`

export const Label = styled.label<StepPropsProps>`
    color:white;
    font-size: 0.875rem;
    display: block;
    margin-top: 15px;
    ${({ active }) => active ? css`
        font-weight: 600;
        margin-top: 7px;
    `: css`
        font-weight: 500;
        
    `
    }

`

export const Line = styled.div<StepPropsProps>`
    position: absolute;
    width: 100%;
    background-color:white;
    height: 3px;
    ${({ active }) => active ? css`
        top: 15px;
    `: css`
        top: 7px;
    `
    }

`

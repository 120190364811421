import styled from "styled-components";


export const Input = styled.input`
  
        width: 100%;
        height: 40px;
        padding:10px;
        padding-left: 20px;
        outline: none;
        background-color:var(--input-background);
        color: var(--roxo-700);
        font-size: 1rem;
        border-radius: 18px;
        font-weight: 300;
        border: 1px solid var(--roxo-700);
        


        &::placeholder{
            color: var(--input-text-color);
        }
    
`
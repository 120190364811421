import { IndexType } from 'typescript';
import { Button } from './styled';

interface SubmitButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    loading?: boolean;
    color?: "branco" | "azul" | "laranja" | "roxo";
    outline?: boolean
}
const corBackground = {
    branco: "#fff",
    azul: "#07B7E6",
    laranja: "#F58220",
    roxo: "#340950",
}

const corTexto = {
    branco: "#340950",
    azul: "#fff",
    laranja: "#fff",
    roxo: "#fff",
}

export function SubmitButton({ text, color = "branco", outline = false, loading = false, ...rest }: SubmitButtonProps) {

    return (
        <Button
            {...rest}
            color={corBackground[color]}
            colorText={corTexto[color]}
            outline={outline}
        >
            {
                loading ? (
                    <svg>
                        <circle cx="12" cy="12" r="12"></circle>
                    </svg>
                ) : (
                    text
                )
            }
        </Button>
    )
}
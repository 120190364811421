
import { Props } from 'react-input-mask';

import { Input } from './styles';

interface CustomInputMaskProps extends Props {

}

export function CustomInputMask({ ...rest }: CustomInputMaskProps) {
    return (
        <Input
            {...rest}
        />
    )
}
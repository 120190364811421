import { Container } from "./styles";
import { FaArrowLeft } from 'react-icons/fa';

interface BackButtonProps {
    func: () => void;
}
export function BackButton({ func }: BackButtonProps) {

    return (
        <Container
            onClick={func}
        >
            <FaArrowLeft
                size={30}
            />
        </Container>
    )
}
import styled from "styled-components"

export const Container = styled.section`
    min-height: 100vh;
    width: 100%;
    display:flex ;
    .box1, .box2{
        min-height: 100vh;
        width: 50%;
      }
    .box1{
        background-image: linear-gradient(290deg ,#F15A22 0%,  #F58220 75%);
        display:flex;
        flex-direction:column;
        align-items: center;
        padding:80px 20px 20px 20px;
        position: relative;
        
        .etapas{
            width: 100%;
        }
        img{
            max-height: 300px;
            right: 0;
            bottom: 0;
            position: absolute;

        }
        h1{
            color: var(--white);
            font-size: 2.5rem;
        }
    }

    @media only screen and (max-width: 900px) {
      .box1{
          display: none;
      }
      .box2{
          width:100% ;
      }
  }

`

import { Card, Container } from "./styles";
import Logo from '../../assets/imagens/logo.png';
import { LoginInputs } from "../../components/form/LoginInputs";
import { SubmitButton } from "../../components/form/SubmitButton";
import { IoIosPerson } from "react-icons/io";
import { useState } from "react";
import { postUserSolicitarNovaSenha } from "../../services/service";
import { toast } from "react-toastify";

export function RecuperarSenha() {
	const [email, setEmail] = useState("");

	const handleSolicitarSenha = async () => {
		try {
			const res = await postUserSolicitarNovaSenha(email);
			console.log(res);
			toast.success("E-mail de recuperação de senha enviado!");
		} catch (error) {
			console.log(error);
			toast.error("Erro ao enviar email, verifique os dados e tente novamente");
		}
	}

	return (
		<Container>
			<div className="leftContent">
				<Card>
					<div className="avatar">
						<IoIosPerson
							className="icon"
							size={24}
						/>
					</div>
					<h2 className="cardText" >Esqueceu sua senha?</h2>
					<form >
						<LoginInputs
							placeholder="Digite seu email*"
							type={"email"}
							required
							noIcon
							onChange={(e) => setEmail(e.target.value)}
							color="#340950"
						/>

						<SubmitButton
							type="button"
							text="Solicitar redefinição de senha"
							color="roxo"
							className="submitButton"
							onClick={handleSolicitarSenha}
						/>
					</form>
				</Card>
			</div>

			<div className="rightContent">
				<img src={Logo} alt="logo-jeitto" />

				<h1 className="title" >Esqueceu sua senha?</h1>
				<h2 className="text" >Entendemos, essas coisas acontecem.</h2>
				<h2 className="text" >Basta digitar seu endereço de e-mail abaixo e enviaremos um link para redefinir sua senha!</h2>

			</div>
		</Container>
	)
}
import styled from "styled-components";

interface ButtonProps {
    color: string,
    colorText: string,
    outline: boolean,
}

export const Button = styled.button<ButtonProps>`

    height:3.33em;
    width: 14em;
    font-size: 1.25rem;
    color: ${({ outline, colorText, color }) => outline ? color : colorText};
    background: ${({ outline, color }) => outline ? 'transparent' : color};
    outline: ${({ outline, color }) => outline ? `1px solid ${color}` : 'none'};
    border-width: 0;
    border-radius: 40px;
    position:  relative;
    text-transform: uppercase;
    font-weight: bold;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    &:hover{
        transition: 0.2s;
        opacity: 0.9;        
    }
    &:active{
        transition: 0.2s;
        top: 2px;
    }

    svg{
        position: relative;
        width: 34px;
        height: 34px;
        animation: rotate 2s linear infinite;
        
        @keyframes rotate {
                0%{
                    transform: rotate(0deg);
                }
                100%{
                    transform: rotate(360deg);
                } 
            }
        circle{
            width: 100%;
            height: 100%;
            fill: none;
            stroke-width: 4;
            stroke: var(--azul-300);
            stroke-linecap: round;
            transform: translate(5px ,5px);
            stroke-dasharray: 100;
            stroke-dashoffset: 100;
            animation: animate 2s linear infinite;

            @keyframes animate {
                0%,100%{
                    stroke-dashoffset: 100;
                }
                50%{
                    stroke-dashoffset: 0;
                }
                50.1%{
                    stroke-dashoffset: 190;
                }
            }
        }
    }
`
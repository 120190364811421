import { newBlogPostProps } from "../services/models";

const keyName = 'PRE_VISUALIZAR_POST';

const usePreviewPost = () => {
  const createPreview = (post: newBlogPostProps) => {
    window.localStorage.setItem(keyName, JSON.stringify(post));
  }

  const deletePreview = () => {
    window.localStorage.removeItem(keyName);
  }

  const preview: newBlogPostProps = JSON.parse(window.localStorage.getItem(keyName)!);
  
  return {preview, createPreview, deletePreview }
}

export default usePreviewPost;
import styled from "styled-components"

export const Container = styled.section`
    min-height: 100vh;
    width: 100%;
    display:flex ;
    .box1, .box2{
        min-height : 100vh ;
        width: 50%;
      }
    .box1{
        background-image: linear-gradient(290deg ,#F15A22 0%,  #F58220 75%);
        display:flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;
        padding:20px 20px 20px 20px;
        
        img{
            max-width: 680px;
            width: 100%;
        }
    }
    .box2{
        display: flex;
        align-items: center;
        justify-content: center;
    
        .subcontainer{
            width: 100%;
            max-width: 520px;
            display: flex;
            flex-direction: column;
            text-align:  center;
            margin: 0 auto;
            padding: 10px;
            h1{
                font-weight:600;
                font-size: 2rem;
                text-align:center;
                margin-bottom: 10px;
            }
            h3{
                font-size: 0,9rem;
            }

            .logo{
                max-width: 300px;
                align-self: center;
                margin-bottom: 50px;
            }
        }
        button{
                align-self: center;
                margin-top: 240px;
        }
    }
    @media only screen and (max-width: 900px) {
        .box1{
            display:none ;
        }
        .box2{
            width:100%;
        }
    }
`


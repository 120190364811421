import { useState } from "react";
import { IoIosLock, IoMdEyeOff, IoMdEye, IoIosPerson } from 'react-icons/io';

import { Container } from './styles';

interface LoginInputsProps extends React.InputHTMLAttributes<HTMLInputElement> {
    password?: boolean
    noIcon?: boolean
    color?: string,
    containerClassName?: string
}
export function LoginInputs({
    password = false,
    noIcon = false,
    color = '#FFF',
    containerClassName = '',
    ...rest
}: LoginInputsProps) {
    const [isHidden, setIsHidden] = useState(true);

    function toggleInpuType() {
        setIsHidden(!isHidden)
    }

    if (password) {
        return (
            <Container className={containerClassName} color={color} >
                <IoIosLock
                    className="right-icon"
                    size={24}
                />
                <input
                    type={isHidden ? "password" : "text"}
                    {...rest}
                />
                {
                    isHidden ? (
                        <IoMdEye
                            onClick={toggleInpuType}
                            className="left-icon"
                            size={24}
                        />
                    ) : (
                        <IoMdEyeOff
                            onClick={toggleInpuType}
                            className="left-icon"
                            size={24}
                        />
                    )
                }

            </Container>
        )
    } else {
        return (
            <Container className={containerClassName} color={color} >
                {!noIcon && <IoIosPerson
                    className="right-icon"
                    size={24}
                />}
                <input
                    type={"text"}
                    {...rest}
                />
            </Container>
        )
    }
} 
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import {BsLink45Deg} from "react-icons/bs"

import useAuth from "../../../hooks/useAuth";
import { blogPostProps, newBlogPostProps } from "../../../services/models";
import { deleteBlogPost, getBlogCategorias, getBlogCategoriasList, getBlogPost, getBlogTags, getBlogTagsList, postBlogEditarPost } from "../../../services/service";
import { Container, CopiarLinkContainer, EditarPostHeader, Form, TagContainer, TagsContainer } from "./style";

import { Loading } from "../../../components/Loading/Loading";
import { TiDelete } from "react-icons/ti";
import { MdEdit } from "react-icons/md";
import { FullScreenModal } from "../../../components/FullScreenModal";
import { OutlinedButton } from "../../../components/OutlinedButton";
import { toast } from "react-toastify";
import { InputText } from "../../../components/form/InputText";
import { InputTextArea } from "../../../components/form/InputTextArea";
import PageNavigation from "../../../components/PageNavigation";
import { QuillTextEditor } from "../../../components/QuillTextEditor";

const Tags = ({tags = [{tag: ''}]}) => {
  const navigate = useNavigate();

  return (
    <TagContainer>{
      tags.map((obj, index) => 
        <p
          key={index}
          onClick={() => navigate(`/blog?filter[tags]=${obj.tag}`)}
        >
          {obj.tag}
        </p>
      )
    }</TagContainer>
  )
}

const CopiarLinkButton = () => {
  const location = useLocation();

  const handleCopyLink = () => {
    try {
      if( navigator.clipboard !== undefined){
        navigator.clipboard.writeText(`${window.location.origin.toString()}${location.pathname}`);
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = `${window.location.origin.toString()}${location.pathname}`;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (err) {
          console.log(err);   
        }
        finally{
            document.body.removeChild(textArea);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  return(
    <CopiarLinkContainer>
      <div className="icon-link-container">
        <BsLink45Deg
          size={25}
          className='icon-link'
        />
      </div>
      <button
        onClick={handleCopyLink}
      >Copiar link</button>
    </CopiarLinkContainer>
  )
}

const DeletarPostModal = ({token, show, setShow, setLoading, post}: any) => {
  const navigate = useNavigate();

  const handleDeletarNoticia = async (id: number) => {
    setShow(false);
    setLoading(true);
    try {
      const res = await deleteBlogPost(token, id);
      if(res.status === 200){
        toast.success('Notícia deletada com sucesso');
        navigate('/blog');
      }
    } catch (err) {
      console.log(err);
      toast.error('Ocorreu um erro. Não foi possível deletar essa Notícia');
      setLoading(false);
    }
  }

  return(
    <FullScreenModal show={show} setShow={setShow} >
      <h2>Deseja deletar a notícia {post.titulo}?</h2>
      <h4 style={{marginTop: '10px', marginBottom: '25px'}}>Essa ação é irreversível</h4>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <OutlinedButton
          show={show}
          onClick={() => handleDeletarNoticia(parseInt(post.id))}             
          >
          DELETAR NOTÍCIA
        </OutlinedButton>
      </div>
    </FullScreenModal>
  )
}

const EditarPost = ({token, post, setLoading}: any) => {
  interface extededProps extends newBlogPostProps {
    id: string
  }
  const navigate = useNavigate();
  const [newPost, setNewPost] = useState(post as extededProps);
  const [newPostErrors, setNewPostErrors] = useState({} as any);
  const [tags, setTags] = useState<{id: string, tag: string}[]>([]);
  const [categoria, setCategoria] = useState({} as {id: string, nome: string, descricao: string});
  const [showSelecionarTagsModal, setShowSelecionarTagsModal] = useState(false);
  const [showSelecionarCategoriaModal, setShowSelecionarCategoriaModal] = useState(false);

  const handleEditarNoticia = async (e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    setNewPostErrors({});
    setLoading(true);
    try {
      const res = await postBlogEditarPost(token, newPost.id, newPost);
      if(res.status === 200){
        toast.success('Notícia alterada com sucesso');
        setLoading(false);
        navigate(0);
        // setNewPost({
        //   titulo: '',
        //   descricao: '',
        //   conteudo: '',
        //   categoria_id: '',
        //   tags: [{id: ''}],
        //   banner: ''
        // });

      }
    } catch (err) {
      console.log(err);
      toast.error('Não foi possível alterar essa Notícia');
      //@ts-ignore
      setNewPostErrors(err.response.data.errors);
      setLoading(false);
    }
  }

  const SelecionarCategoriaModal = () => {
    const [categorias, setCategorias] = useState<{nome: string, descricao: string, id: string}[]>([]);
    const [pagina, setPagina] = useState({current: 1, last: 1});

    const handleLoadCategorias = async () => {
      // setLoading(true);
      try {
        const res = await getBlogCategorias(token, pagina.current) as any;
        setCategorias(res.data.data);
        setPagina(prev => ({...prev, last: res.data.last_page}));
        // setLoading(false);

      } catch (err) {
        console.log(err);
        // setLoading(false);
      } 
    }

    useEffect(() => {
      handleLoadCategorias();
    },[pagina.current])

    return (
      <FullScreenModal 
        show={showSelecionarCategoriaModal}
        setShow={setShowSelecionarCategoriaModal}
      >
        <h2>Selecione a categoria</h2>
        {categorias.length < 1
        ?
          <div style={{height: '200px', width: '200px', display: "flex", justifyContent: "center"}}>
            <svg className="loading">
              <circle cx="70" cy="70" r="70"></circle>
            </svg>
          </div>
        :
          <TagsContainer centered >
          {categorias.map((obj, idx) => (
            <div key={idx} className="tags"
              style={{cursor: "pointer"}}
              onClick={() => {
                setShowSelecionarCategoriaModal(false);
                setCategoria(obj);
                setNewPost(prev => ({...prev, categoria_id: obj.id}))
              }}
            >
              {obj.nome}
            </div> 
          ))}
        </TagsContainer>
        }
        <PageNavigation pagina={pagina} setPagina={setPagina} />
      </FullScreenModal>
    )
  }
  
  const SelecionarTagsModal = () => {
    const [allTags, setAllTags] = useState<{tag: string, id: string}[]>([]);
    const [choosenTags, setChoosenTags] = useState<{tag: string, id: string}[]>(tags);
    const [pagina, setPagina] = useState({current: 1, last: 1});

    const handleLoadCategorias = async () => {
      // setLoading(true);
      try {
        const res = await getBlogTags(token, pagina.current) as any;
        setAllTags(res.data.data);
        setPagina(prev => ({...prev, last: res.data.last_page}));
        // setLoading(false);

      } catch (err) {
        console.log(err);
        // setLoading(false);
      } 
    }
   
    const handleAddTag = (obj: {tag: string, id: string}) => {
      if(choosenTags.some(item => item.id === obj.id)) return;
      setChoosenTags(prev => {
        let arr = prev.slice();
        arr.push(obj);
        return arr;
      })
    }

    const handleRemoveTag = (obj: {tag: string, id: string}) => {
      setChoosenTags(prev => {
        let arr = prev.slice();
        let arr2 = arr.filter(item => item.id !== obj.id);
        return arr2;
      })
    }

    const handleAdionarTags = (e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
      e?.preventDefault();      
      const tagsId = choosenTags.map(obj => ({id: obj.id}))

      setShowSelecionarTagsModal(false);
      setTags(choosenTags);
      setNewPost(prev => ({...prev, tags: tagsId}));

    }

    useEffect(() => {
      handleLoadCategorias();
    },[pagina.current])

    return (
      <FullScreenModal 
        show={showSelecionarTagsModal}
        setShow={setShowSelecionarTagsModal}
      >
        <h2>Tags selecionadas</h2>
        <TagsContainer centered >
          {choosenTags.map((obj, idx) => (
            <div key={idx} className="tags"
              style={{cursor: "pointer"}}
              onClick={() => handleRemoveTag(obj)}
            >
              {obj.tag}
            </div> 
          ))}
        </TagsContainer>
        <h2>Selecione as Tags</h2>
        {allTags.length < 1
        ?
          <div style={{height: '200px', width: '200px', display: "flex", justifyContent: "center"}}>
            <svg className="loading">
              <circle cx="70" cy="70" r="70"></circle>
            </svg>
          </div>
        :
          <TagsContainer centered >
          {allTags.map((obj, idx) => (
            <div key={idx} className="tags"
              style={{cursor: "pointer"}}
              onClick={() => handleAddTag(obj)}
            >
              {obj.tag}
            </div> 
          ))}
        </TagsContainer>
        }
        <PageNavigation pagina={pagina} setPagina={setPagina} />
        <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
        <OutlinedButton
          show={showSelecionarTagsModal}
          onClick={handleAdionarTags}
          >
        ADICIONAR TAGS
        </OutlinedButton>
        </div>
      </FullScreenModal>
    )
  }

  useEffect(() => {
    setLoading(true);
    ((async () => {
      try {
        const res = await getBlogCategoriasList(token) as any;
        const categorias = res.data.data;
        const res2 = await getBlogTagsList(token) as any;
        const tags = res2.data.data;
       
        categorias.forEach((categoria: {id: string, nome: string, descricao: string}) => {
          if(categoria.nome === post.categoria.nome){
            setCategoria(categoria);
            setNewPost(prev => ({...prev, categoria_id: categoria.id}));
          }
        });
        let arr = [] as any;

        tags.forEach((tag: {id: string, tag: string}) => {
          //@ts-ignore
          post.tags.forEach(element => {            
            if(tag.tag === element.tag){
              arr.push(tag);
            }
          });
        });
        setTags(arr);

        setNewPost(prev => ({...prev, tags: arr.map((obj: {id: string, tag: string}) => ({id: obj.id}))}))
             
        setLoading(false);

      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    })())
  },[])

    return (
        <Form onSubmit={handleEditarNoticia}>
              <div className="inputsContainer">
                  <div className="inputWrapper w100">
                      <label className="inputLabel">TÍTULO</label>
                      <InputText
                          onChange={(e) => {
                            setNewPost( prev => ({...prev, titulo: e.target.value}))
                          }}
                          value={newPost.titulo}
                          className="inputStyle"
                          placeholder={""}
                      />
                      {newPostErrors.titulo ? <div className="inputLabelError">{newPostErrors.titulo}</div> : null}
                  </div>
                </div>
                <div className="inputsContainer">
                <div className="inputWrapper w100">
                    <label className="inputLabel">DESCRIÇÃO</label>
                    <InputTextArea
                        onChange={(e) => {
                          setNewPost( prev => ({...prev, descricao: e.target.value}))
                        }}                          
                        value={newPost.descricao}
                        className="inputStyle"
                        placeholder={""}
                        style={{minHeight: '60px', minWidth: '50%', maxWidth: '100%'}}
                    />
                    {newPostErrors.descricao ? <div className="inputLabelError">{newPostErrors.descricao}</div> : null}
                </div>
            </div>

            <div className="inputsContainer">
                  <div className="inputWrapper w50">
                      <label className="inputLabel">Capa da notícia</label>
                      <InputText
                          // value={newPost.tags}
                          onChange={(e) => {
                              setNewPost( prev => ({
                                ...prev,
                                banner: e.target.files![0]
                              }))
                          }}
                          className="inputStyle"
                          placeholder={""}
                          type='file'
                      />
                      {newPostErrors.banner ? <div className="inputLabelError ">{newPostErrors.banner}</div> : null}
                      {newPost.banner ?
                        typeof(newPost.banner) === typeof('string')
                          ? <img src={newPost.banner as any} alt="capa" />
                          : <img src={URL.createObjectURL(newPost.banner as any)} alt="capa" />
                      :null }
                  </div>
              </div>

              <div className="inputsContainer">
                  <div className="inputWrapper w50">
                      <label className="inputLabel">TAGS</label>
                      <div className="chooseContainer" style={{cursor: 'pointer'}} 
                        onClick={() => setShowSelecionarTagsModal(true)}
                      >
                        <TagsContainer centered >
                          {tags.map((obj, idx) => (
                            <div key={idx} className="tags" >
                              {obj.tag}
                            </div> 
                          ))}
                        </TagsContainer>
                      </div>
                      {newPostErrors.tags ? <div className="inputLabelError">{newPostErrors.tags}</div> : null}
                  </div>
                  <div className="inputWrapper w50">
                      <label className="inputLabel">CATEGORIA</label>
                      <div className="chooseContainer" style={{cursor: 'pointer'}} 
                        onClick={() => setShowSelecionarCategoriaModal(true)}
                      >
                        <p>{categoria.nome}</p>
                      </div>
                      {newPostErrors.categoria_id ? <div className="inputLabelError">{newPostErrors.categoria_id}</div> : null}
                  </div>
              </div>

              <div className="inputsContainer">
                <div className="inputWrapper w100">
                    <label className="inputLabel">POSTAGEM</label>
                    {/* <InputTextArea
                        value={newPost.conteudo}
                        onChange={(e) => {
                          setNewPost( prev => ({...prev, conteudo: e.target.value}))
                        }}                          
                        className="inputStyle"
                        placeholder={""}
                        style={{minHeight: '100px', minWidth: '50%', maxWidth: '100%'}}
                    /> */}
                    <QuillTextEditor
                      value={newPost.conteudo}
                      setValue={(arg: string) => setNewPost(prev => ({...prev, conteudo: arg}))}
                    />
                    {newPostErrors.conteudo ? <div className="inputLabelError">{newPostErrors.conteudo}</div> : null}
                </div>
            </div>            

              <div className="btnContainer">
                  <OutlinedButton
                  show={true}
                  onClick={handleEditarNoticia}
                  >
                  SALVAR
                  </OutlinedButton>
              </div>

              {showSelecionarCategoriaModal ? <SelecionarCategoriaModal /> : null}
              {showSelecionarTagsModal ? <SelecionarTagsModal /> : null}
          </Form>

    )
}

export function BlogPost(){
  const params: any = useParams();
  const {token, contaId} = useAuth();
  const [post, setPost] = useState({} as blogPostProps);
  const [loading, setLoading] = useState(true);
  const [showEditarPost, setShowEditarPost] = useState(false);
  const [showDeletarPostModal, setShowDeletarPostModal] = useState(false);
  const spanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await getBlogPost(token, params.id);
        setPost(res.data);
        spanRef.current!.innerHTML = res.data.conteudo;
        setLoading(false);
      } catch (err) {
        console.log(err);
        setPost({
          titulo: 'Página não encontrada!'
        } as blogPostProps);
        setLoading(false);
      }
    })()
  },[])

  return (
    <Container>
      {loading || contaId !== null || post.titulo === 'Página não encontrada!'
      ? null
      :
        <>
        <EditarPostHeader>
          <div
            onClick={() => setShowEditarPost(prev => !prev)}
          >
            <p>EDITAR NOTÍCIA</p>
            <MdEdit
              size={20}
              className='editarIcon'
              />
          </div>
          <div
            onClick={() => setShowDeletarPostModal(true)}
          >
            <p>DELETAR NOTÍCIA</p>
            <TiDelete
              size={24}
              className='editarIcon'
            />
          </div>
        </EditarPostHeader>
        <div style={{marginBottom: '15px'}} />
        </>
      }

      {showEditarPost &&
        <EditarPost
          token={token}
          setLoading={setLoading}
          post={post}
        />
      }

      <h1>{post.titulo}</h1>
      <h2>{post.descricao}</h2>
      <img src={post.banner} alt={''} />
      {loading || post.titulo === 'Página não encontrada!' 
        ? null
        : <Tags tags={post.tags} />
      }
      <div style={{marginBottom: '20px'}} />
      <span ref={spanRef} className='conteudo' />
      {loading || post.titulo === 'Página não encontrada!'
        ? null
        : <CopiarLinkButton />
      }

      <DeletarPostModal
        token={token}
        show={showDeletarPostModal}
        setShow={setShowDeletarPostModal}
        setLoading={setLoading}
        post={post}
      />
      
      <Loading isLoading={loading} />
    </Container>
  )
}
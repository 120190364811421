import styled, { keyframes } from "styled-components";

interface showProps{
    show: boolean
}

const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(.25);
    opacity: 0;
  }
`;

export const Button = styled.button<showProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 160px;
    height: 49px;
    background-color: transparent;
    color: var(--roxo-700);
    border: 2px solid var(--roxo-700);
    border-radius: 30px;

    visibility: ${props => props.show ? 'visible' : 'hidden' };
    animation: ${props => props.show ? fadeIn : fadeOut} 0.2s linear;
    transition: visibility 0.2s linear;

    &:hover {
        background-color: var(--roxo-700);
        color: var(--white);
        /* border: none; */

    }

    @media (max-width: 1300px) {
      height: 0.8rem;
      padding: 1.33rem;
    }

`
import styled from 'styled-components';

export const Container = styled.header`
    width: 100%;
    padding: 54px 71px;
    display: flex;
    justify-content:space-between;
    position:relative;
    img{
        position: absolute;
        right: 71px;
        top: 30px;
        width: 70px;
        height: 48px;
        object-fit: fill;
    } 
    @media only screen and (max-width: 900px) {
      padding: 54px 20px;
      img{
        right: 20px;
    } 
  }
`;

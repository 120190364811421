import { Button } from "./styles";
interface OutlinedButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  show: boolean
}

export function OutlinedButton({show, ...rest}: OutlinedButtonProps){

  return(
    <Button show={show} {...rest} /> 
  )
}
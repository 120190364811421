import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import thunk from 'redux-thunk';

import { MeProps, Token_usuario } from './types';
import { dadosVidaNovaProps } from '../services/models';
import {
    POST_LOGIN,
    POST_ME,
    POST_CONTA_VENDEDOR_NOVA_VIDA,
} from '../constants/actionsStrings';

export interface IState {
    me: MeProps;
    token_usuario: Token_usuario;
    dadosVidaNova: dadosVidaNovaProps
}

const INITIAL_STATE = {
    token_usuario: {},
    me: {},
    dadosVidaNova: {}
}

const enhancer = compose(applyMiddleware(thunk));

const state = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case POST_LOGIN:
            return { ...state, token_usuario: action.payload }

        case POST_ME:
            return { ...state, me: action.payload }
        
        case POST_CONTA_VENDEDOR_NOVA_VIDA:
            return { ...state, dadosVidaNova: action.payload }

        default:
            return { ...state };
    }
}

const store = createStore(state as any, enhancer);

export { store };




